import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdUserSearchModal extends interface___modal, interface___is_logged {

}

//	S T A T E

export interface state___MdUserSearchModal {
	
	users_search___param: string,
	
	users_search___results: any[],
	users_search___results___is_loading: boolean,
	users_search___results___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdUserSearchModal___default: state___MdUserSearchModal = {
		
	users_search___param: '',
	
	users_search___results: [],
	users_search___results___is_loading: true,
	users_search___results___is_complete: false,

}