import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComChatMessageAttachmentUnavailableComponent: type___locale___translations___collection = {
	'tale': {
		de_de: 'Diese Tale ist nicht mehr verfügbar',
		en_us: 'This Tale is no longer available',
		es_es: 'Esta Tale ya no está disponible',
		fr_fr: 'Cette Tale n\'est plus disponible',
		it_it: 'Questa Tale non è più disponibile'
	},
};