import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbPoiViewComponent: type___locale___translations___collection = {
	'poi_commands___feed': {
		de_de: 'Posten Sie ein Update',
		en_us: 'Post an update',
		es_es: 'Publicar una actualización',
		fr_fr: 'Publier une mise à jour',
		it_it: 'Pubblica un aggiornamento',
	},
	'poi_commands___settings': {
		de_de: 'Andere Einstellungen',
		en_us: 'Other settings',
		es_es: 'Otras configuraciones',
		fr_fr: 'Autres paramètres',
		it_it: 'Altre impostazioni',
	},
	'distance_from_you': {
		de_de: 'ca. {{1}} von Ihnen entfernt',
		en_us: 'about {{1}} from you',
		es_es: 'aproximadamente {{1}} de ti',
		fr_fr: 'à environ {{1}} de distance',
		it_it: 'a circa {{1}} da te',
	},
	'poi_events___event_is_now': {
		de_de: 'Jetzt!',
		en_us: 'Now!',
		es_es: '¡Ahora!',
		fr_fr: 'Maintenant!',
		it_it: 'Adesso!',
	},
	'poi_events___event_ended': {
		de_de: 'Fertig',
		en_us: 'Finished',
		es_es: 'Finalizado',
		fr_fr: 'Fini',
		it_it: 'Terminato',
	},
	'view_contents___feed': {
		de_de: 'Siehe Updates',
		en_us: 'See Updates',
		es_es: 'Ver actualizaciones',
		fr_fr: 'Voir les mises à jour',
		it_it: 'Vedi gli aggiornamenti',
	},
	'view_contents___tales': {
		de_de: 'Siehe die Tales',
		en_us: 'See the Tales',
		es_es: 'Ver los Tales',
		fr_fr: 'Voir les Tales',
		it_it: 'Vedi le Tales',
	},
	'view_contents___digitalmenu': {
		de_de: 'Digitales Menü',
		en_us: 'Digital Menu',
		es_es: 'Menú Digital',
		fr_fr: 'Menu Numérique',
		it_it: 'Menu Digitale',
	},
	'view_contents___cars': {
		de_de: 'Katalog der zum Verkauf',
		en_us: 'Catalog of Vehicles',
		es_es: 'Catálogo de Vehículos',
		fr_fr: 'Catalogue de véhicules',
		it_it: 'Catalogo Veicoli',
	},
	'contacts___phone': {
		de_de: 'Telefon',
		en_us: 'Telephone',
		es_es: 'Teléfono',
		fr_fr: 'Téléphone',
		it_it: 'Telefono',
	},
	'contacts___email': {
		de_de: 'E-Mail',
		en_us: 'Email',
		es_es: 'Email',
		fr_fr: 'E-mail',
		it_it: 'Email',
	},
	'contacts___website': {
		de_de: 'Webseite',
		en_us: 'Website',
		es_es: 'Sitio web',
		fr_fr: 'Site web',
		it_it: 'Sito Web',
	}
};