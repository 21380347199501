import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___Com404Component: type___locale___translations___collection = {
	'title': {
		de_de: 'Uuuups.....',
		en_us: 'Wooops.....',
		es_es: 'Uuuups.....',
		fr_fr: 'Oouups.....',
		it_it: 'Oooops.....',
	},
	'message': {
		de_de: 'Die App kann nicht auf die Kamera des Geräts zugreifen',
		en_us: 'The app cannot access the device\'s camera',
		es_es: 'La aplicación no puede acceder a la cámara del dispositivo.',
		fr_fr: 'L\'application ne peut pas accéder à la caméra de l\'appareil',
		it_it: 'L\'app non riesce ad accedere alla fotocamera del dispositivo',
	},
	'footer': {
		de_de: 'Sind Sie sicher, dass Sie Zugriff gewährt haben? Wenn Sie dies noch nicht getan haben, gehen Sie zu den Einstellungen und aktivieren Sie den Zugriff auf Kamera und Mikrofon',
		en_us: 'Are you sure you have granted access? If you haven\'t done so, go to settings and enable access to the camera and microphone',
		es_es: '¿Estás seguro de que has concedido acceso? Si no lo has hecho, ve a configuración y habilita el acceso a la cámara y al micrófono',
		fr_fr: 'Etes-vous sûr d\'avoir accordé l\'accès? Si vous ne l\'avez pas fait, accédez aux paramètres et activez l\'accès à la caméra et au microphone.',
		it_it: 'Sei sicuro di aver garantito l\'accesso? Se non lo hai fatto vai nelle impostazioni ed abilita l\'accesso alla fotocamera ed al microfono',
	},
	'button___retry': {
		de_de: 'Versuchen Sie es erneut',
		en_us: 'Try again',
		es_es: 'Intentar otra vez',
		fr_fr: 'Essayer à nouveau',
		it_it: 'Riprova',
	},
	'button___close': {
		de_de: 'Schließen',
		en_us: 'Close',
		es_es: 'Cerca',
		fr_fr: 'Fermer',
		it_it: 'Chiudi',
	},
};