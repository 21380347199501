import { interface___is_logged } from "../../interfaces/interface.logged"

//	P R O P S

export interface props___ComTaleCreateComponent extends interface___is_logged {

	event___onTaleCreationDismiss?: (_: boolean) => any | void | null | undefined

}

//	S T A T E

export interface state___ComTaleCreateComponent {

	ionModal___MdTalesCreateModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComTaleCreateComponent___default: state___ComTaleCreateComponent = {

	ionModal___MdTalesCreateModal___isOpen: false,

}