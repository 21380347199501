import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbArchivePage: type___locale___translations___collection = {
	'notification___title': {
		de_de: 'Deine Benachrichtigungen',
		en_us: 'Your Notifications',
		es_es: 'Tus Notificaciones',
		fr_fr: 'Vos Notifications',
		it_it: 'Le tue Notifiche',
	},
	'notification___see_all': {
		de_de: 'Alle Benachrichtigungen anzeigen',
		en_us: 'See all notifications',
		es_es: 'Ver todas las notificaciones',
		fr_fr: 'Voir toutes les notifications',
		it_it: 'vedi tutte le notifiche',
	},
	'notification___none': {
		de_de: 'Keine Benachrichtigungen anzuzeigen...',
		en_us: 'No notifications to show...',
		es_es: 'No hay notificaciones para mostrar...',
		fr_fr: 'Aucune notification à afficher...',
		it_it: 'Nessuna notifica da mostrare...',
	},
	'archive___title': {
		de_de: 'Dein Archiv',
		en_us: 'Your Archive',
		es_es: 'Tu Archivo',
		fr_fr: 'Votre Archive',
		it_it: 'Il tuo archivio',
	},
	'archive___favorites': {
		de_de: 'Deine Favoriten',
		en_us: 'Your Favorites',
		es_es: 'Tus Favoritos',
		fr_fr: 'Vos Favoris',
		it_it: 'I tuoi Preferiti',
	},
	'archive___your_poi': {
		de_de: 'Deine Punkte von Interesse',
		en_us: 'Your Points of Interest',
		es_es: 'Tus Puntos de Interés',
		fr_fr: 'Vos Points d\'Intérêt',
		it_it: 'I tuoi Punti di Interesse',
	},
	'archive___create_poi': {
		de_de: 'Erstelle einen Punkt von Interesse',
		en_us: 'Create a Point of Interest',
		es_es: 'Crear un Punto de Interés',
		fr_fr: 'Créer un Point d\'Intérêt',
		it_it: 'Crea un Punto di Interesse',
	},
	'archive___your_events': {
		de_de: 'Deine Veranstaltungen',
		en_us: 'Your Events',
		es_es: 'Tus Eventos',
		fr_fr: 'Vos Événements',
		it_it: 'I tuoi Eventi',
	},
	'archive___create_event': {
		de_de: 'Erstelle ein Ereignis',
		en_us: 'Create an Event',
		es_es: 'Crear un Evento',
		fr_fr: 'Créer un Événement',
		it_it: 'Creare un Evento',
	},
	'archive___create_something': {
		de_de: 'Erstelle ein Ereignis oder einen Punkt von Interesse',
		en_us: 'Create an Event or a Point of Interest',
		es_es: 'Crear un Evento o un Punto de Interés',
		fr_fr: 'Créer un Événement ou un Point d\'Intérêt',
		it_it: 'Crea un Evento o un Punto di Interesse',
	}
};