import React from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonPopover, IonRefresher, IonSegment, IonSegmentButton, IonToast, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-user-profile.scss';

//	S T A T E   -   P R O P S

import { props___SbUserProfilePage, state___SbUserProfilePage, state___SbUserProfilePage___default } from './sb-user-profile.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___user___follow___action_type, type___user___follow___request, type___user___friends___action } from '../../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComEventComponent from '../../../components/com-event/com-event';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComPostComponent from '../../../components/com-post/com-post';
import ComPostCreateComponent from '../../../components/com-post-create/com-post-create';
import ComPostCreateDisabledComponent from '../../../components/com-post-create-disabled/com-post-create-disabled';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';
import ComSpinnerTagComponent from '../../../components/com-spinner-tag/com-spinner-tag';
import ComTaleComponent from '../../../components/com-tale/com-tale';
import ComTaleCreateComponent from '../../../components/com-tale-create/com-tale-create';
import ComUserPrivateComponent from '../../../components/com-user-private/com-user-private';
import ComUserPrivateTopBarComponent from '../../../components/com-user-private-topbar/com-user-private-topbar';
import ComUserRequestComponent from '../../../components/com-user-request/com-user-request';
import ComUserVerifiedComponent from '../../../components/com-user-verified/com-user-verified';

//	M O D A L S

import MdMediaViewModal from '../../../modals/md-media-view/md-media-view';
import MdTalesViewModal from '../../../modals/md-tales-view/md-tales-view';
import MdUserFollowsModal from '../../../modals/md-user-follows/md-user-follows';

//	C L A S S

class SbUserProfilePage extends React.Component<props___SbUserProfilePage, state___SbUserProfilePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbUserProfilePage,
	) {
		super(props);
		this.state = state___SbUserProfilePage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user = async () => {
		const ___user___target: 'username' | 'me' = (this.state.user___props___username === 'profile') ? 'me' : 'username';
		const ___user___param: string | null = (this.state.user___props___username === 'profile') ? null : this.state.user___props___username;
		const ___temp___user_props: type___api___response = await this._API.users___read___single(___user___target, ___user___param);
		if (!___temp___user_props || ___temp___user_props.response !== 'success' || ___temp___user_props.data === null) { console.log('404'); }
		this.setState({
			user___props: ___temp___user_props.data,
			user___props___is_loading: false
		}, async () => {
			if (this.state.user___props !== null) {
				this.handle___tab_switch(this.state.tab___selected);
				await Promise.all([
					this.read_props___user_events(),
					this.read_props___user_poi(),
				]);
			}
		});
	}

	private read_props___user_feed = async () => {
		if (this.state.user___props !== null) {
			const ___temp___user_feed: type___api___response = await this._API.posts___read___multi('user', this.state.user___props['user_id'], this.state.user___feed.length);
			this.setState((prevState) => ({
				user___feed: [...prevState.user___feed, ...___temp___user_feed.data],
				user___feed___is_loading: false,
				user___feed___is_complete: (___temp___user_feed.response === 'no-data') ? true : false,
			}));
		}
	}

	private read_props___user_tales = async () => {
		if (this.state.user___props !== null) {
			const ___temp___user_tales: type___api___response = await this._API.tales___read___multi('user', this.state.user___props['user_id'], this.state.user___tales.length);
			this.setState((prevState) => ({
				user___tales: [...prevState.user___tales, ...___temp___user_tales.data],
				user___tales___is_loading: false,
				user___tales___is_complete: (___temp___user_tales.response === 'no-data') ? true : false,
			}));
		}
	}

	private read_props___user_events = async () => {
		if (this.state.user___props !== null) {
			const ___temp___user_events: type___api___response = await this._API.events___read___multi('user_public', this.state.user___props['user_id'], this.state.user___events.length);
			this.setState((prevState) => ({
				user___events: [...prevState.user___events, ...___temp___user_events.data],
				user___events___is_loading: false,
				user___events___is_complete: (___temp___user_events.response === 'no-data') ? true : false,
			}));
		}
	}

	private read_props___user_poi = async () => {
		if (this.state.user___props !== null && this.state.user___props['user_poi'] && this.state.user___props['user_poi'] !== null) {
			const ___temp___user_poi: type___api___response = await this._API.poi___read___single(this.state.user___props['user_poi']);
			this.setState({
				user___poi: ___temp___user_poi.data,
				user___poi___is_loading: false,
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail> | null = null) => {
		this.setState({
			user___feed: [],
			user___feed___is_loading: true,
			user___feed___is_complete: false,
			user___tales: [],
			user___tales___is_loading: true,
			user___tales___is_complete: false,
		}, async () => {
			await this.read_props___user();
			if (___e !== null) { ___e.detail.complete(); }
		});
	}

	private handle___media_preview___show = (___media_src: string) => {
		this.setState({
			ionModal___MdMediaViewModal___argument: ___media_src,
			ionModal___MdMediaViewModal___isOpen: true,
		});
	}

	private handle___follow___toggle = (___action: type___user___follow___action_type) => {
		this.setState({
			user___follows___is_loading: true,
		}, async () => {
			const ___is_now_following: type___api___response = await this._API.users_follows___toggle(this.state.user___props['user_id'], ___action);
			if (___is_now_following) { await this.read_props___user(); }
			this.setState({ user___follows___is_loading: false });
		});
	}

	private handle___friend___toggle = (___action: type___user___friends___action) => {
		this.setState({
			user___friends___is_loading: true,
		}, async () => {
			const ___is_now_friend: type___api___response = await this._API.users_friends___toggle(this.state.user___props['user_id'], ___action);
			if (___is_now_friend) { await this.read_props___user(); }
			switch (___action) {
				case 'add':
					this.setState({
						user___friends___is_loading: false,
						ionToast___isCloseFriend_add___isOpen: true,
					});
				break;
				case 'remove':
					this.setState({
						user___friends___is_loading: false,
						ionToast___isCloseFriend_remove___isOpen: true,
					}); 
				break;
			}
		});
	}

	private handle___tab_switch = (___new_tab: state___SbUserProfilePage['tab___selected']) => {
		this.setState({
			user___feed: [],
			user___feed___is_loading: true,
			user___feed___is_complete: false,
			user___tales: [],
			user___tales___is_loading: true,
			user___tales___is_complete: false,
			user___events: [],
			user___events___is_loading: true,
			user___events___is_complete: false,
			tab___selected: (this.state.tab___selected !== ___new_tab) ? ___new_tab : this.state.tab___selected,
		}, async () => {
			switch (___new_tab) {
				case 'feed': await this.read_props___user_feed(); break;
				case 'tales': await this.read_props___user_tales(); break;
				case 'events': await this.read_props___user_events(); break;
			}
		});
	}

	private handle___post___is_created = () => {
		this.setState({
			user___feed: [],
			user___feed___is_loading: true,
			user___feed___is_complete: false,
		}, async () => {
			await this.read_props___user_feed();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		this.setState({
			...state___SbUserProfilePage___default
		}, () => {
			const ___user_username: string | 'profile' = this._ROUTER.get_id(this.props);
			this.setState({
				tab___selected: 'feed',
				user___props___username: ___user_username,
			}, () => {
				this.read_props___user();
			});
		});
	}

	ionViewWillLeave() : void {
		this.setState({
			...state___SbUserProfilePage___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				{(this.state.user___props___is_loading === false && this.state.user___props !== null) ? <>
				
					<MdMediaViewModal isOpen={ this.state.ionModal___MdMediaViewModal___isOpen === true && this.state.ionModal___MdMediaViewModal___argument !== '' } media_src={ this.state.ionModal___MdMediaViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdMediaViewModal___isOpen: false, ionModal___MdMediaViewModal___argument: '' }); }}/>
					<MdTalesViewModal isOpen={ this.state.ionModal___MdTaleViewModal___isOpen === true && this.state.ionModal___MdTaleViewModal___argument !== '' } tale_id={ this.state.ionModal___MdTaleViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdTaleViewModal___isOpen: false, ionModal___MdTaleViewModal___argument: '' }); }}/>
					<MdUserFollowsModal isOpen={ this.state.ionModal___MdUserFollowsModal___isOpen === true && this.state.ionModal___MdUserFollowsModal___argument !== '' } target={ this.state.ionModal___MdUserFollowsModal___argument } target_user={ this.state.user___props['user_id'] } event___onDidDismiss={() => { this.setState({ ionModal___MdUserFollowsModal___isOpen: false, ionModal___MdUserFollowsModal___argument: '' }); }}is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
					
					<IonToast isOpen={ this.state.ionToast___isCloseFriend_add___isOpen } message={ this.state.user___props['user_username'] + ' è stata aggiunta ai tuoi amici stretti'} duration={ 3000 } color="success" position="top" onDidDismiss={() => { this.setState({ ionToast___isCloseFriend_add___isOpen: false }); }}/>
					<IonToast isOpen={ this.state.ionToast___isCloseFriend_remove___isOpen } message={ this.state.user___props['user_username'] + ' è stata rimossa ai tuoi amici stretti'} duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___isCloseFriend_remove___isOpen: false }); }}/>

				</> : <></>}

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover"></IonBackButton>
						</IonButtons>
						{(this.state.user___props___is_loading === false && this.state.user___props !== null) ? <>
							<IonButtons slot="end">
								{(this.state.user___props['user_is_me'] === true && this.state.user___props___username === 'profile') ? <>
									<IonButton routerLink="/settings">
										<i className="fas fa-cog"></i>
									</IonButton>
								</> : <>
									{(this.state.user___friends___is_loading) ? <>
										<IonButton style={{filter:'grayscale(1)'}} >
											<section className="sb-user-profile---trusted-friend-button">
												<ComSpinnerTagComponent />
											</section>
										</IonButton>
									</> : <>
										{(
											(this.state.user___props['user_you_follow_him'] && this.state.user___props['user_you_follow_him'] === 'accepted') ||
											(this.state.user___props['user_he_follows_you'] && this.state.user___props['user_he_follows_you'] === 'accepted')
										) ? <>
											{(this.state.user___props['user_is_close_friend'] && this.state.user___props['user_is_close_friend'] === true) ? <>
												<IonButton onClick={() => { this.handle___friend___toggle('remove'); }} fill="solid" color="success">
													<section className="sb-user-profile---trusted-friend-button">
														<i className="fas fa-user-minus"></i>
														<span>Amico Fidato</span>
													</section>
												</IonButton>
											</> : <>
												<IonButton onClick={() => { this.handle___friend___toggle('add'); }}>
													<section className="sb-user-profile---trusted-friend-button">
														<i className="fas fa-user-plus"></i>
														<span>Amico Fidato</span>
													</section>
												</IonButton>
											</>}
										</> : <></>}
									</>}
								</>}
							</IonButtons>
						</> : <></>}
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefresherComponent/>
					</IonRefresher>
				
					{(this.state.user___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>
						{(this.state.user___props !== null) ? <>
							<div className="sb-page---container">

								{(this.state.user___props['user_is_me'] === true && this.state.user___props___username === 'profile' && !(this.state.user___props['user_profile_public'] && this.state.user___props['user_profile_public'] === true)) ? <>
									<ComUserPrivateTopBarComponent/>
								</> : <></>}

								{(this.state.user___props['user_he_follows_you'] && this.state.user___props['user_he_follows_you'] === 'requested') ? <>
									<ComUserRequestComponent user___props={ this.state.user___props} event___onActionPerformed={() => { this.handle___page___refresh }}/>
									<br />
								</> : <></>}

								<div className="sb-user-profile---header">
									{(this.state.user___props['user_image_cover'] !== null) ? <>
										<img src={ this.state.user___props['user_image_cover'] } onClick={() => { this.handle___media_preview___show(this.state.user___props['user_image_cover']); }}/>
									</> : <>
										<img src="../../assets/ui/images/image-profile-cover.jpg"/>
									</>}
									<img src={ this.state.user___props['user_image_profile'] }  onClick={() => { this.handle___media_preview___show(this.state.user___props['user_image_profile']); }}/>
								</div>

								{(this.state.user___props['user_name'] && this.state.user___props['user_name'].length > 0) ? <>
									<div className="sb-user-profile---header---name">
										<h1>
											<span>{ this.state.user___props['user_name'] }</span>
											{(this.state.user___props['user_profile_verified'] && this.state.user___props['user_profile_verified'] === true) ? <>
												<ComUserVerifiedComponent popup={ true }/>
											</> : <></>}
										</h1>
										<h6>~ @{ this.state.user___props['user_username'] }</h6>
									</div>
								</> : <>
									<div className="sb-user-profile---header---name">
										<h1>
											<span>{ this.state.user___props['user_username'] }</span>
											{(this.state.user___props['user_profile_verified'] && this.state.user___props['user_profile_verified'] === true) ? <>
												<ComUserVerifiedComponent popup={ true }/>
											</> : <></>}
										</h1>
									</div>
								</>}

								{(this.state.user___props['user_profile_bio'] && this.state.user___props['user_profile_bio'].length > 0) ? <>
									<section className="sb-user-profile---header---bio">
										<p>{ this.state.user___props['user_profile_bio'] }</p>
									</section>
								</> : <></>}

								<div className="sb-user-profile---following-panel">
									<section>
										<span>{ this.state.user___props['user_count___contents'] }</span>
										<span>contenuti</span>
									</section>
									<section onClick={() => { this.setState({ ionModal___MdUserFollowsModal___isOpen: true, ionModal___MdUserFollowsModal___argument: 'followers' }); }}>
										<span>{ this.state.user___props['user_count___followers'] }</span>
										<span>followers</span>
									</section>
									<section onClick={() => { this.setState({ ionModal___MdUserFollowsModal___isOpen: true, ionModal___MdUserFollowsModal___argument: 'following' }); }}>
										<span>{ this.state.user___props['user_count___following'] }</span>
										<span>seguiti</span>
									</section>
								</div>

								{(this.state.user___props['user_poi'] && this.state.user___props['user_poi'] !== null) ? <>
									{(this.state.user___poi___is_loading === true) ? <>
										<ComSpinnerInlineComponent />
									</> : <>
										<ComListSeparatorComponent />
										<Link to={ '/view/poi/' + this.state.user___poi['poi_id'] }>
											<ComPoiComponent poi_props={ this.state.user___poi } size="normal" />
										</Link>
									</>}
								</> : <></>}

								{(this.state.user___props['user_is_me'] === true) ? <>
									<section className="sb-user-profile---options---buttons">
										<Link to="/user/closefriends" className="sb-user-profile---options---buttons-secondary">
											<i className="fas fa-user-friends"></i>
											<span>Modifica amici fidati</span>
										</Link>
										<div style={{display:'none'}}></div>
									</section>
								</> : <>
									<section className="sb-user-profile---options---buttons">
										{(this.state.user___follows___is_loading === true) ? <>
											<div className="sb-user-profile---options---buttons-secondary">
												<ComSpinnerInlineComponent/>
											</div>
										</> : <>
											{(() => { switch (this.state.user___props['user_you_follow_him'] as type___user___follow___request) {
												case 'accepted': return <>
													<div onClick={() => { this.handle___follow___toggle('unfollow'); }} className="sb-user-profile---options---buttons-secondary">
														<i className="fas fa-unlink"></i>
														<span>Smetti di seguire</span>
													</div>
												</>; break;
												case 'requested': return <>
													<div onClick={() => { this.handle___follow___toggle('unfollow'); }} className="sb-user-profile---options---buttons-secondary">
														<i className="fas fa-times"></i>
														<span>Annulla la richiesta</span>
													</div>
												</>; break;
												case 'none': return <>
													<div onClick={() => { this.handle___follow___toggle('follow'); }}>
														<i className="fas fa-plus"></i>
														{(this.state.user___props['user_he_follows_you'] && this.state.user___props['user_he_follows_you'] === 'accepted') ? <>
															<span>Segui anche tu</span>
														</> : <>
															<span>Segui</span>
														</>}
													</div>
												</>; break;
											}})()}
										</>}
										{(
											(this.state.user___props['user_profile_contactable'] && this.state.user___props['user_profile_contactable'] === true) ||
											(this.state.user___props['user_you_follow_him'] && this.state.user___props['user_you_follow_him'] === 'accepted') ||
											(this.state.user___props['user_he_follows_you'] && this.state.user___props['user_he_follows_you'] === 'accepted')
										) ? <>
											<Link to={ '/chats/with/' + this.state.user___props['user_username'] }>
												<i className="fas fa-comments"></i>
											</Link>
										</> : <>
											<IonPopover className="sb-user-profile---messages---buttons-secondary---cannot-chat---popup" trigger="sb-user-profile---messages---buttons-secondary---cannot-chat" triggerAction="click" dismissOnSelect={ true }>
												<IonContent forceOverscroll={ false }>
													<section>
														<p>Questo utente non vuole essere contattato da chi non segue</p>
													</section>
												</IonContent>
											</IonPopover>
											<div id="sb-user-profile---messages---buttons-secondary---cannot-chat" className="sb-user-profile---messages---buttons-secondary">
												<i className="far fa-comments"></i>
											</div>
										</>}
									</section>
								</>}

								<br />

								{(
									(this.state.user___props['user_is_me'] === true) ||
									(this.state.user___props['user_profile_public'] && this.state.user___props['user_profile_public'] === true) || 
									(this.state.user___props['user_you_follow_him'] === 'accepted')
								) ? <>
									
									<IonSegment value={ this.state.tab___selected } onIonChange={(___e: any) => { this.handle___tab_switch(___e.detail.value); }}>
										<IonSegmentButton value="feed">
											<IonLabel><img src="/assets/ui/icons/icon-post.svg" alt="Feed"/></IonLabel>
										</IonSegmentButton>
										<IonSegmentButton value="tales">
											<IonLabel><img src="/assets/ui/icons/icon-tale.svg" alt="Tales"/></IonLabel>
										</IonSegmentButton>
									
										{/*
											{(this.state.user___props['user_permission___events'] && this.state.user___props['user_permission___events'] === true && this.state.user___events.length > 0) ? <>
												<IonSegmentButton value="events">
													<IonLabel><img src="/assets/ui/icons/icon-event.svg" alt="Events"/></IonLabel>
												</IonSegmentButton>
											</> : <></>}
										*/}

									</IonSegment>

									<br />

									{(() => { switch (this.state.tab___selected) {
										case 'feed': return <>
											<div className="sb-user-profile---tab sb-user-profile---tab---is-feed">
												{(this.state.user___props['user_is_me'] === true || this.state.user___props['user_you_follow_him'] === 'accepted') ? <>
													<ComPostCreateComponent is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } event___onDidCreate={ this.handle___post___is_created } post_creation_target={{ target: 'user', id: this.state.user___props['user_id'], name: this.state.user___props['user_username'] }}/>
												</> : <>
													<ComPostCreateDisabledComponent why="not_following" />
												</>}
												<br />
												{this.state.user___feed.map((___p: any, ___p_i: number) => { return <>
													<ComPostComponent key={ ___p_i } post___type="normal" post___props={ ___p } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } post___can_route_comments={ true }/>
												</>})}
												{(this.state.user___feed___is_complete === true) ? <>
													<ComListEmptyComponent list_items_quantity={ this.state.user___feed.length }/>
												</> : <>
													<ComInfiniteScrollerComponent is_loading={ this.state.user___feed___is_loading } event___scrollTriggered={ this.read_props___user_feed } />
												</>}
											</div>
										</>; break;
										case 'tales': return <>
											<div className="sb-user-profile---tab sb-user-profile---tab---is-tales">
												{(this.state.user___props['user_is_me'] === true) ? <>
													<ComTaleCreateComponent is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props }/>
												</> : <></>}
												{this.state.user___tales.map((___t: any, ___t_i: number) => {
													return <>
														<ComTaleComponent key={ ___t_i } tale___props={ ___t } event___onClick={() => { this.setState({ ionModal___MdTaleViewModal___isOpen: true, ionModal___MdTaleViewModal___argument: ___t['tale_id'] }) }}/>
													</>
												})}
												{(this.state.user___tales___is_complete === true) ? <>
													<ComListEmptyComponent list_items_quantity={ this.state.user___tales.length }/>
												</> : <>
													<ComInfiniteScrollerComponent is_loading={ this.state.user___tales___is_loading } event___scrollTriggered={ this.read_props___user_tales } />
												</>}
											</div>
										</>; break;
										case 'events': return <>
											{(this.state.user___props['user_permission___events'] && this.state.user___props['user_permission___events'] === true) ? <>
												<div className="sb-user-profile---tab sb-user-profile---tab---is-events">
													{this.state.user___events.map((___e: any, ___e___idx: number) => {
														return <>
															<Link to={ '/view/events/' + ___e['event_id'] }>
																<ComEventComponent key={ ___e___idx } event_props={ ___e } size="small"/>
															</Link>
														</>
													})}
													{(this.state.user___events___is_complete === true) ? <>
														<ComListEmptyComponent list_items_quantity={ this.state.user___events.length }/>
													</> : <>
														<ComInfiniteScrollerComponent is_loading={ this.state.user___events___is_loading } event___scrollTriggered={ this.read_props___user_events } />
													</>}
												</div>
											</> : <></>}
									</>; break;
									}})()}

								</> : <>

									<br />
									<br />
									<ComUserPrivateComponent user___props={ this.state.user___props }/>

								</>}

							</div>
						</> : <>
							<Com404Component/>
						</>}
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbUserProfilePage);