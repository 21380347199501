import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbUsersCloseFriendsPage extends interface___is_logged {

}

//	S T A T E

export interface state___SbUsersCloseFriendsPage {

	close_friends___collection: string[],
	close_friends___collection___is_loading: boolean,

	follows___search_param: string,

	follows___collection: any[],
	follows___collection___is_loading: boolean,
	follows___collection___is_complete: boolean,








}

//	S T A T E   D E F A U L T

export const state___SbUsersCloseFriendsPage___default: state___SbUsersCloseFriendsPage = {

	close_friends___collection: [],
	close_friends___collection___is_loading: true,

	follows___search_param: '',

	follows___collection: [],
	follows___collection___is_loading: false,
	follows___collection___is_complete: false,










}