//	T Y P E S

import { type___cookies___accepted } from "./types/types.cookies";
import { type___locale___language } from "./types/types.locale";

//	S T A T E

export interface state___App {

	app___is_loading: boolean,

	me___is_logged: boolean | null,
	me___is_logged___props: any | null,

	notifications___count: number,

	entry___language_selected: type___locale___language | null,
	entry___cookies_accepted: type___cookies___accepted | null,
	entry___geolocation_available: boolean | null,

	ionModal___MdEntryChangeLogModal___isOpen: boolean,
	ionModal___MdEntryCookiesModal___isOpen: boolean,
	ionModal___MdEntryDownloadAppModal___isOpen: boolean,
	ionModal___MdEntryInterestsModal___isOpen: boolean,
	ionModal___MdEntryLanguageModal___isOpen: boolean,
	ionModal___MdEntrySignModal___isOpen: boolean,
	ionModal___MdSignPasswordChangeModal___isOpen: boolean,

	ionToast___notification___isOpen: boolean,
	ionToast___notification___message: string,

}

//	S T A T E   D E F A U L T S

export const state___App___default: state___App = {

	app___is_loading: true,

	me___is_logged : null,
	me___is_logged___props : null,

	notifications___count: 0,

	entry___language_selected: null,
	entry___cookies_accepted: null,
	entry___geolocation_available: null,

	ionModal___MdEntryChangeLogModal___isOpen: false,
	ionModal___MdEntryCookiesModal___isOpen: false,
	ionModal___MdEntryDownloadAppModal___isOpen: false,
	ionModal___MdEntryInterestsModal___isOpen: false,
	ionModal___MdEntryLanguageModal___isOpen: false,
	ionModal___MdEntrySignModal___isOpen: false,
	ionModal___MdSignPasswordChangeModal___isOpen: false,

	ionToast___notification___isOpen: false,
	ionToast___notification___message: '',

}