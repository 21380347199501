import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdPoiRoleAddModal: type___locale___translations___collection = {
	'ionToast___role_added': {
		de_de: 'Rolle hinzugefügt',
		en_us: 'Role added',
		es_es: 'Rol agregado',
		fr_fr: 'Rôle ajouté',
		it_it: 'Ruolo aggiunto',
	},
	'ionToast___role_added___error': {
		de_de: 'Fehler beim Hinzufügen der Rolle',
		en_us: 'Error adding role',
		es_es: 'Error al agregar el rol',
		fr_fr: 'Erreur lors de l\'ajout du rôle',
		it_it: 'Errore nell\'aggiunta del ruolo',
	},
	'title': {
		de_de: 'Neuer Benutzer mit Rolle',
		en_us: 'New User with Role',
		es_es: 'Nuevo usuario con rol',
		fr_fr: 'Nouvel utilisateur avec rôle',
		it_it: 'Nuovo Utente con Ruolo',
	},
	'type_selected___title': {
		de_de: 'Veranstalter',
		en_us: 'Event Creator',
		es_es: 'Creador de eventos',
		fr_fr: 'Créateur d\'événements',
		it_it: 'Creatore di eventi',
	},
	'type_selected___description': {
		de_de: 'Dieser Benutzer kann Veranstaltungen an diesem Ort erstellen und verwalten',
		en_us: 'This user will be able to create and manage events for this Point of Interest',
		es_es: 'Este usuario podrá crear y gestionar eventos para este Punto de Interés',
		fr_fr: 'Cet utilisateur pourra créer et gérer des événements pour ce Point d\'Intérêt',
		it_it: 'Questo utente avrà la possibilità di creare e gestire gli eventi di questo Punto di Interesse',
	},
	'you_selected': {
		de_de: 'Du hast ausgewählt',
		en_us: 'You selected',
		es_es: 'Has seleccionado',
		fr_fr: 'Vous avez sélectionné',
		it_it: 'Hai selezionato',
	},
	'you_selected___cancel': {
		de_de: 'Auswahl aufheben',
		en_us: 'Cancel selection',
		es_es: 'Cancelar selección',
		fr_fr: 'Annuler la sélection',
		it_it: 'Annulla selezione',
	},
	'field___search': {
		de_de: 'Nach Benutzername suchen',
		en_us: 'Search user by username',
		es_es: 'Buscar usuario por nombre de usuario',
		fr_fr: 'Rechercher un utilisateur par nom d\'utilisateur',
		it_it: 'Cerca utente per username',
	},
	'field___search___placeholder': {
		de_de: 'Name oder Benutzername',
		en_us: 'Name or Username',
		es_es: 'Nombre o Nombre de usuario',
		fr_fr: 'Nom ou nom d\'utilisateur',
		it_it: 'Nome o Nome Utente',
	},
	'field___search___not_found': {
		de_de: 'Kein Benutzer mit diesem Benutzernamen gefunden...',
		en_us: 'No user matches this username...',
		es_es: 'No se encuentra un usuario con este nombre...',
		fr_fr: 'Aucun utilisateur ne correspond à ce nom...',
		it_it: 'Nessun utente corrisponde a questo username...',
	},
	'button___add_role': {
		de_de: 'Rolle hinzufügen',
		en_us: 'Add Role',
		es_es: 'Agregar rol',
		fr_fr: 'Ajouter un rôle',
		it_it: 'Aggiungi Ruolo',
	},
	'button___select_user': {
		de_de: 'Einen Benutzer auswählen',
		en_us: 'Select a User',
		es_es: 'Selecciona un usuario',
		fr_fr: 'Sélectionner un utilisateur',
		it_it: 'Seleziona un Utente',
	}
};