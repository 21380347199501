import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComInputTextareaComponent } from './com-input-textarea.locale';

//	S T Y L E

import './com-input-textarea.scss'

//	S T A T E S   -   P R O P S

import { props___ComInputTextareaComponent, state___ComInputTextareaComponent, state___ComInputTextareaComponent___default } from './com-input-textarea.state';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	F U N C T I O N S

import { funcs_text } from '../../funcs/funcs.text';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComInputTagsComponent from '../com-input-tags/com-input-tags';

//	C L A S S

export default class ComInputTextareaComponent extends React.Component<props___ComInputTextareaComponent, state___ComInputTextareaComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComInputTextareaComponent,
	) {
		super(props);
		this.state = state___ComInputTextareaComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___write = (___input: string) => {

		const ___textarea: HTMLTextAreaElement = document.getElementById('com-input-textarea---text-input') as HTMLTextAreaElement;

		this.setState({
			input_text: this.funcs___text.text_input___process(___input),
		}, () => {

			const ___input_string: string = this.state.input_text;
			this.props.event___onInput(___input_string);
	//		this.handle___highlights(___input_string);

			const ___textarea___text: string = ___input_string;
			const ___textarea___cursor_position: number = ___textarea.selectionStart;
			const ___textarea___atIndex: number = ___textarea___text.lastIndexOf('@', ___textarea___cursor_position);
			const ___last_user_tagged: string = (___textarea___atIndex !== -1 && ___textarea___cursor_position > ___textarea___atIndex) ? ___textarea___text.substring(___textarea___atIndex, ___textarea___cursor_position) : '';

			if (lib_names_shared.regex.text___usertag.test(___last_user_tagged) === true) {
				this.setState({
					tag_selector___input: ___last_user_tagged,
					tag_selector___is_visible: true,
				});
			} else {
				this.setState({
					tag_selector___is_visible: false,
				});
			}

		});
	}

	private handle___selectTag = (___user_tagged: string) => {
	
		const ___input___string: string = this.state.input_text;
		const ___input___lastAtIndex: number = ___input___string.lastIndexOf('@');
		if (___input___lastAtIndex === -1) { return ___input___lastAtIndex; }

		const ___textarea: HTMLTextAreaElement = document.getElementById('com-input-textarea---text-input') as HTMLTextAreaElement;
		const ___textarea___text: string = ___input___string;
		const ___textarea___cursor_position: number = ___textarea.selectionStart;
		const ___textarea___atIndex: number = ___textarea___text.lastIndexOf('@', ___textarea___cursor_position);
		const ___textarea___textBefore: string = ___textarea___text.substring(0, ___textarea___atIndex);
		const ___textarea___textAfter: string = ___textarea___text.substring(___textarea___cursor_position);
		const ___textarea___textNew = ___textarea___textBefore + ___user_tagged + ' ' + ___textarea___textAfter;

		this.setState({
			input_text: ___textarea___textNew,
			tag_selector___input: '',
			tag_selector___is_visible: false,
		}, () => {
			___textarea.selectionStart = ___textarea___atIndex + ___user_tagged.length + 1;
			___textarea.selectionEnd = ___textarea___atIndex + ___user_tagged.length + 1;
			___textarea.focus();
		});

	}

	private handle___highlights = (___input) => {

		let ___input___text: string = this.state.input_text;

		const ___patterns: any[] = [
			{ pattern: lib_names_shared.regex.text___usertag, class: 'input---nb-outlined---rich-textarea---usertag' },
			{ pattern: lib_names_shared.regex.text___url, class: 'input---nb-outlined---rich-textarea---url' },
			{ pattern: lib_names_shared.regex.text___hashtag, class: 'input---nb-outlined---rich-textarea---hashtag' },
		];

		___patterns.forEach((___p: any) => {
			___input___text = ___input___text.replace(___p['pattern'], (___m: string) => {
				return '<span class="' + ___p['class'] + '">' + ___m + '</span>';
			});
		});

		this.setState({
			input_text___rich: ___input___text,
		});

	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		
	}

	componentDidUpdate(): void {
		
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-input-textarea---container">
				{/*
					<div className="input---nb-outlined---rich-textarea">
						<div dangerouslySetInnerHTML={{ __html: this.state.input_text___rich }}></div>
						<textarea id="com-input-textarea---text-input" value={ this.state.input_text } placeholder="Scrivi qualcosa" onInput={(___e: any) => { this.handle___write(___e.target.value); }} ></textarea>
					</div>
				*/}
				<textarea id="com-input-textarea---text-input" className="input---nb-outlined" value={ this.state.input_text } placeholder={ this._LOCALE.translate(locale___ComInputTextareaComponent.textarea___placeholder) } onInput={(___e: any) => { this.handle___write(___e.target.value); }} ></textarea>
				<ComInputTagsComponent text={ this.state.tag_selector___input } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } event___onTagSelect={ this.handle___selectTag }/>
			</div>
		</>;
	}

//#endregion

}