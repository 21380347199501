import { type___locale___translations___collection } from "../types/types.locale";

//	E X P O R T   L O C A L E   -->    service_Sharing

export const locale___service_Sharing: type___locale___translations___collection = {
	'share_text': {
		de_de: 'Schauen Sie, was ich auf Nearby gefunden habe 🤩🤩 \nSchauen Sie sich "{{1}}" an\n',
		en_us: 'Look what I found on Nearby 🤩🤩 \nCheck out "{{1}}"\n',
		es_es: 'Mira lo que encontré en Nearby 🤩🤩 \nMira "{{1}}"\n',
		fr_fr: 'Regardez ce que j\'ai trouvé sur Nearby 🤩🤩 \nDécouvrez "{{1}}"\n',
		it_it: 'Guarda cos\'ho trovato su Nearby 🤩🤩 \nDai un\'occhiata a "{{1}}"\n',
	},
};