import React from 'react'

//	L O C A L E

import { locale___ComInputPassword } from './com-input-password.locale';

//	S T Y L E

import './com-input-password.scss'

//	S T A T E S   -   P R O P S

import { props___ComInputPassword, state___ComInputPassword, state___ComInputPassword___default } from './com-input-password.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComInputPassword extends React.Component<props___ComInputPassword, state___ComInputPassword>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComInputPassword,
	) {
		super(props);
		this.state = state___ComInputPassword___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___write = (___input: string) => {
		const ___input___trimmed: string = ___input.trim();
		this.props.event___onInput(___input___trimmed);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-input-password---container">
				{(this.state.field___is_visible === true) ? <>
					<input type="text" placeholder={(this.props.placeholder && this.props.placeholder.length > 0) ? this.props.placeholder : this._LOCALE.translate(locale___ComInputPassword.placeholder) } className="input---nb-outlined" value={ this.props.text } onInput={(___e: any) =>{ this.handle___write(___e.target.value); }} />
					<div onClick={() => { this.setState({ field___is_visible: false }); }}>
						<i className="far fa-eye"></i>
					</div>
				</> : <>
					<input type="password" placeholder={(this.props.placeholder && this.props.placeholder.length > 0) ? this.props.placeholder : this._LOCALE.translate(locale___ComInputPassword.placeholder) } className="input---nb-outlined" value={ this.props.text } onInput={(___e: any) =>{ this.handle___write(___e.target.value); }} />
					<div onClick={() => { this.setState({ field___is_visible: true }); }}>
						<i className="far fa-eye-slash"></i>
					</div>
				</>}
			</div>
		</>;
	}

//#endregion

}