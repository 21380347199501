//	L I B S

import { lib_errors_geolocation } from '../libs/lib.errors.geolocation';
import { lib_names } from '../libs/lib.names';
import { locale___service_Geolocation } from './service-geolocation.locale';

//	T Y P E S

import { type___geo___lngLat } from '../types/types.types';

//	S E R V I C E S

import { service_LocalizationService } from './service-localization';

//	E X P O R T

export class service_GeolocationService
{

//#region 																							N U M B E R S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							L O C A T E

	public geolocation___locate = async () : Promise<type___geo___lngLat> => {
		return new Promise((resolve, reject) => {
			if (!navigator.geolocation) { return resolve({ lat: 0, lng: 0, error: lib_errors_geolocation.geolocation___not_supported }); }
			navigator.geolocation.getCurrentPosition(
				(___position: GeolocationPosition) => {
					const ___me___geolocation: type___geo___lngLat = { lng: ___position.coords.longitude, lat: ___position.coords.latitude, error: null };
					const ___me___geolocation___stringified: string = ___me___geolocation['lng'].toString() + ',' + ___me___geolocation['lat'].toString();
					localStorage.setItem(lib_names.localStorage.user___location, ___me___geolocation___stringified);
					resolve(___me___geolocation);
					return;
				},
				(___error: GeolocationPositionError) => {
					let ___error___errorMessage: string = '';
					switch (___error.code) {
						case ___error.PERMISSION_DENIED: ___error___errorMessage = lib_errors_geolocation.geolocation___denied; break;
						case ___error.POSITION_UNAVAILABLE: ___error___errorMessage = lib_errors_geolocation.geolocation___unavailable; break;
						case ___error.TIMEOUT: ___error___errorMessage = lib_errors_geolocation.geolocation___timed_out; break;
						default: ___error___errorMessage = lib_errors_geolocation.geolocation___generic; break;
					}
					resolve({ lat: 0, lng: 0, error: lib_errors_geolocation.geolocation___generic });
				}
			);
		}) as Promise<type___geo___lngLat>;
	}

//#endregion

//#region 																							G E T   A D D R E S S   F R O M   C O O R D S

	public async geolocation___get_address___from_coords(___coords: type___geo___lngLat) : Promise<string>
	{
		const ___s_route: string = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + ___coords.lng.toString() + ',' + ___coords.lat.toString() + '.json?language=it&access_token=' + lib_names.mapboxgl.accessToken_geocoding;
		const ___s_response: Response = await fetch(___s_route, { method: 'GET' });
		if (___s_response.ok)
		{
			const ___server_response: any = await ___s_response.json();
			const ___server_response___parsed: string = (___server_response['features'].length > 0) ? ___server_response['features'][0]['place_name_it'] : null;
			return ___server_response___parsed;
		}
		else
		{
			return this._LOCALE.translate(locale___service_Geolocation.address_not_availble);
		//	try {
		//		const ___error_details: any = await ___s_response.json();
		//		return ___error_details.message;
		//	} catch (___e: any) { return ___e; }
		}
	}

//#endregion

//#region 																							G E T   G E T   R A N D O M   C O O R D S

	public geolocation___get_random_coords() : type___geo___lngLat
	{
		const ___latitude: number = Math.random() * 180 - 90;
		const ___longitude: number = Math.random() * 360 - 180;
		return { lng: ___longitude, lat: ___latitude } as type___geo___lngLat;
	}

//#endregion

}