import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdPoiTimelineEditModal: type___locale___translations___collection = {
	'title': {
		de_de: 'Öffnungszeiten',
		en_us: 'Hours',
		es_es: 'Horarios',
		fr_fr: 'Horaires',
		it_it: 'Orari',
	},
	'segment___none': {
		de_de: 'Keine',
		en_us: 'None',
		es_es: 'Ninguno',
		fr_fr: 'Aucun',
		it_it: 'Nessuno',
	},
	'segment___h24': {
		de_de: 'Rund um die Uhr geöffnet',
		en_us: 'Always Open',
		es_es: 'Siempre Abierto',
		fr_fr: 'Toujours Ouvert',
		it_it: 'Sempre Aperto',
	},
	'segment___custom': {
		de_de: 'Anpassen',
		en_us: 'Customize',
		es_es: 'Personalizar',
		fr_fr: 'Personnaliser',
		it_it: 'Personalizza',
	},
	'segment___p___none': {
		de_de: 'Keine Zeiten für diesen Punkt von Interesse angeben',
		en_us: 'Do not specify hours for this point of interest',
		es_es: 'No especificar horarios para este punto de interés',
		fr_fr: 'Ne pas spécifier d\'horaires pour ce point d\'intérêt',
		it_it: 'Non specificare orari per questo punto di interesse',
	},
	'segment___p___h24': {
		de_de: 'Dieser Punkt von Interesse ist 24 Stunden am Tag, 7 Tage die Woche zugänglich',
		en_us: 'This point of interest is accessible 24/7',
		es_es: 'Este punto de interés está accesible 24/7',
		fr_fr: 'Ce point d\'intérêt est accessible 24 heures sur 24, 7 jours sur 7',
		it_it: 'Questo punto di interesse è accessibile 24 ore su 24 e 7 giorni su 7',
	},
	'hours___from_to': {
		de_de: 'von {{1}} bis {{2}}',
		en_us: 'from {{1}} to {{2}}',
		es_es: 'de {{1}} a {{2}}',
		fr_fr: 'de {{1}} à {{2}}',
		it_it: 'dalle {{1}} alle {{2}}',
	},
	'hours___from': {
		de_de: 'von ',
		en_us: 'from ',
		es_es: 'de ',
		fr_fr: 'de ',
		it_it: 'dalle ',
	},
	'hours___to': {
		de_de: ' bis ',
		en_us: ' to ',
		es_es: ' a ',
		fr_fr: ' à ',
		it_it: ' alle ',
	},
	'button___update_timetable': {
		de_de: 'Zeitplan aktualisieren',
		en_us: 'Update Timetable',
		es_es: 'Actualizar Horario',
		fr_fr: 'Mettre à jour l\'horaire',
		it_it: 'Aggiorna Orario',
	}
};