import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbChatsWithPage: type___locale___translations___collection = {
	'go_to_profile': {
		de_de: 'Zum Profil gehen',
		en_us: 'Go to profile',
		es_es: 'Ir al perfil',
		fr_fr: 'Aller au profil',
		it_it: 'Vai al profilo',
	},
	'field___write_message': {
		de_de: 'Schreiben Sie eine Nachricht...',
		en_us: 'Write a message...',
		es_es: 'Escribe un mensaje...',
		fr_fr: 'Écrire un message...',
		it_it: 'Scrivi un messaggio...',
	}
};