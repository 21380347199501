import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonList, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbDigitalMenuEditPage } from './sb-digitalmenu-edit.locale';

//	S T Y L E

import './sb-digitalmenu-edit.scss';

//	S T A T E   -   P R O P S

import { props___SbDigitalMenuEditPage, state___SbDigitalMenuEditPage, state___SbDigitalMenuEditPage___default } from './sb-digitalmenu-edit.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	F U N C S

import { funcs_text } from '../../../funcs/funcs.text';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComDigitalMenuDishComponent from '../../../components/com-digitalmenu-dish/com-digitalmenu-dish';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	M O D A L S

import MdDigitalMenuDishEditModal from '../../../modals/md-digitalmenu-dish-edit/md-digitalmenu-dish-edit';

//	C L A S S

class SbDigitalMenuEditPage extends React.Component<props___SbDigitalMenuEditPage, state___SbDigitalMenuEditPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___text: funcs_text = new funcs_text();

	private media_files___array: string[] = state___SbDigitalMenuEditPage___default['field___poi_gallery'];

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbDigitalMenuEditPage,
	) {
		super(props);
		this.state = state___SbDigitalMenuEditPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___digitalmenu = async () => {
		const ___temp___poi___props: type___api___response = await this._API.digitalmenus___read___single('digitalmenu', this.state.digitalmenu___id);
		if (!___temp___poi___props || ___temp___poi___props.response !== 'success' || ___temp___poi___props.data === null) { console.log('404'); }
		this.setState({
			digitalmenu___props: ___temp___poi___props.data,
			digitalmenu___props___is_loading: false,
		});
	}

	






















//#endregion

//#region 																							H A N D L E R S

	private handle___dish_edit = (___dish_id: string | null, ___dish_category: string | null) => {
		this.setState({
			ionModal___MdDigitalMenuDishEditModal___isOpen: true,
			ionModal___MdDigitalMenuDishEditModal___target_id: ___dish_id,
			ionModal___MdDigitalMenuDishEditModal___target_category: ___dish_category,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private submit___poi___check_if_can = () : boolean => {

		const ___can_submit: boolean = true;

		

		return ___can_submit as boolean;

	}

	private submit___poi = () => {

		const ___can_submit: boolean = this.submit___poi___check_if_can();

		


	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		this.setState({
			digitalmenu___id: ___poi_id,
		}, async () => {
			await this.read_props___digitalmenu();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdDigitalMenuDishEditModal isOpen={ this.state.ionModal___MdDigitalMenuDishEditModal___isOpen } dish_digitalmenu={ this.state.digitalmenu___id } dish_id={ this.state.ionModal___MdDigitalMenuDishEditModal___target_id } dish_category={ this.state.ionModal___MdDigitalMenuDishEditModal___target_category } event___onDidDismiss={() => { this.setState({ ionModal___MdDigitalMenuDishEditModal___isOpen: false, ionModal___MdDigitalMenuDishEditModal___target_id: null, ionModal___MdDigitalMenuDishEditModal___target_category: null }); }}></MdDigitalMenuDishEditModal>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/archive" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.digitalmenu___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						{(this.state.digitalmenu___props !== null) ? <>

							<section className="sb-digitalmenu-edit---container">

								{this.state.digitalmenu___props['digitalmenu_contents'].map((___d_c: any, ___d_c___idx: number) => {
									return <>
										<IonCard key={ ___d_c___idx } className="sb-digitalmenu-edit---card">
											<IonItem className="sb-digitalmenu-edit---card---title">
												<div>
													<img src={ '/assets/digitalmenu/categories/' + ___d_c['dish_category_icon'] + '.svg' } />
													<h6>{ ___d_c['dish_category_name'] }</h6>
												</div>
											</IonItem>
											<IonList>
												{___d_c['dish_category_contents'].map((___dish: any, ___dish___idx: number) => {
													return <>
														<ComDigitalMenuDishComponent key={ ___dish___idx } dish_props={ ___dish } dish_icon={ ___d_c['dish_category_icon'] } event___onClick={(___dish_id: string) => { this.handle___dish_edit(___dish_id, ___d_c['dish_category_id']); }}/>
													</>;
												})}
											</IonList>
											<p onClick={() => { this.handle___dish_edit(null, ___d_c['dish_category_id']); }}>
												<i className="fas fa-plus"></i>
												<span>{ this._LOCALE.translate(locale___SbDigitalMenuEditPage.button___add_product)}</span>
											</p>
										</IonCard>
										<br />
									</>;
								})}

							</section>

						</> : <>

							<Com404Component/>

						</>}

						<br />
						<br />
						<br />

					</>}
				</IonContent>

			</IonPage>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbDigitalMenuEditPage);