import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonToast, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-poi-view-settings.scss';

//	S T A T E   -   P R O P S

import { props___SbPoiViewSettingsPage, state___SbPoiViewSettingsPage, state___SbPoiViewSettingsPage___default } from './sb-poi-view-settings.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerTagComponent from '../../../components/com-spinner-tag/com-spinner-tag';

//	C L A S S

class SbPoiViewSettingsPage extends React.Component<props___SbPoiViewSettingsPage, state___SbPoiViewSettingsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiViewSettingsPage,
	) {
		super(props);
		this.state = state___SbPoiViewSettingsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		if (!___temp___poi___props || ___temp___poi___props.response !== 'success' || ___temp___poi___props.data === null) { console.log('404'); }
		this.setState({
			poi___props: ___temp___poi___props.data,
			poi___props___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			poi___props: null,
			poi___props___is_loading: true,
		}, async () => {
			await this.read_props___poi();
			___e.detail.complete();
		});
	}

	private handle___create_service___digitalmenu = () => {
		this.setState({
			service_created___digitalmenu___is_loading: true,
		}, async () => {
			const ___poi_digitalmenu___created: type___api___response = await this._API.digitalmenus___create(this.state.poi___id);
			if (___poi_digitalmenu___created.data && ___poi_digitalmenu___created.data !== null) {
				this.setState({
					ionToast___service_created___digitalmenu___isVisible: true,
					service_created___digitalmenu___is_loading: false
				}, async () => {
					await this.read_props___poi();
				});
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_id) {
			this.setState({
				poi___id: ___poi_id,
			}, async () => {
				await this.read_props___poi();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonToast isOpen={ this.state.ionToast___service_created___digitalmenu___isVisible } message="Menu digitale Creato" duration={ 3000 } color="success" position="top" onDidDismiss={() => { this.setState({ ionToast___service_created___digitalmenu___isVisible: false }); }}/>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={ '/view/poi/' + this.state.poi___id }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.poi___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerInlineComponent/>
						</IonRefresher>

						{(this.state.poi___props !== null) ? <>
							<div className="sb-page---container sb-poi-view-feed---container">

								<ComPoiComponent poi_props={ this.state.poi___props } size='large'/>

								<br />

								{(
									(this.state.poi___props['poi_is_mine'] && this.state.poi___props['poi_is_mine'] === true)
								) ? <>

									{(this.state.poi___props['poi_category']['poi_category_enabled___digitalMenu'] && this.state.poi___props['poi_category']['poi_category_enabled___digitalMenu'] === true) ? <>
										<IonCard>
											<IonList>
												{(this.state.service_created___digitalmenu___is_loading === true) ? <>
													<IonItem>
														<IonLabel style={{textAlign:'center'}}>
															<ComSpinnerTagComponent/>
														</IonLabel>
													</IonItem>
												</> : <>
													{(this.state.poi___props['poi_service_digitalmenu'] && this.state.poi___props['poi_service_digitalmenu'] !== null) ? <>
														<IonItem className="is-ion-item-button" button={ true } routerLink={ '/digitalmenu/edit/' + this.state.poi___props['poi_service_digitalmenu'] } routerDirection="forward">
															<i className="fas fa-utensils"></i>
															<IonLabel>Modifica Menu Digitale</IonLabel>
														</IonItem>
													</> : <>
														<IonItem className="is-ion-item-button" button={ true } onClick={ this.handle___create_service___digitalmenu } routerDirection="forward">
															<i className="fas fa-utensils"></i>
															<IonLabel>Crea Menu Digitale</IonLabel>
														</IonItem>
													</>}
												</>}
											</IonList>
										</IonCard>

										<br />

									</> : <></>}

									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" button={ true } routerLink={ '/edit/poi/' + this.state.poi___props['poi_id'] } routerDirection="forward">
												<i className="fas fa-edit"></i>
												<IonLabel>Modifica Punto di interesse</IonLabel>
											</IonItem>
											<IonItem className="is-ion-item-button" button={ true } routerLink={ '/edit/poi/' + this.state.poi___props['poi_id'] + '/roles' } routerDirection="forward">
												<i className="fas fa-user-tag"></i>
												<IonLabel>Gestisci Ruoli</IonLabel>
											</IonItem>
											<IonItem className="is-ion-item-button" button={ true } routerLink={ '/edit/poi/' + this.state.poi___props['poi_id'] } routerDirection="forward">
												<i className="fas fa-people-arrows"></i>
												<IonLabel>Trasferisci Proprietà</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>

									<br />

									<IonCard color="danger">
										<IonList color="danger">
											<IonItem color="danger" className="is-ion-item-button" button={ true }>
												<i className="fas fa-trash-alt"></i>
												<IonLabel>Elimina Permanentemente</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>

								</> : <></>} 

							</div>
						</> : <>
							<Com404Component/>
						</>}

					</>}
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiViewSettingsPage);