import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdEntryDownloadAppModal: type___locale___translations___collection = {
	'description': {
		de_de: 'Für ein besseres Erlebnis auf Nearby laden Sie die App aus Ihrem Smartphone-Shop herunter',
		en_us: 'For a better experience on Nearby download the app from your smartphone store',
		es_es: 'Para una mejor experiencia en Nearby, descargue la aplicación desde la tienda de su teléfono inteligente',
		fr_fr: 'Pour une meilleure expérience sur Nearby, téléchargez l\'application depuis la boutique de votre smartphone',
		it_it: 'Per una migliore esperienza su Nearby scarica l\'app dallo tuo store del tuo smartphone',
	},
	'link_or': {
		de_de: 'oder',
		en_us: 'or',
		es_es: 'o',
		fr_fr: 'ou',
		it_it: 'o',
	},
	'close': {
		de_de: 'Schließen Sie die Webversion und verwenden Sie sie',
		en_us: 'close and use the web version',
		es_es: 'cerrar y usar la versión web',
		fr_fr: 'fermez et utilisez la version Web',
		it_it: 'chiudi ed usa la versione web',
	},
};