import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbElementInvite: type___locale___translations___collection = {
	'invite_received': {
		de_de: 'Du hast eine Einladung zu einer Veranstaltung in der Nähe erhalten, die auf deine Interessen abgestimmt ist',
		en_us: 'You have received an invitation to a nearby event based on your interests',
		es_es: 'Has recibido una invitación a un evento cercano basado en tus intereses',
		fr_fr: 'Vous avez reçu une invitation à un événement proche en fonction de vos intérêts',
		it_it: 'Hai ricevuto un invito ad un evento nelle vicinanze in base ai tuoi interessi',
	},
	'who_invited': {
		de_de: 'Hat dich eingeladen',
		en_us: 'Has invited you',
		es_es: 'Te ha invitado',
		fr_fr: 'Vous a invité',
		it_it: 'Ti ha invitato',
	},
	'go_to_event': {
		de_de: 'Zum Ereignis gehen',
		en_us: 'Go to event',
		es_es: 'Ir al evento',
		fr_fr: 'Aller à l\'événement',
		it_it: 'Vai all\'evento',
	}
};