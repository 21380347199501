//	S T A T E

export interface state___SbSettingsPrivacyPage {

	user___props: any | null,
	user___props___is_loading: boolean,
	
	user_privacy___profile_public: boolean,
	user_privacy___contactable: boolean,
	user_privacy___exclude_from_search: boolean,
	user_privacy___only_close_friends: boolean,

	ionToast___privacy_is_submitted___result: 'success' | 'error' | null

}

//	S T A T E   D E F A U L T

export const state___SbSettingsPrivacyPage___default: state___SbSettingsPrivacyPage = {


	user___props: null,
	user___props___is_loading: true,

	user_privacy___profile_public: false,
	user_privacy___contactable: false,
	user_privacy___exclude_from_search: false,
	user_privacy___only_close_friends: false,

	ionToast___privacy_is_submitted___result: null

}