import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { shareOnMobile } from "react-mobile-share";

//	L O C A L I Z A T I O N

import { locale___App } from '../../App.locale';
import { locale___SbInvitePage } from './sb-invite.locale';

//	S T Y L E

import './sb-invite.scss';

//	I N T E R F A C E S

import { props___SbInvitePage, state___SbInvitePage, state___SbInvitePage___default } from './sb-invite.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

class SbInvitePage extends React.Component<props___SbInvitePage, state___SbInvitePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbInvitePage,
	) {
		super(props);
		this.state = state___SbInvitePage___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___share_app = async () => {
		
		const ___url: string = 'https://www.nearbycommunity.it';
		const ___url___title: string = this._LOCALE.translate(locale___SbInvitePage.share_title);
		const ___url___copy: string = this._LOCALE.translate(locale___SbInvitePage.share_text, [___url]);
		
		try {

			shareOnMobile({
				text: ___url___copy,
				url: ___url,
				title: ___url___title,
			});

		} catch (___e) {
		
			console.log('Using Navigator   -->   ', ___e);

			if (navigator.share)
			{
				try {
					await navigator.share({
						title: ___url___title,
						text: ___url___copy,
						url: ___url,
					});
					return 'shared';
				} catch (___e_n: any) {
					console.log('Error sharing   -->   ', ___e_n);
				}
			}

			try {
				await navigator.clipboard.writeText(___url___copy);
				return 'copyed';
			} catch (___e_c: any) {
				console.error('Error copyng   -->   ', ___e_c);
			}

		}

		return null;

	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		this.setState({
			...state___SbInvitePage___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render()
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<div className="sb-invite---container">

						<img src="assets/join-nearby.png" />

						<br />

						<h2>{ this._LOCALE.translate(locale___SbInvitePage.text___question) }</h2>
						<h4>{ this._LOCALE.translate(locale___SbInvitePage.text___answer) }</h4>
						
						<br />
						<br />
						<br />
					
						<div onClick={ this.handle___share_app }>
							<i className="fas fa-share-alt"></i>
							<span>{ this._LOCALE.translate(locale___SbInvitePage.text___button) }</span>
						</div>

						<br />
						<br />
						<br />

					</div>
				</IonContent>

			</IonPage>
		</>
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInvitePage);