import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdPoiSelectModal: type___locale___translations___collection = {
	'title': {
		de_de: 'POI zum Zuordnen auswählen',
		en_us: 'Select POI to Match',
		es_es: 'Seleccionar POI para emparejar',
		fr_fr: 'Sélectionner le POI à associer',
		it_it: 'Seleziona Poi da abbinare',
	},
	'field___search___placedholder': {
		de_de: 'In deinen POIs suchen',
		en_us: 'Search among your POIs',
		es_es: 'Buscar entre tus POIs',
		fr_fr: 'Rechercher parmi vos POI',
		it_it: 'Cerca tra i tuoi POI',
	},
	'field___search___not_found': {
		de_de: 'Kein POI zum Auswählen',
		en_us: 'No POI to select',
		es_es: 'No hay POI para seleccionar',
		fr_fr: 'Aucun POI à sélectionner',
		it_it: 'Nessun poi da selezionare',
	}
};