import React from 'react';
import { IonCard, IonContent, IonFooter, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonNote, IonSegment, IonSegmentButton } from '@ionic/react';

//	L O C A L E

import { locale___MdPoiTimelineEditModal } from './md-poi-timetable-edit.locale';

//	S T Y L E

import './md-poi-timetable-edit.scss';

//	S T A T E   -   P R O P S

import { props___MdPoiTimetableEditModal, state___MdPoiTimetableEditModal, state___MdPoiTimetableEditModal___default } from './md-poi-timetable-edit.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	T Y P E S

import { type___modal___config } from '../../types/types.modals';
import { type___poi___timetable, type___poi___timetable___day_index, type___poi___timetable___hour } from '../../types/types.types';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdPoiTimetableEditModal extends React.Component<props___MdPoiTimetableEditModal, state___MdPoiTimetableEditModal>
{

//#region 																							C O N F I G

	private readonly MdPoiTimetableEditModal___modal_config: type___modal___config = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
	};

//#endregion

//#region 																							D E C L A R A T I O N

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPoiTimetableEditModal,
	) {
		super(props);
		this.state = state___MdPoiTimetableEditModal___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___timetable___add = () => {
		const ___day: string = this.state.timetable___field___newItem;
		const ___timetable___temp: type___poi___timetable = this.state.timetable___field___week;
		___timetable___temp[___day].push({ from: this.state.timetable___field___newItem___value___from, to: this.state.timetable___field___newItem___value___to });
		this.setState({
			timetable___field___week: ___timetable___temp,
			timetable___field___newItem: null,
			timetable___field___newItem___value___from: null,
			timetable___field___newItem___value___to: null,
		});
	}

	private handle___timetable___remove = (___day: string, ___hour_index: number) => {
		const ___timetable___temp: type___poi___timetable = this.state.timetable___field___week;
		___timetable___temp[___day].splice(___hour_index, 1);
		this.setState({
			timetable___field___week: ___timetable___temp
		});
	}

//#endregion

//#region 																							S U B M I T

	private submit___poi_timetable = () => {
		let ___new_timetable: type___poi___timetable = 'none';
		if (this.state.timetable___field___type === 'none') { ___new_timetable = 'none'; }
		if (this.state.timetable___field___type === 'h24') { ___new_timetable = 'h24'; }
		if (this.state.timetable___field___type === 'custom') { ___new_timetable = this.state.timetable___field___week; }
		this.props.event___onDidDismiss(___new_timetable);
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount = () => {
		let ___setup_timetable: state___MdPoiTimetableEditModal['timetable___field___type'] = 'none';
		let ___setup_timetable___datas: state___MdPoiTimetableEditModal['timetable___field___week'] = state___MdPoiTimetableEditModal___default.timetable___field___week;
		if (!(this.props.timetable_props ) || this.props.timetable_props === null || this.props.timetable_props === 'none') { ___setup_timetable = 'none'; }
		else if (this.props.timetable_props === 'h24') { ___setup_timetable = 'h24'; }
		else { ___setup_timetable = 'custom'; ___setup_timetable___datas = this.props.timetable_props; }
		this.setState({
			timetable___field___type: ___setup_timetable,
			timetable___field___week: ___setup_timetable___datas,
			timetable___is_loading: false,
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		const ___return___days_of_the_week: type___poi___timetable___day_index[] = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdPoiTimetableEditModal___modal_config }>
			
				<IonContent forceOverscroll={ false }>
					{(this.state.timetable___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>
						<div className="md-poi-timetable---container">

							<br />

							<IonListHeader>
								<IonLabel>
									<i className="fas fa-list"></i>
									<span>{ this._LOCALE.translate(locale___MdPoiTimelineEditModal.title) }</span>
								</IonLabel>
							</IonListHeader>

							<br />
							<br />

							<IonSegment value={ this.state.timetable___field___type } onIonChange={(___e: any) => { this.setState({ timetable___field___type: ___e.detail.value }); }}>
								<IonSegmentButton value="none">
									<IonLabel>{ this._LOCALE.translate(locale___MdPoiTimelineEditModal.segment___none) }</IonLabel>
								</IonSegmentButton>
								<IonSegmentButton value="h24">
									<IonLabel>{ this._LOCALE.translate(locale___MdPoiTimelineEditModal.segment___h24) }</IonLabel>
								</IonSegmentButton>
								<IonSegmentButton value="custom">
									<IonLabel>{ this._LOCALE.translate(locale___MdPoiTimelineEditModal.segment___custom) }</IonLabel>
								</IonSegmentButton>
							</IonSegment>

							<br />
							<br />

							{(() => { switch (this.state.timetable___field___type) {
								case 'none': return <>
									<ComListEmptyComponent text={ this._LOCALE.translate(locale___MdPoiTimelineEditModal.segment___p___none) } />
								</>; break;
								case 'h24': return <>
									<ComListEmptyComponent text={ this._LOCALE.translate(locale___MdPoiTimelineEditModal.segment___p___h24) } />
								</>; break;
								case 'custom': return <>
									{___return___days_of_the_week.map((___day: type___poi___timetable___day_index) => {
										return <>
											<IonCard>
												<IonList>
													<IonItem className="is-ion-item-button" onClick={() => { this.setState({ timetable___field___newItem: ___day }); }}>
														<i className="fas fa-calendar"></i>
														<IonLabel>
															<b>{ this.funcs___datetime.datetime___get___dayOfTheWeek(___day) }</b>
														</IonLabel>
														<IonNote slot="end"><i className="fas fa-plus"></i></IonNote>
													</IonItem>
												</IonList>
												{(this.state.timetable___field___week[___day].length > 0) ? <>
													<IonList>
														{this.state.timetable___field___week[___day].map((___h: type___poi___timetable___hour, ___h___idx: number) => {
															return <>
																<IonItem key={ ___h___idx } className="is-ion-item-button">
																	<i></i>
																	<i className="far fa-clock"></i>
																	<IonLabel>{ this._LOCALE.translate(locale___MdPoiTimelineEditModal.hours___from_to, [___h['from'], ___h['to']]) }</IonLabel>
																	<IonNote slot="end" color="danger" onClick={() => { this.handle___timetable___remove(___day, ___h___idx); }}>
																		<i className="fas fa-times"></i>
																	</IonNote>
																</IonItem>
															</>;
														})}
													</IonList>
												</> : <></>}
												{(this.state.timetable___field___newItem === ___day) ? <>
													<IonList>
														<IonItem className="is-ion-item-button">
															<i className="far fa-plus"></i>
															<IonLabel>
																<span style={{paddingRight:'10pt'}}>{ this._LOCALE.translate(locale___MdPoiTimelineEditModal.hours___from) } </span>
																<input type="time" value={ this.state.timetable___field___newItem___value___from } onInput={(___e: any) => { this.setState({ timetable___field___newItem___value___from: ___e.target.value }); }}/>
																<span style={{paddingRight:'10pt'}}> { this._LOCALE.translate(locale___MdPoiTimelineEditModal.hours___to) } </span>
																<input type="time" value={ this.state.timetable___field___newItem___value___to } onInput={(___e: any) => { this.setState({ timetable___field___newItem___value___to: ___e.target.value }); }}/>
															</IonLabel>
															<IonNote color="success" onClick={() => { this.handle___timetable___add(); }}>
																<i className="fas fa-check"></i>
															</IonNote>
														</IonItem>
													</IonList>
												</> : <></>}
											</IonCard>
										</>;
									})}
								</>; break;
							}})()}

						</div>
					</>}
				</IonContent>

				<IonFooter>
					{(this.state.timetable___is_loading === true) ? <>
						<ComSpinnerInlineComponent/>
					</> : <>
						<section className="container---footer-section" onClick={() => { this.submit___poi_timetable(); }}>
							<button className="is-element is-button is-color---folly is-full-width">
								<i className="fa-solid fa-check"></i>
								<h4>{ this._LOCALE.translate(locale___MdPoiTimelineEditModal.button___update_timetable) }</h4>
							</button>
						</section>
					</>}
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}