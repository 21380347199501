import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbEventsEditPage: type___locale___translations___collection = {
'ionToast___cannot_save': {
		de_de: 'Bevor Sie speichern, stellen Sie sicher, dass alle hervorgehobenen Felder korrekt ausgefüllt sind',
		en_us: 'Before saving, please ensure all highlighted fields are filled out correctly',
		es_es: 'Antes de guardar, asegúrate de que todos los campos resaltados estén correctamente rellenados',
		fr_fr: 'Avant de sauvegarder, veuillez vous assurer que tous les champs en surbrillance sont correctement remplis',
		it_it: 'Prima di salvare l\'evento controlla che tutti i campi evidenziati siano compilati correttamente',
	},
	'ionToast___event_create': {
		de_de: 'Ereignis erstellt',
		en_us: 'Event Created',
		es_es: 'Evento Creado',
		fr_fr: 'Événement Créé',
		it_it: 'Evento Creato',
	},
	'ionToast___event_creation_error': {
		de_de: 'Fehler bei der Erstellung des Ereignisses',
		en_us: 'Error creating the event',
		es_es: 'Error al crear el evento',
		fr_fr: 'Erreur lors de la création de l\'événement',
		it_it: 'Errore nella creazione dell\'evento',
	},
	'category___title': {
		de_de: 'Kategorie',
		en_us: 'Category',
		es_es: 'Categoría',
		fr_fr: 'Catégorie',
		it_it: 'Categoria',
	},
	'category___category': {
		de_de: 'Kategorie',
		en_us: 'category',
		es_es: 'categoría',
		fr_fr: 'catégorie',
		it_it: 'categoria',
	},
	'category___select': {
		de_de: 'Kategorie auswählen',
		en_us: 'Select Category',
		es_es: 'Seleccionar Categoría',
		fr_fr: 'Sélectionner une Catégorie',
		it_it: 'Seleziona Categoria',
	},
	'poster_and_info___title': {
		de_de: 'Poster und Informationen',
		en_us: 'Poster and Information',
		es_es: 'Póster e Información',
		fr_fr: 'Affiche et Informations',
		it_it: 'Poster e Informazioni',
	},
	'poster_and_info___poster': {
		de_de: 'Ereignisposter',
		en_us: 'event poster',
		es_es: 'póster del evento',
		fr_fr: 'affiche de l\'événement',
		it_it: 'poster dell\'evento',
	},
	'poster_and_info___edit_poster': {
		de_de: 'Poster bearbeiten',
		en_us: 'Edit Poster',
		es_es: 'Editar Póster',
		fr_fr: 'Modifier l\'Affiche',
		it_it: 'Modifica Poster',
	},
	'poster_and_info___field___name': {
		de_de: 'Ereignis',
		en_us: 'event',
		es_es: 'evento',
		fr_fr: 'événement',
		it_it: 'evento',
	},
	'poster_and_info___field___name___placeholder': {
		de_de: 'Name des Ereignisses',
		en_us: 'Event Name',
		es_es: 'Nombre del evento',
		fr_fr: 'Nom de l\'événement',
		it_it: 'Nome dell\'evento',
	},
	'poster_and_info___field___description': {
		de_de: 'Beschreibung',
		en_us: 'description',
		es_es: 'descripción',
		fr_fr: 'description',
		it_it: 'descrizione',
	},
	'poster_and_info___field___description___placeholder': {
		de_de: 'Beschreibung des Ereignisses',
		en_us: 'Event Description',
		es_es: 'Descripción del evento',
		fr_fr: 'Description de l\'événement',
		it_it: 'Descrizione dell\'evento',
	},
	'datetime___title': {
		de_de: 'Datum und Uhrzeit',
		en_us: 'Date and Time',
		es_es: 'Fecha y Hora',
		fr_fr: 'Date et Heure',
		it_it: 'Data e Ora',
	},
	'datetime___begin': {
		de_de: 'Beginn',
		en_us: 'begin',
		es_es: 'inicio',
		fr_fr: 'début',
		it_it: 'inizio',
	},
	'datetime___end': {
		de_de: 'Ende',
		en_us: 'end',
		es_es: 'fin',
		fr_fr: 'fin',
		it_it: 'fine',
	},
	'position___title': {
		de_de: 'Adresse und Standort',
		en_us: 'Address and Location',
		es_es: 'Dirección y Ubicación',
		fr_fr: 'Adresse et Position',
		it_it: 'Indirizzo e Posizione',
	},
	'position___address_in_map': {
		de_de: 'Adresse und Standort auf der Karte',
		en_us: 'address and location on the map',
		es_es: 'dirección y ubicación en el mapa',
		fr_fr: 'adresse et position sur la carte',
		it_it: 'indirizzo e posizione sulla mappa',
	},
	'position___edit_position': {
		de_de: 'Standort bearbeiten',
		en_us: 'Edit position',
		es_es: 'Editar posición',
		fr_fr: 'Modifier la position',
		it_it: 'Modifica posizione',
	},
	'position___insert_position_in_map': {
		de_de: 'Position auf der Karte einfügen',
		en_us: 'insert position on the map',
		es_es: 'insertar posición en el mapa',
		fr_fr: 'insérer la position sur la carte',
		it_it: 'inserisci la posizione sulla mappa',
	},
	'position___insert_position': {
		de_de: 'Position einfügen',
		en_us: 'Insert position',
		es_es: 'Insertar posición',
		fr_fr: 'Insérer la position',
		it_it: 'Inserisci posizione',
	},
	'position___matched': {
		de_de: 'zugeordnet zu ',
		en_us: 'matched to ',
		es_es: 'asociado a ',
		fr_fr: 'associé à ',
		it_it: 'abbinato a ',
	},
	'position___edit_match': {
		de_de: 'Zuordnung bearbeiten',
		en_us: 'Edit match',
		es_es: 'Editar asociación',
		fr_fr: 'Modifier l\'association',
		it_it: 'Modifica abbinamento',
	},
	'position___match_to_poi': {
		de_de: 'Mit einem Punkt von Interesse verknüpfen',
		en_us: 'Match to a Point of Interest',
		es_es: 'Asociar a un punto de interés',
		fr_fr: 'Associer à un point d\'intérêt',
		it_it: 'Abbina ad un punto di interesse',
	},
'contacts___title': {
		de_de: 'Kontakte',
		en_us: 'Contacts',
		es_es: 'Contactos',
		fr_fr: 'Contacts',
		it_it: 'Contatti',
	},
	'contacts___phone': {
		de_de: 'Telefon',
		en_us: 'Phone',
		es_es: 'Teléfono',
		fr_fr: 'Téléphone',
		it_it: 'telefono',
	},
	'contacts___email': {
		de_de: 'E-Mail',
		en_us: 'Email',
		es_es: 'Correo electrónico',
		fr_fr: 'E-mail',
		it_it: 'email',
	},
	'other___title': {
		de_de: 'Andere Einstellungen',
		en_us: 'Other Settings',
		es_es: 'Otras configuraciones',
		fr_fr: 'Autres paramètres',
		it_it: 'Altre impostazioni',
	},
	'other__custom_icon': {
		de_de: 'Benutzerdefiniertes Symbol verwenden',
		en_us: 'Use custom icon',
		es_es: 'Usar icono personalizado',
		fr_fr: 'Utiliser une icône personnalisée',
		it_it: 'Utilizza l\'icona personalizzata',
	},
	'other___user_public': {
		de_de: 'Veranstalter öffentlich machen',
		en_us: 'Make organizer public',
		es_es: 'Hacer público al organizador',
		fr_fr: 'Rendre l\'organisateur public',
		it_it: 'Rendi pubblico l\'organizzatore',
	},
	'other___available_when': {
		de_de: 'online verfügbar ab',
		en_us: 'available online from',
		es_es: 'disponible en línea desde',
		fr_fr: 'disponible en ligne à partir de',
		it_it: 'disponibile online da',
	},
	'button___upload': {
		de_de: 'Veröffentlichen',
		en_us: 'Publish',
		es_es: 'Publicar',
		fr_fr: 'Publier',
		it_it: 'Pubblica',
	},
	'button___upload___update': {
		de_de: 'Veranstaltung aktualisieren',
		en_us: 'Update event',
		es_es: 'Actualizar evento',
		fr_fr: 'Mettre à jour l\'événement',
		it_it: 'Aggiorna l\'evento',
	}
};