import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbArchivePage } from './sb-archive.locale';

//	S T Y L E

import './sb-archive.scss';

//	S T A T E   -   P R O P S

import { props___SbArchivePage, state___SbArchivePage, state___SbArchivePage___default } from './sb-archive.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComNotificationComponent from '../../../components/com-notification/com-notification';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComChatConversationMicroComponent from '../../../components/com-chat-conversation-micro/com-chat-conversation-micro';
import ComSpinnerTagComponent from '../../../components/com-spinner-tag/com-spinner-tag';


//	C L A S S

class SbArchivePage extends React.Component<props___SbArchivePage, state___SbArchivePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbArchivePage,
	) {
		super(props);
		this.state = state___SbArchivePage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user___conversations = async () => {
		const ___temp___conversations: type___api___response = await this._API.chats___read___multi('all', 0);
		this.setState({
			conversations___collection: ___temp___conversations.data,
			conversations___collection___is_loading: false,
		});
	}

	private read_props___user___notifications = async () => {
		const ___temp___notifications: type___api___response = await this._API.notifications___read___multi('preview', 0);
		this.setState({
			notifications___collection: ___temp___notifications.data,
			notifications___collection___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			notifications___collection: [],
			notifications___collection___is_loading: true,
		}, async () => {
			await Promise.all([
				this.read_props___user___conversations(),
				this.read_props___user___notifications(),
			]);
			___e.detail.complete();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		Promise.all([
			this.read_props___user___conversations(),
			this.read_props___user___notifications(),
		]);
	}

	ionViewDidLeave() : void {
		this.setState({
			...state___SbArchivePage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<br />

					<section className="sb-user-archive---container">
						<section className="sb-user-archive---container---chats">
							<Link to="/chats/list">
								<i className="fas fa-envelope"></i>
								<i className="fas fa-plus"></i>
							</Link>
							{(this.state.conversations___collection___is_loading === true) ? <>
								<section>
									<ComSpinnerTagComponent />
								</section>
							</> : <>
								{this.state.conversations___collection.map((___c: any, ___c___idx: number) => {
									if (___c___idx < 5) {
										return <>
											<Link key={ ___c___idx } to={ '/chats/with/' + ___c['chat_with']['user_username'] }>
												<ComChatConversationMicroComponent chat_props={ ___c } />
											</Link>
										</>;
									}
								})}
							</>}
						</section>
					</section>

					<section className="sb-user-archive---container">

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-bell"></i>
								<span>
									<span>{ this._LOCALE.translate(locale___SbArchivePage.notification___title) }</span>
									{(this.props.notification_count && this.props.notification_count > 0) ? <>
										<span style={{opacity:0.5,paddingLeft:'10pt'}}>({ this.props.notification_count.toString() })</span>
									</> : <></>}
								</span>
							</IonLabel>
						</IonListHeader>

						<br />

						{(this.state.notifications___collection___is_loading === true) ? <>
							<ComSpinnerInlineComponent />
						</> : <>
							<section className="sb-user-archive---notifications">
								{(this.state.notifications___collection.length > 0) ? <>
									{this.state.notifications___collection.map((___n: any, ___n___idx: number) => {
										return <>
											<section key={ ___n___idx }>
												<ComNotificationComponent key={ ___n___idx } notification___props={ ___n } />
											</section>
											<ComListSeparatorComponent/>
										</>;
									})}
									<br />
									<section className="sb-user-archive---notifications---more">
										<Link to="/archive/notifications">
											<span>{ this._LOCALE.translate(locale___SbArchivePage.notification___see_all) }</span>
											<i className="fas fa-chevron-right"></i>
										</Link>
									</section>
								</> : <>
									<ComListEmptyComponent text={ this._LOCALE.translate(locale___SbArchivePage.notification___none) } />
								</>}
							</section>
						</>}
					</section>

					<section className="sb-user-archive---container">

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-icons"></i>
								<span>{ this._LOCALE.translate(locale___SbArchivePage.archive___title) }</span>
							</IonLabel>
						</IonListHeader>

						<br />

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } routerLink="/archive/favorites">
									<img src="/assets/ui/icons/icon-star.svg"/>
									<IonLabel>{ this._LOCALE.translate(locale___SbArchivePage.archive___favorites) }</IonLabel>
								</IonItem>
								{/*
									<IonItem className="is-ion-item-button" button={ true } routerLink="/archive/tickets">
										<img src="/assets/ui/icons/icon-ticket.svg"/>
										<IonLabel>I tuoi Tickets</IonLabel>
									</IonItem>
								*/}
							</IonList>
						</IonCard>

						<br />

						{(
							((this.props.is_logged___props['user_permission___events'] && this.props.is_logged___props['user_permission___events'] === true) || (this.props.is_logged___props['user_count___events'] && this.props.is_logged___props['user_count___events'] > 0)) ||
							((this.props.is_logged___props['user_permission___poi'] && this.props.is_logged___props['user_permission___poi'] === true) || (this.props.is_logged___props['user_count___poi'] && this.props.is_logged___props['user_count___poi'] > 0))
						) ? <>
							<IonCard>
								<IonList>
									{(this.props.is_logged___props['user_permission___poi'] && this.props.is_logged___props['user_permission___poi'] === true) || (this.props.is_logged___props['user_count___poi'] && this.props.is_logged___props['user_count___poi'] > 0) ? <>
										<IonItem className="is-ion-item-button" button={ true } routerLink="/archive/poi">
											<img src="/assets/ui/icons/icon-poi.svg"/>
											<IonLabel>{ this._LOCALE.translate(locale___SbArchivePage.archive___your_poi) }</IonLabel>
										</IonItem>
									</> : <>
										<IonItem className="is-ion-item-button" button={ true } routerLink="/archive/enroll">
											<img src="/assets/ui/icons/icon-poi.svg"/>
											<IonLabel>{ this._LOCALE.translate(locale___SbArchivePage.archive___create_poi) }</IonLabel>
										</IonItem>
									</>}
									{(this.props.is_logged___props['user_permission___events'] && this.props.is_logged___props['user_permission___events'] === true) || (this.props.is_logged___props['user_count___events'] && this.props.is_logged___props['user_count___events'] > 0) ? <>
										<IonItem className="is-ion-item-button" button={ true } routerLink="/archive/events">
											<img src="/assets/ui/icons/icon-event.svg"/>
											<IonLabel>{ this._LOCALE.translate(locale___SbArchivePage.archive___your_events) }</IonLabel>
										</IonItem>
									</> : <>
										<IonItem className="is-ion-item-button" button={ true } routerLink="/archive/enroll">
											<img src="/assets/ui/icons/icon-event.svg"/>
											<IonLabel>{ this._LOCALE.translate(locale___SbArchivePage.archive___create_event) }</IonLabel>
										</IonItem>
									</>}
								</IonList>
							</IonCard>
						</> : <>
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button" button={ true } routerLink="/archive/enroll">
										<img src="/assets/ui/icons/icon-poi.svg"/>
										<IonLabel>{ this._LOCALE.translate(locale___SbArchivePage.archive___create_something) }</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>
						</>}
					
						<br />
						<br />
						<br />

					</section>
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbArchivePage);