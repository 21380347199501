import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComInputPassword: type___locale___translations___collection = {
	'placeholder': {
		de_de: 'passwort',
		en_us: 'password',
		es_es: 'contraseña',
		fr_fr: 'mot de passe',
		it_it: 'password',
	},
};