import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComUserRequestComponent: type___locale___translations___collection = {
	'toastNotice___request___is_accepted': {
		de_de: 'Sie haben die Anfrage von {{1}} angenommen',
		en_us: 'You have accepted {{1}}\'s request',
		es_es: 'Has aceptado la solicitud de {{1}}',
		fr_fr: 'Vous avez accepté la demande de {{1}}',
		it_it: 'Hai accettato la richiesta di {{1}}',
	},
	'toastNotice___request___is_rejected': {
		de_de: 'Sie haben die Anfrage von {{1}} abgelehnt',
		en_us: 'You rejected {{1}}\'s request',
		es_es: 'Rechazaste la solicitud de {{1}}',
		fr_fr: 'Vous avez rejeté la demande de {{1}}',
		it_it: 'Hai rifiutato la richeista di {{1}}',
	},
	'toastNotice___request___error': {
		de_de: 'Fehler bei der Verarbeitung der Anfrage...',
		en_us: 'Error processing your request...',
		es_es: 'Error al procesar su solicitud...',
		fr_fr: 'Erreur lors du traitement de votre demande...',
		it_it: 'Errore nell\'elaborazione della richiesta...',
	},
	'request___paragraph': {
		de_de: '{{1}} hat darum gebeten, Ihnen zu folgen.',
		en_us: '{{1}} asked to follow you.',
		es_es: '{{1}} pidió seguirte.',
		fr_fr: '{{1}} a demandé à vous suivre.',
		it_it: '{{1}} ha chiesto di seguirti.',
	},
	'request___accept': {
		de_de: 'Akzeptieren',
		en_us: 'Accept',
		es_es: 'Aceptar',
		fr_fr: 'Accepter',
		it_it: 'Accetta',
	},
	'request___reject': {
		de_de: 'Verweigern',
		en_us: 'Refuse',
		es_es: 'Rechazar',
		fr_fr: 'Refuser',
		it_it: 'Rifiuta',
	},
};