import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComButtonShareComponent: type___locale___translations___collection = {
	'share___internal': {
		de_de: 'Teilen auf Nearby',
		en_us: 'Share on Nearby',
		es_es: 'Compartir en Nearby',
		fr_fr: 'Partager sur Nearby',
		it_it: 'Condividi su Nearby',
	},
	'share___external': {
		de_de: 'Extern teilen',
		en_us: 'Share externally',
		es_es: 'Compartir externamente',
		fr_fr: 'Partager en externe',
		it_it: 'Condividi esternamente',
	},
};