import { interface___is_logged } from '../../../interfaces/interface.logged'
import { interface___has_notifications } from '../../../interfaces/interface.notifications'
import { type___map___filters, type___map___element___type } from '../../../types/types.types'

//	P R O P S

export interface props___SbDiscoverMapPage extends interface___is_logged, interface___has_notifications {

}

//	S T A T E

export interface state___SbDiscoverMapPage {

	discover___search___param: string,

	discover___search_cities: any[],
	discover___search_cities___is_loading: boolean,
	discover___search_poi: any[],
	discover___search_poi___is_loading: boolean,

	map___search_filters: type___map___filters,

	map___is_loading___events: boolean,
	map___is_loading___poi: boolean,
	map___is_loading___tales: boolean,

	map___element_pane: 'element' | 'navigation',
	map___element_pane___content_type: type___map___element___type,
	map___element_pane___content_id: string | null,
	map___element_pane___content_props: any | null,

	ionModal___MdDiscoverCompassModal___isOpen: boolean,
	ionModal___MdDiscoverFiltersModal___isOpen: boolean,
	ionModal___MdDiscoverSearchModal___isOpen: boolean,
	ionModal___MdTalesCreateModal___isOpen: boolean,
	ionModal___MdTalesViewModal___isOpen: boolean | null,
	ionModal___MdTalesViewModal___argument: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbDiscoverMapPage___defaults: state___SbDiscoverMapPage = {

	discover___search___param: '',

	discover___search_cities: [],
	discover___search_cities___is_loading: false,
	discover___search_poi: [],
	discover___search_poi___is_loading: false,

	map___is_loading___events: false,
	map___is_loading___poi: false,
	map___is_loading___tales: false,

	map___search_filters: {
		show_events: true,
		show_poi: true,
		show_tales: true,
		smart_ai: true,
		map_style: 'normal'
	},

	map___element_pane: 'navigation',
	map___element_pane___content_type: null,
	map___element_pane___content_id: null,
	map___element_pane___content_props: null,

	ionModal___MdDiscoverCompassModal___isOpen: false,
	ionModal___MdDiscoverFiltersModal___isOpen: false,
	ionModal___MdDiscoverSearchModal___isOpen: false,
	ionModal___MdTalesCreateModal___isOpen: false,
	ionModal___MdTalesViewModal___isOpen: false,
	ionModal___MdTalesViewModal___argument: null,

}