import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComTaleViewInner: type___locale___translations___collection = {
	'ionActionSheet___taleActions___delete': {
		de_de: 'Tale löschen',
		en_us: 'Delete Tale',
		es_es: 'Eliminar Tale',
		fr_fr: 'Supprimer Tale',
		it_it: 'Elimina Tale',
	},
	'ionAlert___taleActionRemove___title': {
		de_de: 'Aufmerksamkeit',
		en_us: 'Attention',
		es_es: 'Atención',
		fr_fr: 'Attention',
		it_it: 'Attenzione',
	},
	'ionAlert___taleActionRemove___message': {
		de_de: 'Sie sind dabei, dieses Tale zu löschen. Der Vorgang kann nicht rückgängig gemacht werden. Möchten Sie fortfahren?',
		en_us: 'You are about to delete this Tale, the action is irreversible. Do you want to continue?',
		es_es: 'Estás a punto de eliminar este Tale, la acción es irreversible. ¿Quieres continuar?',
		fr_fr: 'Vous êtes sur le point de supprimer cet Tale, l\'action est irréversible. Voulez-vous continuer?',
		it_it: 'Stai per eliminare questa Tale, l\'azione è irreversibile. Vuoi continuare?',
	},
	'ionToast___errorDeleting': {
		de_de: 'Fehler beim Löschen von Tale',
		en_us: 'Error deleting Tale',
		es_es: 'Error al eliminar Tale',
		fr_fr: 'Erreur lors de la suppression de Tale',
		it_it: 'Errore nell\'eliminazione della Tale',
	},
	'ionToast___isDeleted': {
		de_de: 'Tale eliminiert',
		en_us: 'Tale eliminated',
		es_es: 'Tale eliminado',
		fr_fr: 'Tale éliminé',
		it_it: 'Tale eliminata',
	},
	'reply___to_this_tale': {
		de_de: 'Antworte hierauf Tale...',
		en_us: 'Reply to this Tale...',
		es_es: 'Responder a esto Tale...',
		fr_fr: 'Répondre à ça Tale...',
		it_it: 'Rispondi a questa Tale...',
	},
	'tale___at': {
		de_de: 'bei',
		en_us: 'at ',
		es_es: 'en ',
		fr_fr: 'chez ',
		it_it: 'presso ',
	},
	'datetime_today_at': {
		de_de: 'Heute um 10.10 Uhr',
		en_us: 'Today at 10.10am',
		es_es: 'Hoy a las 10.10',
		fr_fr: 'Aujourd\'hui à 10h10',
		it_it: 'Oggi alle {{1}}',
	},
	'datetime_date_at': {
		de_de: '{{1}} um 10:10 Uhr',
		en_us: '{{1}} at 10:10',
		es_es: '{{1}} a las 10:10',
		fr_fr: '{{1}} à 10h10',
		it_it: '{{1}} alle {{2}}',
	},
};