import React from 'react'
import { Link } from 'react-router-dom';

//	L O C A L E

import { locale___ComChatMessageAttachmentComponent } from './com-chat-message-attachment.locale';

//	S T Y L E

import './com-chat-message-attachment.scss'

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___message___attachment_type, type___message___type } from '../../types/types.messages';

//	S T A T E   -   P R O P S

import { props___ComChatMessageAttachmentComponent, state___ComChatMessageAttachmentComponent, state___ComChatMessageAttachmentComponent___default } from './com-chat-message-attachment.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComChatMessageAttachmentUnavailableComponent from '../com-chat-message-attachment-unavailable/com-chat-message-attachment-unavailable';
import ComSpinnerInlineComponent from '../com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class ComChatMessageAttachmentComponent extends React.Component<props___ComChatMessageAttachmentComponent, state___ComChatMessageAttachmentComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComChatMessageAttachmentComponent,
	) {
		super(props);
		this.state = state___ComChatMessageAttachmentComponent___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private readonly read_props___attachment___tale = () => {
		this.setState({
			attachment_props___is_loading: true,
		}, async () => {
			const ___temp___attachment_tale: type___api___response = await this._API.tales___read___single(this.state.attachment_id);

			console.log('___temp___attachment_tale', ___temp___attachment_tale)

			this.setState({
				attachment_props: ___temp___attachment_tale.data,
				attachment_props___is_loading: false,
			});
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {
		this.setState({
			attachment_type: (this.props && this.props.message_props && this.props.message_props['message_attachment_type']) ? this.props.message_props['message_attachment_type'] : null,
			attachment_id: (this.props && this.props.message_props && this.props.message_props['message_attachment_target']) ? this.props.message_props['message_attachment_target'] : null,
		}, () => {
			switch (this.state.attachment_type) {
				case 'tale': this.read_props___attachment___tale(); break;



			}
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.props && this.props.message_props && this.props.message_props['message_attachment_type']) ? <>
				<div className={ 'com-chat-message-attachment---container com-chat-message-attachment---' + (this.props.message_props['message_type'] as type___message___type) }>
					{(this.state.attachment_props___is_loading === true) ? <>
						<ComSpinnerInlineComponent/>
					</> : <>
						{(this.state.attachment_props && this.state.attachment_props !== null) ? <>
							{(() => { switch (this.props.message_props['message_attachment_type'] as type___message___attachment_type) {
								case 'tale': return <>
									<Link to={ '/show/tale/' + this.state.attachment_id } className="com-chat-message-attachment---tale">
										<img src={ this.state.attachment_props['tale_media'] } />
										{(() => { switch (this.props.message_props['message_type'] as type___message___type) {
											case 'received': return <><p>{ this._LOCALE.translate(locale___ComChatMessageAttachmentComponent.tale___he_respond) }</p></>; break;
											case 'sent': return <><p>{ this._LOCALE.translate(locale___ComChatMessageAttachmentComponent.tale___you_respond) }</p></>; break;
										}})()}
									</Link>
								</>; break;
							}})()}
						</> : <>
							<ComChatMessageAttachmentUnavailableComponent attachment_type={ this.props.message_props['message_attachment_type'] } />
						</>}







					</>}
				</div>
			</> : <></>}
		</>;
	}

//#endregion

}