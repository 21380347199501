import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonLabel, RefresherEventDetail, IonListHeader, IonPage, IonRefresher, IonToolbar, withIonLifeCycle, IonList, IonItem } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbArchiveEventsPage } from './sb-archive-events.locale';

//	S T Y L E

import './sb-archive-events.scss';

//	S T A T E   -   P R O P S

import { props___SbArchiveEventsPage, state___SbArchiveEventsPage, state___SbArchiveEventsPage___default } from './sb-archive-events.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-event/com-event';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

class SbArchiveEventsPage extends React.Component<props___SbArchiveEventsPage, state___SbArchiveEventsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbArchiveEventsPage,
	) {
		super(props);
		this.state = state___SbArchiveEventsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user = async () => {
		const ___temp___me_props: type___api___response = await this._API.users___read___single('me', null);
		if (!___temp___me_props || ___temp___me_props.response !== 'success' || ___temp___me_props.data === null) { console.log('404'); }
		this.setState({
			me___props: ___temp___me_props.data,
		}, async () => {
			await this.read_props___events_collection();
		});
	}

	private read_props___events_collection = async () => {
		const ___temp___events_collection: type___api___response = await this._API.events___read___multi('mine', null, this.state.events___collection.length);
		this.setState((prevState) => ({
			events___collection: [...prevState.events___collection, ...___temp___events_collection.data],
			events___collection___is_loading: false,
			events___collection___is_complete: (___temp___events_collection.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			events___collection: [],
			events___collection___is_loading: true,
		}, async () => {
			await this.read_props___user();
			___e.detail.complete();
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			events___collection___is_loading: true
		}, async () => {
			await this.read_props___events_collection();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___user();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<section className="sb-user-portfolio---container">

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-calendar"/>
								<span>{ this._LOCALE.translate(locale___SbArchiveEventsPage.title) }</span>
							</IonLabel>
						</IonListHeader>

						<br />

						{(this.props.is_logged___props['user_permission___events'] && this.props.is_logged___props['user_permission___events'] === true) ? <>
							<Link to="/edit/events/new">
								<IonCard>
									<IonList>
										<IonItem className="is-ion-item-button" button={ true }>
											<i className="fas fa-plus"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbArchiveEventsPage.button___create_event) }</IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
							</Link>
							<br />
							<br />
						</> : <></>}

						{this.state.events___collection.map((___e: any, ___e___idx: number) => {
							return <>
								<Link to={ '/view/events/' + ___e['event_id'] } key={ ___e___idx }>
									<ComEventComponent event_props={ ___e } size="normal" />
								</Link>
								<ComListSeparatorComponent />
							</>;
						})}

						{(this.state.events___collection___is_complete === true) ? <>
							<ComListEmptyComponent list_items_quantity={ this.state.events___collection.length }/>
						</> : <>
							<ComInfiniteScrollerComponent is_loading={ this.state.events___collection___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
						</>}

						<br />
						<br />

					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbArchiveEventsPage);