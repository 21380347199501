import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdPostsCreateModal: type___locale___translations___collection = {
	'ionAlert___cancel___title': {
		de_de: 'Achtung',
		en_us: 'Attention',
		es_es: 'Atención',
		fr_fr: 'Attention',
		it_it: 'Attenzione',
	},
	'ionAlert___cancel___description': {
		de_de: 'Möchten Sie die Änderungen an diesem Beitrag verwerfen?',
		en_us: 'Do you want to discard changes to this Post?',
		es_es: '¿Quieres descartar los cambios de este Post?',
		fr_fr: 'Voulez-vous supprimer les modifications de ce post ?',
		it_it: 'Vuoi eliminare le modifiche a questo Post?',
	},
	'ionToast___error': {
		de_de: 'Fehler beim Posten des Beitrags, bitte versuche es erneut...',
		en_us: 'Error publishing the post, please try again...',
		es_es: 'Error al publicar el post, intenta de nuevo...',
		fr_fr: 'Erreur lors de la publication du post, veuillez réessayer...',
		it_it: 'Errore nella pubblicazione della tale, riprova...',
	},
	'title': {
		de_de: 'Post schreiben',
		en_us: 'Write Post',
		es_es: 'Escribir Post',
		fr_fr: 'Écrire un post',
		it_it: 'Scrivi Post',
	},
	'writing_as___name': {
		de_de: 'du postest als {{1}}',
		en_us: 'you are posting as {{1}}',
		es_es: 'estás publicando como {{1}}',
		fr_fr: 'vous publiez en tant que {{1}}',
		it_it: 'stai pubblicando come {{1}}',
	},
	'writing_on___your_profile': {
		de_de: 'du postest auf deinem Profil',
		en_us: 'you are posting on your profile',
		es_es: 'estás publicando en tu perfil',
		fr_fr: 'vous publiez sur votre profil',
		it_it: 'stai pubblicando sul tuo profilo',
	},
	'writing_on___name_profile': {
		de_de: 'du postest auf dem Profil von {{1}}',
		en_us: 'you are posting on {{1}}\'s profile',
		es_es: 'estás publicando en el perfil de {{1}}',
		fr_fr: 'vous publiez sur le profil de {{1}}',
		it_it: 'stai pubblicando sul profilo di {{1}}',
	},
	'sharing___poi': {
		de_de: 'du teilst diesen POI auf deinem Profil:',
		en_us: 'you are sharing on your profile the POI:',
		es_es: 'estás compartiendo en tu perfil el POI:',
		fr_fr: 'vous partagez sur votre profil le POI :',
		it_it: 'stai condividendo sul tuo profilo il POI:',
	},
	'sharing___event': {
		de_de: 'du teilst dieses Ereignis auf deinem Profil:',
		en_us: 'you are sharing on your profile the event:',
		es_es: 'estás compartiendo en tu perfil el evento:',
		fr_fr: 'vous partagez sur votre profil l\'événement :',
		it_it: 'stai condividendo sul tuo profilo l\'evento:',
	},
	'something_gone_wrong': {
		de_de: 'Etwas ist schief gelaufen...',
		en_us: 'Something went wrong...',
		es_es: 'Algo salió mal...',
		fr_fr: 'Quelque chose a mal tourné...',
		it_it: 'Qualcosa è andato storto...',
	},
	'button___save': {
		de_de: 'Post veröffentlichen',
		en_us: 'Publish Post',
		es_es: 'Publicar Post',
		fr_fr: 'Publier le post',
		it_it: 'Pubblica Post',
	}
};