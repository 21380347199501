import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdEntryChangelogComponent: type___locale___translations___collection = {
	'whats_new': {
		de_de: 'Was ist neu?',
		en_us: 'What\'s New?',
		es_es: '¿Qué hay de nuevo?',
		fr_fr: 'Quoi de neuf ?',
		it_it: 'Cosa c\'è di nuovo?',
	},
};
