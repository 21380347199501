import React from 'react';
import { IonContent, IonModal } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___MdEntrySignModal } from './md-entry-sign.locale';

//	S T Y L E

import './md-entry-sign.scss';

//	T Y P E S

import { type___modal___config } from '../../types/types.modals';

//	S T A T E   -   P R O P S

import { props___MdEntrySignModal } from './md-entry-sign.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class MdEntrySignModal extends React.Component<props___MdEntrySignModal, {}>
{

//#region 																							C O N F I G

	private readonly MdEntrySignModal___modal_config: type___modal___config = {
		breakpoints: [ 1, 0 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidDismiss: () => { this.props.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdEntrySignModal___modal_config }>
				<IonContent forceOverscroll={ false }>
					<section className="md-entry-sign---container">
						
						<br />

						<img src="/assets/logo.squared.svg"/>

						<br />
						<br />
					
						<h4>{ this._LOCALE.translate(locale___MdEntrySignModal.title) }</h4>
						<p>{ this._LOCALE.translate(locale___MdEntrySignModal.description) }</p>

						<br />

						<div className="md-entry-sign---buttons">
							<Link to="/sign" onClick={ this.props.event___onDidDismiss }>
								{ this._LOCALE.translate(locale___MdEntrySignModal.sign_register) }
							</Link>
							<span>{ this._LOCALE.translate(locale___MdEntrySignModal.sign_or_separator) }</span>
							<Link to="/sign" onClick={ this.props.event___onDidDismiss }>
								{ this._LOCALE.translate(locale___MdEntrySignModal.sign_login) }
							</Link>
						</div>

						<br />
						<br />
						<br />

						<h6 onClick={ this.props.event___onDidDismiss }>
							<span>{ this._LOCALE.translate(locale___MdEntrySignModal.sign_close) }</span>
							<span>{ this._LOCALE.translate(locale___MdEntrySignModal.close) }</span>
						</h6>

					</section>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}