/* 
 * 
 * 
 * 
 * 								L I B   A P I   S H A R E D
 * 
 * 
 * 
 */

export const lib_api_shared = {
	content_type: 'application/json',
	headers: {
		default___authorization: 'Authorization',
		default___authorization___bearer: 'Bearer',
		extra___x_locale: 'x-locale',
		extra___x_tracking: 'x-tracking',
		extra___x_coords: 'x-coords',
		extra___x_interests_poi_categories: 'x-interests-poi-categories',
	}
}