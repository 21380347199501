import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbSettingsDatasDeleteMePage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbSettingsDatasDeleteMePage {

	delete___confirmation: boolean,
	delete___is_loading: boolean,

	ionAlert___askPasswordDelete___isOpen: boolean,

	ionToast___deleteAccountError___isVisible: boolean,
	ionToast___deleteAccountSuccess___isVisible: boolean,
	
}

//	S T A T E   D E F A U L T

export const state___SbSettingsDatasDeleteMePage___default: state___SbSettingsDatasDeleteMePage = {

	delete___confirmation: false,
	delete___is_loading: false,

	ionAlert___askPasswordDelete___isOpen: false,

	ionToast___deleteAccountError___isVisible: false,
	ionToast___deleteAccountSuccess___isVisible: false,
	
}