import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPostComponent: type___locale___translations___collection = {
	'timeline___datetime___today': {
		de_de: 'heute um {{1}} Uhr',
		en_us: 'today at {{1}}',
		es_es: 'hoy a las {{1}}',
		fr_fr: 'aujourd\'hui à {{1}}',
		it_it: 'oggi alle {{1}}',
	},
	'timeline___datetime___at': {
		de_de: 'am {{1}} um {{2}} Uhr',
		en_us: 'on {{1}} at {{2}}',
		es_es: 'el {{1}} a las {{2}}',
		fr_fr: 'le {{1}} à {{2}}',
		it_it: 'il {{1}} alle {{2}}',
	},
	'normal___postAction___delete': {
		de_de: 'Beitrag löschen',
		en_us: 'Delete Post',
		es_es: 'Eliminar publicación',
		fr_fr: 'Supprimer le message',
		it_it: 'Elimina Post',
	},
	'normal___postAction___cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},
	'normal___postAction___delete___confirm_title': {
		de_de: 'Aufmerksamkeit',
		en_us: 'Attention',
		es_es: 'Atención',
		fr_fr: 'Attention',
		it_it: 'Attenzione',
	},
	'normal___postAction___delete___confirm_text': {
		de_de: 'Sie sind dabei, diesen Beitrag zu löschen. Die Aktion ist unwiderruflich. Möchten Sie fortfahren?',
		en_us: 'You are about to delete this post, the action is irreversible. Do you want to continue?',
		es_es: 'Estás a punto de eliminar esta publicación, la acción es irreversible ¿Quieres continuar?',
		fr_fr: 'Vous êtes sur le point de supprimer ce post, l\'action est irréversible. Voulez-vous continuer?',
		it_it: 'Stai per eliminare questo post, l\'azione è irreversibile, Vuoi continuare?',
	},
	'normal___postAction___delete___confirm_yes': {
		de_de: 'Ja',
		en_us: 'Yes',
		es_es: 'Sí',
		fr_fr: 'Oui',
		it_it: 'Si',
	},
	'normal___postAction___delete___confirm_cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},
	'normal___postAction___delete___toast_ok': {
		de_de: 'Beitrag gelöscht',
		en_us: 'Post deleted',
		es_es: 'Publicación eliminada',
		fr_fr: 'Message supprimé',
		it_it: 'Post eliminato',
	},
	'normal___postAction___delete___toast_error': {
		de_de: 'Fehler beim Löschen des Beitrags',
		en_us: 'Error deleting post',
		es_es: 'Error al eliminar la publicación',
		fr_fr: 'Erreur lors de la suppression du message',
		it_it: 'Errore nell\'eliminazione del post',
	},
	'normal___datetime_today_at': {
		de_de: 'Heute um 10.10 Uhr',
		en_us: 'Today at 10.10am',
		es_es: 'Hoy a las 10.10',
		fr_fr: 'Aujourd\'hui à 10h10',
		it_it: 'Oggi alle {{1}}',
	},
	'normal___datetime_date_at': {
		de_de: '{{1}} um 10:10 Uhr',
		en_us: '{{1}} at 10:10',
		es_es: '{{1}} a las 10:10',
		fr_fr: '{{1}} à 10h10',
		it_it: '{{1}} alle {{2}}',
	},
	'normal___post_is_deleted': {
		de_de: 'Beitrag gelöscht',
		en_us: 'Post deleted',
		es_es: 'Publicación eliminada',
		fr_fr: 'Message supprimé',
		it_it: 'Post eliminato',
	},
	'view_attachment___tale': {
		de_de: 'Siehe Tale',
		en_us: 'See the Tale',
		es_es: 'Ver la Tale',
		fr_fr: 'Voir le Tale',
		it_it: 'Vedi la Tale',
	},
};