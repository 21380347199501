import React from 'react'

//	S T Y L E

import './com-chat-message.scss'

//	T Y P E S

import { type___message___type } from '../../types/types.messages';

//	S T A T E   -   P R O P S

import { props___ComChatMessageComponent } from './com-chat-message.state';

//	C O M P O N E N T S

import ComChatMessageAttachmentComponent from '../com-chat-message-attachment/com-chat-message-attachment';
import ComTextRichComponent from '../com-text-rich/com-text-rich';

//	C L A S S

export default class ComChatMessageComponent extends React.Component<props___ComChatMessageComponent, {}>
{

//#region 																							D E C L A R A T IO NS

	private doubleclick___counter: number = 0;

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDoubleClick = () => {
		this.doubleclick___counter = this.doubleclick___counter + 1;
		if (this.doubleclick___counter == 2) { this.props.event___onDoubleClick(this.props.message_props); }
		setTimeout(() => { this.doubleclick___counter = 0; }, 1000);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className={ 'com-chat-message---container com-chat-message---' + this.props.message_props['message_type'] as type___message___type }>
				<div className={ (this.props.message_props['message_liked'] && this.props.message_props['message_liked'] === true) ? 'com-chat-message---is-liked' : '' }>
					<ComChatMessageAttachmentComponent message_props={ this.props.message_props }/>
					<div className="com-chat-message---text" onClick={ this.event___onDoubleClick }>
						<ComTextRichComponent text={ this.props.message_props['message_text'] } />
						{(this.props.message_props['message_liked'] && this.props.message_props['message_liked'] === true) ? <>
							<div className="com-chat-message---text---like"><i className="fas fa-heart"></i></div>
						</> : <></>}
					</div>
				</div>
			</div>
		</>;
	}

//#endregion

}