import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbSettingsPrivacyPage: type___locale___translations___collection = {
	'ionToast___privacy_is_submitted___saved': {
		de_de: 'Speichern war erfolgreich',
		en_us: 'Save was successful',
		es_es: 'El guardado fue exitoso',
		fr_fr: 'Enregistrement réussi',
		it_it: 'Il salvataggio è andato a buon fine',
	},
	'ionToast___privacy_is_submitted___error': {
		de_de: 'Fehler beim Speichern',
		en_us: 'Error saving',
		es_es: 'Error al guardar',
		fr_fr: 'Erreur lors de l\'enregistrement',
		it_it: 'Errore nel salvataggio',
	},
	'title': {
		de_de: 'Profil-Privatsphäre',
		en_us: 'Profile Privacy',
		es_es: 'Privacidad del Perfil',
		fr_fr: 'Confidentialité du Profil',
		it_it: 'Privacy del Profilo',
	},
	'user_privacy___profile_public': {
		de_de: 'Öffentliches Profil',
		en_us: 'Public Profile',
		es_es: 'Perfil Público',
		fr_fr: 'Profil Public',
		it_it: 'Profilo Pubblico',
	},
	'user_privacy___profile_public___description': {
		de_de: 'Ein öffentliches Profil ermöglicht es jedem, dir zu folgen und deine Inhalte zu sehen. Deine Inhalte können in Hashtags angezeigt und anderen Nutzern empfohlen werden.',
		en_us: 'A public profile allows anyone to follow you and see the content you post. Your content can be shown in hashtags and recommended to other users.',
		es_es: 'Un perfil público permite que cualquier persona te siga y vea el contenido que publicas. Tu contenido puede aparecer en hashtags y ser recomendado a otros usuarios.',
		fr_fr: 'Un profil public permet à quiconque de vous suivre et de voir le contenu que vous publiez. Vos contenus peuvent apparaître dans les hashtags et être recommandés à d\'autres utilisateurs.',
		it_it: 'Il profilo pubblico permette a chiunque di seguirti e vedere i contenuti che pubblichi, i tuoi contenuti potranno essere visualizzati all\'interno degli hashtag e consigliati ad altri utenti',
	},
	'user_privacy___exclude_from_search': {
		de_de: 'Von der Suche ausschließen',
		en_us: 'Exclude from search',
		es_es: 'Excluir de la búsqueda',
		fr_fr: 'Exclure de la recherche',
		it_it: 'Escludimi dalle ricerche',
	},
	'user_privacy___exclude_from_search___description': {
		de_de: 'Wenn aktiviert, wird dein Profil in den Suchergebnissen für Personen, mit denen du keinen Kontakt hast, nicht angezeigt.',
		en_us: 'If enabled, your profile will not appear in search results to those who are not connected with you.',
		es_es: 'Si está habilitado, tu perfil no aparecerá en los resultados de búsqueda para aquellos que no estén conectados contigo.',
		fr_fr: 'Si activé, votre profil n\'apparaîtra pas dans les résultats de recherche pour ceux qui ne sont pas en contact avec vous.',
		it_it: 'Se attivato, il tuo profilo non apparirà nelle ricerche a coloro che non hanno contatti con te',
	},
	'user_privacy___only_close_friends': {
		de_de: 'Nur vertrauenswürdige Freunde',
		en_us: 'Limit activities to close friends',
		es_es: 'Limitar actividades a amigos cercanos',
		fr_fr: 'Limiter les activités aux amis proches',
		it_it: 'Limita le attività agli amici fidati',
	},
	'user_privacy___only_close_friends___description': {
		de_de: 'Wenn aktiviert, wird dein Profil in den Suchergebnissen für Personen, mit denen du keinen Kontakt hast, nicht angezeigt.',
		en_us: 'If enabled, your profile will not appear in search results to those who are not connected with you.',
		es_es: 'Si está habilitado, tu perfil no aparecerá en los resultados de búsqueda para aquellos que no estén conectados contigo.',
		fr_fr: 'Si activé, votre profil n\'apparaîtra pas dans les résultats de recherche pour ceux qui ne sont pas en contact avec vous.',
		it_it: 'Se attivato, il tuo profilo non apparirà nelle ricerche a coloro che non hanno contatti con te',
	},
	'messages': {
		de_de: 'Nachrichten und Gespräche',
		en_us: 'Messages and conversations',
		es_es: 'Mensajes y conversaciones',
		fr_fr: 'Messages et conversations',
		it_it: 'Messaggi e conversazioni',
	},
	'user_privacy___contactable': {
		de_de: 'Ich kann kontaktiert werden',
		en_us: 'I can be contacted',
		es_es: 'Se me puede contactar',
		fr_fr: 'Je peux être contacté',
		it_it: 'Posso essere contattato',
	},
	'user_privacy___contactable___description': {
		de_de: 'Deaktivieren, um nicht von Personen, mit denen du keinen Kontakt hast, kontaktiert zu werden.',
		en_us: 'Disable to not be contacted by people with whom you have no follow contact.',
		es_es: 'Desactiva para que no puedan contactarte personas con las que no tienes un contacto de seguimiento.',
		fr_fr: 'Désactivez pour ne pas être contacté par des personnes avec qui vous n\'avez pas de contact de suivi.',
		it_it: 'Disabilita per non poter essere contattato dalle persone con cui non hai un contatto di follow',
	}
};