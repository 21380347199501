import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";
import { type___geo___lngLat } from "../../types/types.types";

//	P R O P S

export interface props___MdTalesCreateModal extends interface___modal, interface___is_logged {

}

//	S T A T E

export interface state___MdTalesCreateModal {

	camera_env___is_available: boolean,

	tale___media: any | null,
	tale___position___coords: type___geo___lngLat | null,
	tale___position___poi: any | null,
	tale___text: string,

	tale___is_submitting: boolean,

	ionAlert___askForClose___isOpen: boolean,
	ionModal___MdMediaEditorModal___isOpen: boolean,
	ionModal___MdPositionSelectorModal___isOpen: boolean,
	ionModal___MdPoiSelectModal___isOpen: boolean,
	ionToast___error___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdTalesCreateModal___default: state___MdTalesCreateModal = {

	camera_env___is_available: true,

	tale___media: null,
	tale___position___coords: null,
	tale___position___poi: null,
	tale___text: '',

	tale___is_submitting: false,

	ionAlert___askForClose___isOpen: false,
	ionModal___MdMediaEditorModal___isOpen: false,
	ionModal___MdPositionSelectorModal___isOpen: false,
	ionModal___MdPoiSelectModal___isOpen: false,
	ionToast___error___isOpen: false,

}