import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbPoiCategoryViewPage } from './sb-poi-category-view.locale';

//	S T Y L E

import './sb-poi-category-view.scss';

//	S T A T E   -   P R O P S

import { props___SbPoiCategoryViewPage, state___SbPoiCategoryViewPage, state___SbPoiCategoryViewPage___default } from './sb-poi-category-view.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComGalleryPlaceholderComponent from '../../../components/com-gallery-placeholder/com-gallery-placeholder';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComPoiEmergencyComponent from '../../../components/com-poi-emergency/com-poi-emergency';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';

//	C L A S S

class SbPoiCategoryViewPage extends React.Component<props___SbPoiCategoryViewPage, state___SbPoiCategoryViewPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiCategoryViewPage,
	) {
		super(props);
		this.state = state___SbPoiCategoryViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi_category = async () => {
		const ___temp___poi_category___props: type___api___response = await this._API.poi_categories___read___single(this.state.poi_category___id);
		if (!___temp___poi_category___props || ___temp___poi_category___props.response !== 'success' || ___temp___poi_category___props.data === null) { console.log('404'); }
		this.setState({
			poi_category___props: ___temp___poi_category___props.data,
			poi_category___props___is_loading: false,
		});
	}

	private read_props___poi_category___poi_collection = async () => {
		const ___temp___poi_collection___props: type___api___response = await this._API.poi___read___multi('category', this.state.poi_category___id, this.state.poi_category___poi_collection.length);
		this.setState((prevState) => ({
			poi_category___poi_collection: [...prevState.poi_category___poi_collection, ...___temp___poi_collection___props.data],
			poi_category___poi_collection___is_loading: false,
			poi_category___poi_collection___is_complete: (___temp___poi_collection___props.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			poi_category___poi_collection: [],
			poi_category___poi_collection___is_loading: true,
			poi_category___poi_collection___is_complete: false,
		}, async () => {
			await this.read_props___poi_category();
			___e.detail.complete();
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			poi_category___poi_collection___is_loading: true
		}, async () => {
			await this.read_props___poi_category___poi_collection();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_category_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_category_id) {
			this.setState({
				poi_category___id: ___poi_category_id,
			}, async () => {
				await Promise.all([
					this.read_props___poi_category(),
					this.read_props___poi_category___poi_collection(),
				]);
			});
		}
	}

	ionViewWillLeave() : void {
		this.setState({
			...state___SbPoiCategoryViewPage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefresherComponent/>
					</IonRefresher>

					{(this.state.poi_category___props___is_loading === true) ? <>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</> : <>
						{(this.state.poi_category___props !== null) ? <>
							<div className="sb-page---container sb-poi-category-view---container">

								{(this.state.poi_category___props['poi_category_emergency'] === true) ? <>
									<ComPoiEmergencyComponent/>
									<ComListSeparatorComponent />
								</> : <></>}

								<section className="sb-poi-category-view---category-image">
									<ComGalleryPlaceholderComponent placeholder_path={ '/assets/markers/poi/' + this.state.poi_category___props['poi_category_icon'] + '.svg' }/>
								</section>

								<section className="sb-poi-category-view---category-box">
									{(this.state.poi_category___props['poi_category_parent']) ? <>
										<p>{ this.state.poi_category___props['poi_category_parent']['poi_category_name'] }</p>
									</> : <>
										<p>{ this._LOCALE.translate(locale___SbPoiCategoryViewPage.category_is_loading) }</p>
									</>}
									<h3>{ this.state.poi_category___props['poi_category_name'] }</h3>
								</section>

								<section className="sb-poi-category-view---category-scroller">
									{this.state.poi_category___poi_collection.map((___p: any, ___p_i: number) => {
										return <>
											<Link key={ ___p_i } to={ '/view/poi/' + ___p['poi_id'] }>
												<ComPoiComponent poi_props={ ___p} size="normal" />
											</Link>
											<ComListSeparatorComponent />
										</>;
									})}
								</section>

								{(this.state.poi_category___poi_collection___is_complete === true) ? <>
									<ComListEmptyComponent list_items_quantity={ this.state.poi_category___poi_collection.length }/>
								</> : <>
									<ComInfiniteScrollerComponent is_loading={ this.state.poi_category___poi_collection___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
								</>}

							</div>
						</> : <>
							<Com404Component/>
						</>}
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiCategoryViewPage);