import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbPoiViewTalesPage: type___locale___translations___collection = {
	'switch___more_popular': {
		de_de: 'Am beliebtesten',
		en_us: 'Most Popular',
		es_es: 'Más populares',
		fr_fr: 'Les plus populaires',
		it_it: 'Più Popolari',
	},
	'switch___newest': {
		de_de: 'Neueste',
		en_us: 'Newest',
		es_es: 'Más recientes',
		fr_fr: 'Les plus récents',
		it_it: 'Più Recenti',
	}
};