import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbSettingsProfilePage: type___locale___translations___collection = {
	'ionToast___saved': {
		de_de: 'Speichern war erfolgreich',
		en_us: 'Save was successful',
		es_es: 'El guardado fue exitoso',
		fr_fr: 'Enregistrement réussi',
		it_it: 'Il salvataggio è andato a buon fine',
	},
	'ionToast___save_error': {
		de_de: 'Fehler beim Speichern',
		en_us: 'Error saving',
		es_es: 'Error al guardar',
		fr_fr: 'Erreur lors de l\'enregistrement',
		it_it: 'Errore nel salvataggio',
	},
	'title': {
		de_de: 'Profil bearbeiten',
		en_us: 'Edit your profile',
		es_es: 'Editar tu perfil',
		fr_fr: 'Modifier votre profil',
		it_it: 'Modifica il tuo profilo',
	},
	'info___username___popover': {
		de_de: 'Derzeit erlauben wir keine Änderung des Benutzernamens. Wenn Sie ihn ändern möchten oder einen Fehler gemacht haben, kontaktieren Sie uns bitte',
		en_us: 'We currently do not allow username changes. If you wish to change it or made an error, please contact us',
		es_es: 'Actualmente no permitimos cambios de nombre de usuario. Si deseas cambiarlo o cometiste un error, contáctanos',
		fr_fr: 'Nous ne permettons pas actuellement le changement de nom d\'utilisateur. Si vous souhaitez le changer ou avez fait une erreur, veuillez nous contacter',
		it_it: 'Attualmente non permettiamo il cambio di username, se desideri cambiarlo o hai commesso un errore contattaci',
	},
	'info___username___popover___support': {
		de_de: 'zur Support-Seite gehen',
		en_us: 'go to support page',
		es_es: 'ir a la página de soporte',
		fr_fr: 'aller à la page d\'assistance',
		it_it: 'vai alla pagina dell\'assistenza',
	},
	'field___name': {
		de_de: 'Angezeigter Name',
		en_us: 'display name',
		es_es: 'nombre mostrado',
		fr_fr: 'nom affiché',
		it_it: 'nome visualizzato',
	},
	'field___contacts': {
		de_de: 'Kontakte',
		en_us: 'contacts',
		es_es: 'contactos',
		fr_fr: 'contacts',
		it_it: 'contatti',
	},
	'button___update': {
		de_de: 'Informationen aktualisieren',
		en_us: 'Update information',
		es_es: 'Actualizar información',
		fr_fr: 'Mettre à jour les informations',
		it_it: 'Aggiorna informazioni',
	}
};