import { interface___is_logged } from "../../interfaces/interface.logged";
import { type___post___attachment_type } from "../../types/types.types";

//	P R O P S

export interface props___ComInputTagsComponent extends interface___is_logged {

	text: string,

	event___onTagSelect: (_: string) => void,

}

//	S T A T E

export interface state___ComInputTagsComponent {

	search_param: string,

	usernames___collection: any[]
	usernames___collection___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComInputTagsComponent___default: state___ComInputTagsComponent = {

	search_param: '',

	usernames___collection: [],
	usernames___collection___is_loading: true,

}