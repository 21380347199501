import { type___locale___translations___collection } from "../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___MdSignPasswordChangeModal: type___locale___translations___collection = {
	'ionToast___password_changed': {
		de_de: 'Ihr Passwort wurde erfolgreich geändert',
		en_us: 'Your password has been successfully changed',
		es_es: 'Tu contraseña ha sido cambiada con éxito',
		fr_fr: 'Votre mot de passe a été changé avec succès',
		it_it: 'La tua password è stata cambiata con successo',
	},
	'ionToast___password_old_invalid': {
		de_de: 'Die Passwörter stimmen nicht überein...',
		en_us: 'The passwords do not match...',
		es_es: 'Las contraseñas no coinciden...',
		fr_fr: 'Les mots de passe ne correspondent pas...',
		it_it: 'Le password non combaciano...',
	},
	'ionToast___password_error_generic': {
		de_de: 'Fehler beim Ändern des Passworts',
		en_us: 'Error changing the password',
		es_es: 'Error al cambiar la contraseña',
		fr_fr: 'Erreur lors du changement du mot de passe',
		it_it: 'Errore nel cambiamento della password',
	},
	'title': {
		de_de: 'Passwort zurücksetzen',
		en_us: 'Password Reset',
		es_es: 'Restablecimiento de contraseña',
		fr_fr: 'Réinitialisation du mot de passe',
		it_it: 'Reset della password',
	},
	'description': {
		de_de: 'Sie haben derzeit ein temporäres Passwort. Um Nearby weiter nutzen zu können, müssen Sie ein sicheres Passwort erstellen.',
		en_us: 'You currently have a temporary password. To continue using Nearby, you need to create a secure password.',
		es_es: 'Actualmente tienes una contraseña temporal. Para continuar usando Nearby, debes crear una contraseña segura.',
		fr_fr: 'Vous avez actuellement un mot de passe temporaire. Pour continuer à utiliser Nearby, vous devez créer un mot de passe sécurisé.',
		it_it: 'Al momento hai una password temporanea, per continuare ad utilizzare Nearby devi necessariamente creare una password sicura',
	}
};