import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbSettingsAuthenticationPage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbSettingsAuthenticationPage {

	field___password___old: string, 
	field___password___new___x1: string,
	field___password___new___x2: string,

	validation___number: boolean,
	validation___lowercase: boolean,
	validation___uppercase: boolean,
	validation___symbols: boolean,
	validation___length: boolean,
	validation___match_new_old: boolean,

	ionToast___password_change___active: boolean,
	ionToast___password_change___type: 'success' | 'error_generic' | 'error_new_not_match' | 'error_old_wrong' | null,

}

//	S T A T E   D E F A U L T

export const state___SbSettingsAuthenticationPage___default: state___SbSettingsAuthenticationPage = {

	field___password___old: '', 
	field___password___new___x1: '',
	field___password___new___x2: '',

	validation___number: false,
	validation___lowercase: false,
	validation___uppercase: false,
	validation___symbols: false,
	validation___length: false,
	validation___match_new_old: false,

	ionToast___password_change___active: false,
	ionToast___password_change___type: null,

}