import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComInputTagsComponent: type___locale___translations___collection = {
	'tags___suggestions': {
		de_de: 'tag-vorschläge...',
		en_us: 'tag suggestions...',
		es_es: 'sugerencias de etiquetas...',
		fr_fr: 'suggestions de balises...',
		it_it: 'suggerimenti tag...',
	},
	'tags___nobody': {
		de_de: 'Niemand zum markieren...',
		en_us: 'No one to tag...',
		es_es: 'Nadie a quien etiquetar...',
		fr_fr: 'Personne à taguer...',
		it_it: 'Nessuno da taggare...',
	},
};