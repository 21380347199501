import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonPage, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbSettingsAuthenticationPage } from './sb-settings-authentication.locale';

//	S T Y L E

import './sb-settings-authentication.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	P R O P S   -   S T A T E S

import { props___SbSettingsAuthenticationPage, state___SbSettingsAuthenticationPage, state___SbSettingsAuthenticationPage___default } from './sb-settings-authentication.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComInputPassword from '../../../components/com-input-password/com-input-password';

//	C L A S S

class SbSettingsAuthenticationPage extends React.Component<props___SbSettingsAuthenticationPage, state___SbSettingsAuthenticationPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSettingsAuthenticationPage,
	) {
		super(_PROPS);
		this.state = state___SbSettingsAuthenticationPage___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___password___change = async () => {
		if (this.state.field___password___new___x1 !== this.state.field___password___new___x2) {
			this.setState({
				ionToast___password_change___active: true,
				ionToast___password_change___type: 'error_new_not_match',
			});
		}
		else
		{
			const ___temp___password_change: type___api___response = await this._API.auth___perform___password_change(this.state.field___password___old, this.state.field___password___new___x1);
			if (___temp___password_change && ___temp___password_change.response === 'success')
			{
				this.setState({
					ionToast___password_change___active: true,
					ionToast___password_change___type: 'success',
				});
			}
			else
			{
				this.setState({
					ionToast___password_change___active: true,
					ionToast___password_change___type: 'error_generic',
				});
			}
		}
	}

	private handle___password___write = async () => {
		this.setState({
			validation___number: (this.state.field___password___new___x1.match(/\d+/)) ? true : false,
			validation___lowercase: (this.state.field___password___new___x1.match(/[a-z]+/)) ? true : false,
			validation___uppercase: (this.state.field___password___new___x1.match(/[A-Z]+/)) ? true : false,
			validation___symbols: (this.state.field___password___new___x1.match(/[!@#$%^&*(),.?":{}|<>]+/)) ? true : false,
			validation___length: (this.state.field___password___new___x1.length >= 3 && this.state.field___password___new___x1.length <= 16) ? true : false,
			validation___match_new_old: (this.state.field___password___new___x1 === this.state.field___password___new___x2) ? true : false,
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				{(this.state.ionToast___password_change___active === true && this.state.ionToast___password_change___type !== null) ? <>
					{(() => { switch (this.state.ionToast___password_change___type) {
						case 'success': return <>
							<IonToast position="top" isOpen={ this.state.ionToast___password_change___active } message={ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.ionToast___success) } color="success" duration={ 3000 } onDidDismiss={() => { this.setState({ ionToast___password_change___active: false, ionToast___password_change___type: null }); }}></IonToast>
						</>; break;
						case 'error_generic': return <>
							<IonToast position="top" isOpen={ this.state.ionToast___password_change___active } message={ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.ionToast___error___error_generic) } color="danger" duration={ 3000 } onDidDismiss={() => { this.setState({ ionToast___password_change___active: false, ionToast___password_change___type: null }); }}></IonToast>
						</>; break;
						case 'error_new_not_match': return <>
							<IonToast position="top" isOpen={ this.state.ionToast___password_change___active } message={ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.ionToast___error___error_new_not_match) } color="danger" duration={ 3000 } onDidDismiss={() => { this.setState({ ionToast___password_change___active: false, ionToast___password_change___type: null }); }}></IonToast>
						</>; break;
						case 'error_old_wrong': return <>
							<IonToast position="top" isOpen={ this.state.ionToast___password_change___active } message={ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.ionToast___error___error_old_wrong) } color="danger" duration={ 3000 } onDidDismiss={() => { this.setState({ ionToast___password_change___active: false, ionToast___password_change___type: null }); }}></IonToast>
						</>; break;
					}})()}
				</> : <></>}

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<section className="sb-settings-authentication---container">

						<img src="../assets/ui/icons/icon-password.svg"/>

						<IonListHeader>
							<IonLabel style={{textAlign:'center',padding:0}}>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.title) }</IonLabel>
						</IonListHeader>

						<br />
						<br />

						<ComInputPassword text={ this.state.field___password___old } placeholder={ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.old___placeholder) } event___onInput={(___e: string) => { this.setState({ field___password___old: ___e }) }} />
						<br />
						<br />
						<ComInputPassword text={ this.state.field___password___new___x1 } placeholder={ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.new___placeholder) } event___onInput={(___e: string) => { this.setState({ field___password___new___x1: ___e }, () => { this.handle___password___write(); }); }} />
						<br />
						<ComInputPassword text={ this.state.field___password___new___x2 } placeholder={ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.new_x2___placeholder) } event___onInput={(___e: string) => { this.setState({ field___password___new___x2: ___e }, () => { this.handle___password___write(); }); }} />

						<br />
						<br />

						{(this.state.validation___number === true) ? <>
							<p style={{color:'var(--color---greeny)'}}>
								<i className="fas fa-check"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___number) }</span>
							</p>
						</> : <>
							<p style={{color:'var(--color---reddy)'}}>
								<i className="fas fa-times"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___number) }</span>
							</p>
						</>}

						{(this.state.validation___lowercase === true) ? <>
							<p style={{color:'var(--color---greeny)'}}>
								<i className="fas fa-check"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___lowercase) }</span>
							</p>
						</> : <>
							<p style={{color:'var(--color---reddy)'}}>
								<i className="fas fa-times"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___lowercase) }</span>
							</p>
						</>}

						{(this.state.validation___uppercase === true) ? <>
							<p style={{color:'var(--color---greeny)'}}>
								<i className="fas fa-check"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___uppercase) }</span>
							</p>
						</> : <>
							<p style={{color:'var(--color---reddy)'}}>
								<i className="fas fa-times"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___uppercase) }</span>
							</p>
						</>}
					
						{(this.state.validation___symbols === true) ? <>
							<p style={{color:'var(--color---greeny)'}}>
								<i className="fas fa-check"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___symbols) }</span>
							</p>
						</> : <>
							<p style={{color:'var(--color---reddy)'}}>
								<i className="fas fa-times"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___symbols) }</span>
							</p>
						</>}

						{(this.state.validation___length === true) ? <>
							<p style={{color:'var(--color---greeny)'}}>
								<i className="fas fa-check"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___length) }</span>
							</p>
						</> : <>
							<p style={{color:'var(--color---reddy)'}}>
								<i className="fas fa-times"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___length) }</span>
							</p>
						</>}

						{(this.state.validation___match_new_old === true) ? <>
							<p style={{color:'var(--color---greeny)'}}>
								<i className="fas fa-check"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___match_new_old) }</span>
							</p>
						</> : <>
							<p style={{color:'var(--color---reddy)'}}>
								<i className="fas fa-times"></i>
								<span>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.validation___match_new_old) }</span>
							</p>
						</>}

					</section>

				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						{(this.state.validation___number === true && this.state.validation___lowercase === true && this.state.validation___uppercase === true && this.state.validation___symbols === true && this.state.validation___length === true && this.state.validation___match_new_old === true) ? <>
							<button className="is-element is-button is-color---folly is-full-width" onClick={ this.handle___password___change }>
								<i className="fa-solid fa-check"></i>
								<h6>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.button___save) }</h6>
							</button>
						</> : <>
							<button className="is-element is-button is-color---shadow is-full-width">
								<h6>{ this._LOCALE.translate(locale___SbSettingsAuthenticationPage.button___disabled) }</h6>
							</button>
						</>}
					</section>
				</IonFooter>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsAuthenticationPage);