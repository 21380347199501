import { type___locale___translations___collection } from "../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___MdSignPasswordResetModal: type___locale___translations___collection = {
	'title': {
		de_de: 'Passwort zurücksetzen',
		en_us: 'Password Reset',
		es_es: 'Restablecimiento de contraseña',
		fr_fr: 'Réinitialisation du mot de passe',
		it_it: 'Reset della password',
	},
	'description_1': {
		de_de: 'Um das Passwort zurückzusetzen, geben Sie Ihre E-Mail-Adresse ein und folgen Sie den Anweisungen in der E-Mail',
		en_us: 'To proceed with the password reset, enter your email and follow the instructions sent by email',
		es_es: 'Para proceder con el restablecimiento de la contraseña, ingresa tu correo electrónico y sigue las instrucciones enviadas por correo',
		fr_fr: 'Pour procéder à la réinitialisation du mot de passe, entrez votre e-mail et suivez les instructions envoyées par e-mail',
		it_it: 'Per procedere al reset della password inserisci la tua email e segui le istruzioni via mail',
	},
	'description_2': {
		de_de: 'Normalerweise kommt die Rücksetz-Mail sofort an, aber es kann bis zu 10 Minuten dauern',
		en_us: 'The reset email typically arrives immediately, but it may take up to 10 minutes',
		es_es: 'Normalmente, el correo de restablecimiento llega de inmediato, pero puede tardar hasta 10 minutos',
		fr_fr: 'L\'e-mail de réinitialisation arrive généralement immédiatement, mais cela peut prendre jusqu\'à 10 minutes',
		it_it: 'Normalmente la mail di reset arriva subito ma può essere necessario attendere fino a 10 minuti',
	},
	'email_placeholder': {
		de_de: 'deine@email.de',
		en_us: 'youremail@example.com',
		es_es: 'tucorreo@ejemplo.com',
		fr_fr: 'votre@email.com',
		it_it: 'latua@email.com',
	},
	'button_recover': {
		de_de: 'Passwort wiederherstellen',
		en_us: 'Recover Password',
		es_es: 'Recuperar Contraseña',
		fr_fr: 'Récupérer le mot de passe',
		it_it: 'Recupera Password',
	},
	'toast___success': {
		de_de: 'Wir haben Anweisungen zum Zurücksetzen Ihres Passworts an Ihre E-Mail gesendet',
		en_us: 'We have sent instructions to reset your password to your email',
		es_es: 'Hemos enviado instrucciones para restablecer tu contraseña a tu correo electrónico',
		fr_fr: 'Nous avons envoyé des instructions pour réinitialiser votre mot de passe à votre e-mail',
		it_it: 'Abbiamo inviato le istruzioni per resettare la tua password alla tua email',
	},
	'toast___error': {
		de_de: 'Fehler beim Zurücksetzen des Passworts, sind Sie sicher, dass Sie die richtige E-Mail-Adresse eingegeben haben?',
		en_us: 'Error resetting password, are you sure you entered the correct email?',
		es_es: 'Error al restablecer la contraseña, ¿estás seguro de haber ingresado el correo correcto?',
		fr_fr: 'Erreur lors de la réinitialisation du mot de passe, êtes-vous sûr d\'avoir saisi le bon e-mail ?',
		it_it: 'Errore nella reimpostazione della password, sei sicuro di aver inserito la mail corretta?',
	}
};