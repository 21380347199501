import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComUserPrivateTopBarComponent: type___locale___translations___collection = {
	'profile_is_private': {
		de_de: 'Dein Profil ist privat',
		en_us: 'Your profile is private',
		es_es: 'Tu perfil es privado',
		fr_fr: 'Votre profil est privé',
		it_it: 'Il tuo profilo è privato'
	},
};