import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdTalesCreateModal: type___locale___translations___collection = {
'ionAlert___askForClose___title': {
		de_de: 'Achtung',
		en_us: 'Attention',
		es_es: 'Atención',
		fr_fr: 'Attention',
		it_it: 'Attenzione',
	},
	'ionAlert___askForClose___message': {
		de_de: 'Möchten Sie die Änderungen an dieser Tale verwerfen?',
		en_us: 'Do you want to discard changes to this Tale?',
		es_es: '¿Quieres descartar los cambios de esta Tale?',
		fr_fr: 'Voulez-vous supprimer les modifications de cette Tale ?',
		it_it: 'Vuoi eliminare le modifiche a questa Tale?',
	},
	'ionToast___error_uploading': {
		de_de: 'Fehler beim Hochladen der Tale, bitte versuchen Sie es erneut...',
		en_us: 'Error uploading the Tale, please try again...',
		es_es: 'Error al cargar la Tale, intenta de nuevo...',
		fr_fr: 'Erreur lors du téléchargement de la Tale, veuillez réessayer...',
		it_it: 'Errore nella pubblicazione della tale, riprova...',
	},
	'media___from_camera': {
		de_de: 'Foto aufnehmen',
		en_us: 'Take Photo',
		es_es: 'Tomar Foto',
		fr_fr: 'Prendre une photo',
		it_it: 'Scatta Foto',
	},
	'media___from_gallery': {
		de_de: 'Galerie',
		en_us: 'Gallery',
		es_es: 'Galería',
		fr_fr: 'Galerie',
		it_it: 'Galleria',
	},
	'cannot_determine_position': {
		de_de: 'Standort kann nicht ermittelt werden...',
		en_us: 'Unable to determine your location...',
		es_es: 'No se puede determinar tu ubicación...',
		fr_fr: 'Impossible de déterminer votre position...',
		it_it: 'Impossibile identificare la tua posizione...',
	},
	'button___change_poi': {
		de_de: 'POI ändern',
		en_us: 'Change linked POI',
		es_es: 'Cambiar POI vinculado',
		fr_fr: 'Changer le POI associé',
		it_it: 'Cambia Poi abbinato',
	},
	'button___add_poi': {
		de_de: 'POI hinzufügen',
		en_us: 'Link to a POI',
		es_es: 'Vincular a un POI',
		fr_fr: 'Associer à un POI',
		it_it: 'Abbina ad un Poi',
	},
	'button___share': {
		de_de: 'Veröffentlichen',
		en_us: 'Publish',
		es_es: 'Publicar',
		fr_fr: 'Publier',
		it_it: 'Pubblica',
	}
};