//	L I B S

import { lib_names } from '../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../types/types.api-response';
import { type___user___interests } from '../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from './service-api';

//	E X P O R T

export class service_InterestsService
{

//#region 																							D E C L A R A T I O N S
			
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							G E T   A L L

	public get_interests = async () : Promise<type___user___interests> => {
	
		const ___interests___event___string: string | null = localStorage.getItem(lib_names.localStorage.user___interests___events) ?? null;
		let ___interests___event___array: string[] = (___interests___event___string && ___interests___event___string.length > 0) ? JSON.parse(___interests___event___string) : [];

		const ___interests___poi___string: string | null = localStorage.getItem(lib_names.localStorage.user___interests___poi) ?? null;
		let ___interests___poi___array: string[] = (___interests___poi___string && ___interests___poi___string.length > 0) ? JSON.parse(___interests___poi___string) : [];

		const ___temp___user_interests___from_server: type___api___response = await this._API.users_interests___read_all();
		if (___temp___user_interests___from_server && ___temp___user_interests___from_server.response === 'success' && ___temp___user_interests___from_server.data !== null) {
			___interests___event___array = ___temp___user_interests___from_server['data']['user_interests___events'];
			___interests___poi___array = ___temp___user_interests___from_server['data']['user_interests___poi'];
		}

		return {
			events: (___interests___event___array) ? ___interests___event___array : [],
			poi: (___interests___poi___array) ? ___interests___poi___array : [],
			exists: ((___interests___event___array && ___interests___event___array.length > 0) || (___interests___poi___array && ___interests___poi___array.length > 0)) ? true : false,
		} as type___user___interests;

	}

//#endregion

//#region 																							S E T   E V E N T S

	public set_interests___events = async (___ids_collection: string[], ___is_logged: boolean = false) : Promise<void> => {
		if (___is_logged === true) { await this._API.users_interests___set___events(___ids_collection); }
		const ___ids_collection___serialized: string = (___ids_collection.length > 0) ? JSON.stringify(___ids_collection as string[]) : '[]';
		localStorage.setItem(lib_names.localStorage.user___interests___events, ___ids_collection___serialized);
		return;
	}

//#endregion

//#region 																							S E T   P O I

	public set_interests___poi = async (___ids_collection: string[], ___is_logged: boolean = false) : Promise<void> => {
		if (___is_logged === true) { await this._API.users_interests___set___poi(___ids_collection); }
		const ___ids_collection___serialized: string = (___ids_collection.length > 0) ? JSON.stringify(___ids_collection as string[]) : '[]';
		localStorage.setItem(lib_names.localStorage.user___interests___poi, ___ids_collection___serialized);
		return;
	}

//#endregion

}