import React from 'react'
import { IonCard, IonItem, IonLabel } from '@ionic/react';

//	S T Y L E

import './com-notification.scss'

//	L I B S

import { lib_notifications_shared } from '../../libs/lib.notifications.shared';

//	S T A T E S   -   P R O P S

import { props___ComNotificationComponent, state___ComNotificationComponent, state___ComNotificationComponent___default } from './com-notification.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C L A S S

export default class ComNotificationComponent extends React.Component<props___ComNotificationComponent, state___ComNotificationComponent>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComNotificationComponent,
	) {
		super(props);
		this.state = state___ComNotificationComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___notification___read = async () => {
		const ___notification_id: string = this.props.notification___props['notification_id'];
		await this._API.notifications___mark_as_read('single', ___notification_id);
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		const ___notification___route: string = lib_notifications_shared[this.props.notification___props['notification_type']]['route'] + ((lib_notifications_shared[this.props.notification___props['notification_type']]['route_has_param'] === true) ? this.props.notification___props['notification_target'] : '');
		this.setState({
			notification___route: ___notification___route,
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard className={ 'com-notification-component---container' + ((this.props.notification___props['notification_viewed'] && this.props.notification___props['notification_viewed'] === true) ? ' com-notification-component---viewed' : ' ') } onClick={ this.handle___notification___read }>
				<IonItem className="is-ion-item-button" button={ true } lines="none" routerLink={ this.state.notification___route } routerDirection="forward">
					{(lib_notifications_shared[this.props.notification___props['notification_type']]['icon'] && lib_notifications_shared[this.props.notification___props['notification_type']]['icon'] !== '') ? <>
						<img src={ '/assets/ui/icons/' + lib_notifications_shared[this.props.notification___props['notification_type']]['icon'] + '.svg' } />
					</> : <>
						<img src="/assets/ui/icons/icon-notification.svg"/>
					</>}
					<IonLabel>
						<span>{ this.props.notification___props['notification_text'] }</span>
						<p>{ this.funcs___datetime.datetime___get___age(this.props.notification___props['notification_datetime']) }</p>
					</IonLabel>
				</IonItem>
			</IonCard>
		</>;
	}

//#endregion

}