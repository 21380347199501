import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComInfiniteScrollerComponent: type___locale___translations___collection = {
	'load_more': {
		de_de: 'mehr laden...',
		en_us: 'load more...',
		es_es: 'carga más...',
		fr_fr: 'charger plus...',
		it_it: 'carica altro...',
	},
};