import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdUserSearchModal: type___locale___translations___collection = {
	'title': {
		de_de: 'Benutzer suchen',
		en_us: 'Search User',
		es_es: 'Buscar usuario',
		fr_fr: 'Rechercher un utilisateur',
		it_it: 'Cerca utente',
	},
	'fiels___search___placeholder': {
		de_de: 'Benutzer suchen...',
		en_us: 'Search user...',
		es_es: 'Buscar usuario...',
		fr_fr: 'Rechercher un utilisateur...',
		it_it: 'Cerca utente...',
	}
};