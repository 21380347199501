import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComInputUsername: type___locale___translations___collection = {
	'placeholder': {
		de_de: 'nutzername',
		en_us: 'username',
		es_es: 'nombre de usuario',
		fr_fr: 'nom d\'utilisateur',
		it_it: 'username',
	},
	'info': {
		de_de: 'Benutzernamen dürfen nur Buchstaben, Zahlen und Unterstriche enthalten, andere Symbole, Leerzeichen oder Emojis sind nicht erlaubt und die Länge muss zwischen 3 und 16 Zeichen liegen. Groß- und Kleinschreibung spielt keine Rolle.',
		en_us: 'Usernames can only contain letters, numbers, and underscores, no other symbols, spaces, or emojis are allowed, and the length must be between 3 and 16 characters. Case sensitivity does not matter.',
		es_es: 'Los nombres de usuario solo pueden contener letras, números y guiones bajos, no se permiten otros símbolos, espacios en blanco o emojis, y la longitud debe estar entre 3 y 16 caracteres. Las mayúsculas y minúsculas no importan.',
		fr_fr: 'Les noms d\'utilisateur ne peuvent contenir que des lettres, des chiffres et des tirets bas, aucun autre symbole, espace ou émoji n\'est autorisé, et la longueur doit être comprise entre 3 et 16 caractères. La casse n\'a pas d\'importance.',
		it_it: 'Gli username devono contenere solo lettere, numeri e trattini bassi, non sono ammessi altri simboli, spazi bianchi o emoji e devono avere una lunghezza compresa tra 3 e 16 caratteri. Maiuscole e minuscole non contano.'
	}
};