import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { PushNotifications } from '@capacitor/push-notifications';
import { Link } from 'react-router-dom';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbChatsWithPage } from './sb-chats-with.locale';

//	S T Y L E

import './sb-chats-with.scss';

//	S T A T E   -   P R O P S

import { props___SbChatsWithPage, state___SbChatsWithPage, state___SbChatsWithPage___default } from './sb-chats-with.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___message___like_action } from '../../../types/types.messages';
import { type___notifications___type } from '../../../types/types.notifications';

//	F U N C S

import { funcs_platform } from '../../../funcs/funcs.platform';
import { funcs_text } from '../../../funcs/funcs.text';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComChatMessageComponent from '../../../components/com-chat-message/com-chat-message';
import ComChatMessageViewedComponent from '../../../components/com-chat-message-viewed/com-chat-message-viewed';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComSpinnerTagComponent from '../../../components/com-spinner-tag/com-spinner-tag';
import ComUserVerifiedComponent from '../../../components/com-user-verified/com-user-verified';

//	C L A S S

class SbChatsWithPage extends React.Component<props___SbChatsWithPage, state___SbChatsWithPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___platform: funcs_platform = new funcs_platform();
	private readonly funcs___text: funcs_text = new funcs_text();

	private reference___message_container: React.RefObject<any|null>;
	private reference___message_receiver___eventListener: any | null = null;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbChatsWithPage,
	) {
		super(props);
		this.state = state___SbChatsWithPage___default;
		this.reference___message_container = React.createRef();
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user = async () => {
		const ___temp___user_props: type___api___response = await this._API.users___read___single('username', this.state.user___props___username);
		this.setState({
			user___props: ___temp___user_props.data,
			user___props___is_loading: false,
		}, async () => {
			await this.read_props___chats_messages();
		});
	}

	private read_props___chats_messages = async () => {
		const ___temp___user_props: type___api___response = await this._API.chats_messages___read___multi(this.state.user___props['user_id'], this.state.chat_messages___list.length);
		this.setState((prevState) => ({
			chat_messages___list: [...___temp___user_props.data, ...prevState.chat_messages___list],
			chat_messages___list___is_loading: false,
			chat_messages___list___is_complete: (___temp___user_props.response === 'no-data') ? true : false,
		}), () => {
			this.handle___messages___mark_as_read();
			if (this.state.chat_messages___list___is_empty === true) {
				this.handle___message_container___scrollToBottom();
				this.setState({
					chat_messages___list___is_empty: false
				});
			}
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___messages___load_more = () => {
		this.setState({
			chat_messages___list___is_loading: true
		}, async () => {
			await this.read_props___chats_messages();
		});
	}

	private handle___messages___mark_as_read = async () => {
		await this._API.chats_messages___mark_as___read(this.state.user___props['user_id']);
	}

	private handle___messages___receiverListener = () => {
		if (this.funcs___platform.is_native() === true) {
			this.reference___message_receiver___eventListener = PushNotifications.addListener('pushNotificationReceived', (___notification: any) => {			
				const ___notification_payload: any = ___notification.data;
				const ___notification_payload___type: type___notifications___type = ___notification_payload['type'];
				const ___notification_payload___target: string = ___notification_payload['message_to'].toString().toLowerCase();
				const ___notification_payload___text: string = ___notification_payload['message_text'];
				if (___notification_payload___type === 'message' && ___notification_payload___target === this.state.user___props['user_username'].toString().toLowerCase()) {
					const ___temp___message_received: any = { message_sent_datetime: new Date(), message_from: this.props.is_logged___props['user_id'], message_text: ___notification_payload___text, message_type: 'received' };
					const ___temp___message_received___conversations: any[] = [...this.state.chat_messages___list, ___temp___message_received];
					this.setState({
						chat_messages___list: ___temp___message_received___conversations,
					}, () => {
						this.handle___message_container___scrollToBottom();
					});
				}
			});
		} else {
			console.log('Web Notifications Disabled');
		}
	}

	private handle___messages___receiverListener___remove = () => {
		if (this.funcs___platform.is_native() === true) {
			if (this.reference___message_receiver___eventListener !== null) {
				this.reference___message_receiver___eventListener.remove();
			}
		} else {
			console.log('Web Notifications Disabled');
		}
	}

	private handle___message___is_sent = () => {
		const ___temp___message_sent___text: string = this.state.field___message_text;
		const ___temp___message_sent: any = { message_sent_datetime: new Date(), message_from: this.props.is_logged___props['user_id'], message_text: ___temp___message_sent___text, message_type: 'sent' };
		const ___temp___message_sent___conversations: any[] = [...this.state.chat_messages___list, ___temp___message_sent];
		this.handle___message_container___scrollToBottom();
		this.setState({
			chat_messages___list: ___temp___message_sent___conversations,
			field___message_text: '',
		}, () => {
			document.getElementById('field---message-body-text')?.focus();
			(async () => {
				const ___temp___message_sent: type___api___response = await this._API.chats_messages___send(this.state.user___props['user_id'], ___temp___message_sent___text, null);
				
				
				
				//	check message as sent



			})()
		});
	}

	private handle___message_container___scrollToBottom = () => {
		if (this.reference___message_container.current) {
			this.reference___message_container.current.scrollToBottom(1);
		}
	}

	private handle___message___like___toggle = async (___message: any) => {
		const ___message___liked___action: type___message___like_action = (___message['message_liked'] === true) ? 'unlike' : 'like';
		const ___message___liked: type___api___response = await this._API.chats_messages___like___toggle(___message['message_id'], ___message___liked___action);
		if (___message___liked.response === 'success') {
			const ___message___collection: any[] = this.state.chat_messages___list.map((___m: any) => { if (___message['message_id'] === ___m['message_id']) { return { ...___m, 'message_liked': !___message['message_liked'] }; } return ___m; });
			this.setState({
				chat_messages___list: ___message___collection,
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		this.setState({
			...state___SbChatsWithPage___default
		}, () => {
			const ___user___username: string | null = this._ROUTER.get_id(this.props);
			if (___user___username) {
				this.setState({
					user___props___username: ___user___username,
				}, async () => {
					this.handle___messages___receiverListener();
					await this.read_props___user();
				});
			}
		});
	}

	ionViewWillLeave() : void {
		this.handle___messages___receiverListener___remove();
		this.setState({
			...state___SbChatsWithPage___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/chats/list" text={ this._LOCALE.translate(locale___App.ionBackButton___text) } />
						</IonButtons>
					</IonToolbar>
					<section className="sb-chat-conversation---header-user">
						{(this.state.user___props___is_loading === true) ? <>
							<ComSpinnerTagComponent />
						</> : <>
							<Link to={ '/view/user/' + this.state.user___props['user_username'] }>
								<IonCard>
									<img src={ this.state.user___props['user_image_profile'] }/>
									<section>
										<h5>
											<span>{ this.state.user___props['user_username'] }</span>
											{(this.state.user___props['user_profile_verified'] && this.state.user___props['user_profile_verified'] === true) ? <>
												<ComUserVerifiedComponent popup={ false } />
											</> : <></>}
										</h5>
										<p>
											<span>{ this._LOCALE.translate(locale___SbChatsWithPage.go_to_profile) }</span>
											<i className="fas fa-chevron-right"></i>
										</p>
									</section>
									<i className="fas fa-chevron-right"></i>
								</IonCard>
							</Link>
						</>}
					</section>
				</IonHeader>

				<IonContent forceOverscroll={ false } ref={ this.reference___message_container }>
					<section className="sb-chat-conversation---container">

						<br />
						<br />

						{(this.state.chat_messages___list___is_complete === true) ? <>
							<ComListEmptyComponent list_items_quantity={ this.state.chat_messages___list.length }/>
						</> : <>
							<ComInfiniteScrollerComponent is_loading={ this.state.chat_messages___list___is_loading } event___scrollTriggered={ this.handle___messages___load_more } />
						</>}

						{this.state.chat_messages___list.map((___c_m: any, ___c_m___idx: number) => {
							return <>
								<ComChatMessageComponent key={ ___c_m___idx } message_props={ ___c_m } event___onDoubleClick={ this.handle___message___like___toggle }/>
								<section style={{display:'block',height:'10pt'}}></section>
								{(___c_m___idx >= this.state.chat_messages___list.length) ? <>
									{(___c_m['message_read'] && ___c_m['message_read'] === true) ? <>
										<ComChatMessageViewedComponent message_props={ ___c_m }/>
									</> : <></>}
								</> : <></>}
							</>;
						})}

					</section>
				</IonContent>

				{(this.state.user___props !== null) ? <>
					<IonFooter>
						<section className="container---footer-section---text-input">
							<textarea id="field---message-body-text" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___SbChatsWithPage.field___write_message) } value={ this.state.field___message_text } onInput={(___e: any) => { this.setState({ field___message_text: this.funcs___text.text_input___process(___e.target.value) }); }} onFocus={() => { this.handle___message_container___scrollToBottom(); }}></textarea>
							{(this.state.field___message_text.length > 0) ? <>
								<i className="fa-solid fa-arrow-up" onClick={() => { this.handle___message___is_sent(); }}></i>
							</> : <>
								<i className="fa-solid fa-arrow-up" style={{opacity:'0.5',filter:'grayscale(1)'}}></i>
							</>}
						</section>
					</IonFooter>
				</> : <></>}

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbChatsWithPage);