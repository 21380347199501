import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbInfoAppPage: type___locale___translations___collection = {
	'version_and_build': {
		de_de: 'version {{1}} - build {{2}}',
		en_us: 'version {{1}} - build {{2}}',
		es_es: 'versión {{1}} - build {{2}}',
		fr_fr: 'version {{1}} - build {{2}}',
		it_it: 'versione {{1}} - build {{2}}',
	},
	'about___changelog': {
		de_de: 'Änderungsprotokoll anzeigen',
		en_us: 'View changelog',
		es_es: 'Ver registro de cambios',
		fr_fr: 'Afficher le journal des modifications',
		it_it: 'Visualizza changelog',
	},
};