import React from 'react';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonModal, IonToolbar } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___MdEntryInterestsModal } from './md-entry-interests.locale';

//	S T Y L E

import './md-entry-interests.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';
import { type___user___interests } from '../../types/types.types';

//	S T A T E   -   P R O P S

import { props___MdEntryInterestsModal, state___MdEntryInterestsModal, state___MdEntryInterestsModal___default } from './md-entry-interests.state';

//	S E R V I C E S

import { service_InterestsService } from '../../services/service-interests';
import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class MdEntryInterestsModal extends React.Component<props___MdEntryInterestsModal, state___MdEntryInterestsModal>
{

//#region 																							C O N F I G

	private readonly MdEntryInterestsModal___modal_config: type___modal___config = (this.props.isDismissible && this.props.isDismissible === true) ? {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); }
	} : {
		backdropDismiss: false,
		handle: false,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _INTERESTS: service_InterestsService = new service_InterestsService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdEntryInterestsModal,
	) {
		super(props);
		this.state = state___MdEntryInterestsModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user_interests = async () => {
		const ___temp___user_interests: type___user___interests = await this._INTERESTS.get_interests();
		this.setState({
			field___categories_selected___events: ___temp___user_interests['events'],
			field___categories_selected___poi: ___temp___user_interests['poi'],
			field___categories_selected___is_loading: false,
		}, () => {
			if (this.state.field___categories_selected___events && this.state.field___categories_selected___events.length > 0) {
				this.setState({ field___categories_selected___events_open: true });
			}
		});
	}

	private read_props___categories___events = async () => {
		const ___temp___categories_events___collection: type___api___response = await this._API.events_categories___read___multi();
		this.setState({
			categories_events___collection: (___temp___categories_events___collection.response === 'success') ? ___temp___categories_events___collection['data'] : [],
			categories_events___collection___is_loading: false,
		});
	}

	private read_props___categories___poi = async () => {
		const ___temp___categories_poi___collection: type___api___response = await this._API.poi_categories___read___multi(null, true);
		this.setState({
			categories_poi___collection: (___temp___categories_poi___collection.response === 'success') ? ___temp___categories_poi___collection['data'] : [],
			categories_poi___collection___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___category___select___event = (___category_id: string) => {
		let ___category_collection___temp: string[] = this.state.field___categories_selected___events;
		switch (this.state.field___categories_selected___events.includes(___category_id)) {
			case true:
				___category_collection___temp = ___category_collection___temp.filter(___c => ___c !== ___category_id);
			break;
			case false:
				___category_collection___temp.push(___category_id);
			break;
		}
		this.setState({
			field___categories_selected___events: ___category_collection___temp,
		});
	}

	private handle___category___select___poi = (___category: any) => {
		let ___category_collection___temp: string[] = this.state.field___categories_selected___poi;
		switch (this.state.field___categories_selected___poi.includes(___category['poi_category_id'])) {
			case true:
				___category_collection___temp = ___category_collection___temp.filter(___c => ___c !== ___category['poi_category_id']);
				if (___category['poi_category_children'] && ___category['poi_category_children'].length > 0) {
					for (let ___c_c___i = 0; ___c_c___i < ___category['poi_category_children'].length; ___c_c___i++) {
						const ___category_collection___c_c___single___id: string = ___category['poi_category_children'][___c_c___i]['poi_category_id'];
						___category_collection___temp = ___category_collection___temp.filter(___c_c => ___c_c !== ___category_collection___c_c___single___id);
					}
				}
			break;
			case false:
				___category_collection___temp.push(___category['poi_category_id']);
			break;
		}
		this.setState({
			field___categories_selected___poi: ___category_collection___temp,
		});
	}

	private handle___preferences___save = () => {
		this._INTERESTS.set_interests___events(this.state.field___categories_selected___events, this.props.is_logged);
		this._INTERESTS.set_interests___poi(this.state.field___categories_selected___poi, this.props.is_logged);
		this.props.event___onDidDismiss();
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void {
		this.read_props___user_interests();
		this.read_props___categories___events();
		this.read_props___categories___poi();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdEntryInterestsModal___modal_config }>
				
				{(this.props.isDismissible && this.props.isDismissible === true) ? <>
					<IonHeader>
						<IonToolbar>
							<IonButtons slot="end">
								<IonButton onClick={ this.props.event___onDidDismiss }>
									<b>{ this._LOCALE.translate(locale___MdEntryInterestsModal.topBarButton___close) }</b>
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
				</> : <></>}

				<IonContent forceOverscroll={ false }>

					<br />
					<br />

					<section className="md-entry-interests---logo">
						<img src="/assets/logo.horizontal.svg"/>
					</section>

					<br />
					<br />

					<IonListHeader>
						<IonLabel>{ this._LOCALE.translate(locale___MdEntryInterestsModal.title) }</IonLabel>
					</IonListHeader>

					<IonListHeader style={{opacity:'0.5'}}>
						<p style={{fontSize:'10pt'}}>{ this._LOCALE.translate(locale___MdEntryInterestsModal.subtitle) }</p>
					</IonListHeader>

						{(this.state.categories_events___collection___is_loading === true || this.state.categories_poi___collection___is_loading === true || this.state.field___categories_selected___is_loading === true) ? <>
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
						</> : <>
							<section className="md-entry-interests---container">

								<section key={ 938812 }>
									<input type="checkbox" id="md-entry-interests----cb-cat-events" checked={ this.state.field___categories_selected___events_open } onInput={() => { this.setState({ field___categories_selected___events_open: !(this.state.field___categories_selected___events_open) }); }}/>
									<label htmlFor="md-entry-interests----cb-cat-events" style={{borderColor:'var(--color---folly)',backgroundColor:'var(--color---folly)'}}>
										<i className="fas fa-check"></i>
										<i className="far fa-circle"></i>
										<span style={{fontWeight:'bold'}}>{ this._LOCALE.translate(locale___MdEntryInterestsModal.category___events) }</span>
									</label>
									<section>
										{this.state.categories_events___collection.map((___c_e: any, ___c_e___idx: number) => {
											const ___c_e___child___checkbox___id: string = 'md-entry-interests----cb-cat-event-child---' + ___c_e['event_category_id'];
											return <>
												<input key={ ___c_e___idx + 1900 } type="checkbox" id={ ___c_e___child___checkbox___id } value={ ___c_e['event_category_id'] } checked={ this.state.field___categories_selected___events.includes(___c_e['event_category_id']) } onInput={() => { this.handle___category___select___event(___c_e['event_category_id']); }}/>
												<label key={ ___c_e___idx + 1100 } htmlFor={ ___c_e___child___checkbox___id } style={{borderColor:'var(--color---folly)',backgroundColor:'var(--color---folly)'}}>
													<i className="fas fa-check"></i>
													<i className="far fa-circle"></i>
													<span>{ ___c_e['event_category_name'] }</span>
												</label>
											</>;
										})}
										<br />
										<br />
									</section>
								</section>

								{this.state.categories_poi___collection.map((___c_p: any, ___c_p___idx: number) => {
									const ___c_p___checkbox___id: string = 'md-entry-interests----cb-cat-poi---' + ___c_p['poi_category_id'];
									return <>
										<section key={ ___c_p___idx }>
											<input type="checkbox" id={ ___c_p___checkbox___id } value={ ___c_p['poi_category_id'] } checked={ this.state.field___categories_selected___poi.includes(___c_p['poi_category_id']) } onInput={() => { this.handle___category___select___poi(___c_p); }}/>
											<label htmlFor={ ___c_p___checkbox___id } style={{borderColor:___c_p['poi_category_color'],backgroundColor:___c_p['poi_category_color']}}>
												<i className="fas fa-check"></i>
												<i className="far fa-circle"></i>
												<span style={{fontWeight:'bold'}}>{ ___c_p['poi_category_name'] }</span>
											</label>
											<section>
												{___c_p['poi_category_children'].map((___c_p_c: any, ___c_p_c___idx: number) => {
													const ___c_p___child___checkbox___id: string = 'md-entry-interests----cb-cat-poi-child---' + ___c_p_c['poi_category_id'];
													return <>
														<input key={ ___c_p_c___idx + 900 } type="checkbox" id={ ___c_p___child___checkbox___id } value={ ___c_p_c['poi_category_id'] } checked={ this.state.field___categories_selected___poi.includes(___c_p_c['poi_category_id']) } onInput={() => { this.handle___category___select___poi(___c_p_c); }}/>
														<label key={ ___c_p_c___idx + 100 } htmlFor={ ___c_p___child___checkbox___id } style={{borderColor:___c_p_c['poi_category_color'],backgroundColor:___c_p_c['poi_category_color']}}>
															<i className="fas fa-check"></i>
															<i className="far fa-circle"></i>
															<span>{ ___c_p_c['poi_category_name'] }</span>
														</label>
													</>;
												})}
												<br />
												<br />
											</section>
										</section>
									</>;
								})}

							</section>
						</>}

				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						{(this.state.field___categories_selected___events.length > 0 || this.state.field___categories_selected___poi.length > 0) ? <>
							<button className="is-element is-button is-color---success is-full-width" onClick={ this.handle___preferences___save }>
								<i className="fas fa-heart"></i>
								<h4>{ this._LOCALE.translate(locale___MdEntryInterestsModal.button___save) }</h4>
							</button>
						</> : <>
							<button className="is-element is-button is-color---transparent is-full-width" style={{boxShadow:'none'}} onClick={ this.handle___preferences___save }>
								<i className="far fa-frown-open"></i>
								<h4>{ this._LOCALE.translate(locale___MdEntryInterestsModal.button___skip) }</h4>
							</button>
						</>}
					</section>
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}