import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonListHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-discover-hashtag.scss';

//	S T A T E   -   P R O P S

import { props___SbDiscoverHashtagPage, state___SbDiscoverHashtagPage, state___SbDiscoverHashtagPage___default } from './sb-discover-hashtag.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPostComponent from '../../../components/com-post/com-post';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';

//	C L A S S

class SbDiscoverHashtagPage extends React.Component<props___SbDiscoverHashtagPage, state___SbDiscoverHashtagPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbDiscoverHashtagPage,
	) {
		super(props);
		this.state = state___SbDiscoverHashtagPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___hashtag_posts = async () => {
		const ___temp___poi_collection___props: type___api___response = await this._API.posts___read___multi('hashtag', this.state.hashtag___string, this.state.hashtag___posts.length);
		this.setState((prevState) => ({
			hashtag___posts: [...prevState.hashtag___posts, ...___temp___poi_collection___props.data],
			hashtag___posts___is_loading: false,
			hashtag___posts___is_complete: (___temp___poi_collection___props.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			hashtag___posts: []
		}, async () => {
			await this.read_props___hashtag_posts();
			___e.detail.complete();
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			hashtag___posts___is_loading: true
		}, async () => {
			await this.read_props___hashtag_posts();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___hashtag_string: string | null = this._ROUTER.get_id(this.props);
		if (___hashtag_string) {
			this.setState({
				hashtag___string: '#' + ___hashtag_string,
			}, async () => {
				await this.read_props___hashtag_posts();
			});
		}
	}

	ionViewWillLeave() : void {
		this.setState({
			...state___SbDiscoverHashtagPage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefresherComponent/>
					</IonRefresher>

					<IonListHeader>
						<IonLabel>{ this.state.hashtag___string }</IonLabel>
					</IonListHeader>
	
					<div className="sb-page---container sb-discover-hashtag---container">

						<section>
							{this.state.hashtag___posts.map((___hp: any, ___hp___idx: number) => {
								return <>
									<section key={ ___hp___idx }>
										<ComPostComponent post___type="normal" post___props={ ___hp} is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } post___can_route_comments={ true }/>
									</section>
								</>;
							})}
						</section>

						{(this.state.hashtag___posts___is_complete === true) ? <>
							<ComListEmptyComponent list_items_quantity={ this.state.hashtag___posts.length }/>
						</> : <>
							{(this.state.hashtag___posts___is_loading !== true) ? <>
								<ComInfiniteScrollerComponent is_loading={ this.state.hashtag___posts___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
							</> : <></>}
						</>}
					
					</div>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbDiscoverHashtagPage);