import React from 'react';
import {  IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonSegment, IonSegmentButton, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';

//	L O C A L I Z A T I O N

import { locale___SbSignPage } from './sb-sign.locale';

//	S T Y L E

import './sb-sign.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___geo___lngLat } from '../../types/types.types';

//	I N T E R F A C E S

import { props___SbSignPage, state___SbSignPage, state___SbSignPage___default } from './sb-sign.state';

//	F U N C T I O N S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	L I B S

import { lib_errors_shared } from '../../libs/lib.errors.shared';
import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_GeolocationService } from '../../services/service-geolocation';
import { service_RestAuthService } from '../../services/service-auth';
import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComInputUsername from '../../components/com-input-username/com-input-username';
import ComInputPassword from '../../components/com-input-password/com-input-password';
import ComPasswordSafetyComponent from '../../components/com-password-safety/com-password-safety';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	M O D A L S

import MdSignPasswordResetModal from '../../modals/md-sign-password-reset/md-sign-password-reset';

//	C L A S S

class SbSignPage extends React.Component<props___SbSignPage, state___SbSignPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();

	private funcs___datetime: funcs_datetime = new funcs_datetime();

	private readonly mapbox___map___container: React.RefObject<HTMLDivElement> = React.createRef();
	private mapbox___map: mapboxgl.Map;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbSignPage,
	) {
		super(props);
		this.state = state___SbSignPage___default;
	}

//#endregion

//#region 																							P E R F O R M   A U T H

	private perform_auth___login = () => {
		this.setState({
			sign___is_loading: true,
		}, async () => {
			if (this.state.field_login___username.length > 0 || this.state.field_login___password.length > 0) {
				const ___auth_response___server: type___api___response = await this._AUTH.auth___perform___login(this.state.field_login___username, this.state.field_login___password);
				if (___auth_response___server['response'] === 'success') {
					const ___login___token: string = ___auth_response___server['data'];
					localStorage.setItem(lib_names.localStorage.user___logintoken, ___login___token);
					await this.perform___userData___register();
				} else {
					let ___ionToast___signError___text: string = '';
					switch (___auth_response___server.message) {
						case lib_errors_shared.login___username___not_valid: ___ionToast___signError___text = this._LOCALE.translate(locale___SbSignPage.toastError___login___username); break;
						case lib_errors_shared.login___password___not_valid: ___ionToast___signError___text = this._LOCALE.translate(locale___SbSignPage.toastError___login___password); break;
						default: ___ionToast___signError___text = this._LOCALE.translate(locale___SbSignPage.toastError___login___generic); break;
					}
					this.setState({
						ionToast___signError___text: ___ionToast___signError___text,
						ionToast___signError___isVisible: true,
						sign___is_loading: false,
					});
				}
			}
		});
	}

	private perform_auth___register___confirm_age = () => {
		this.setState({
			field_register___born_date___confirmed: new Date(this.state.field_register___born_date)
		});
	}

	private perform_auth___register = () => {
		this.setState({
			sign___is_loading: true,
		}, async () => {
			if (this.state.field_register___email.length > 0 && this.state.field_register___username.length > 0 && this.state.field_register___password.length > 0) {
				const ___auth_response___server: type___api___response = await this._AUTH.auth___perform___register(this.state.field_register___username, this.state.field_register___email, this.state.field_register___password, this.state.field_register___born_date___confirmed);
				if (___auth_response___server['response'] === 'success') {
					const ___register___token: string = ___auth_response___server['data'];
					localStorage.setItem(lib_names.localStorage.user___logintoken, ___register___token);
					await this.perform___userData___register();
				} else {
					let ___ionToast___registerError___text: string = '';
					switch (___auth_response___server.message) {
						case lib_errors_shared.register___email___not_valid: ___ionToast___registerError___text = this._LOCALE.translate(locale___SbSignPage.toastError___register___email); break;
						case lib_errors_shared.register___username___not_valid: ___ionToast___registerError___text = this._LOCALE.translate(locale___SbSignPage.toastError___register___username); break;
						default: ___ionToast___registerError___text = this._LOCALE.translate(locale___SbSignPage.toastError___register___generic); break;
					}
					this.setState({
						ionToast___signError___text: ___ionToast___registerError___text,
						ionToast___signError___isVisible: true,
						sign___is_loading: false,
					});
				}
			}
		});
	}

	private perform___userData___register = async () => {
		const ___temp___user_props: type___api___response = await this._API.users___read___single('me');
		if (___temp___user_props && ___temp___user_props['response'] === 'success') {
			const ___user_props: any | null = ___temp___user_props['data'];
			this.props.event___registerAuthUserDatas(___user_props);
			document.getElementById('sb-sign----return-to-home').click();
		}
	}

//#endregion

//#region 																							M A P

	private map___prepare = () => {
		
	//	const ___random_coords: type___geo___lngLat = { lng: 39.2050737, lat: 8.9863625 };
		const ___random_coords: type___geo___lngLat = this._GEOLOCATION.geolocation___get_random_coords();

		this.mapbox___map = new mapboxgl.Map({
			accessToken: lib_names.mapboxgl.accessToken,
			container: this.mapbox___map___container.current as HTMLElement,
			style: lib_names.mapboxgl.style_normal,
			center: [___random_coords.lng, ___random_coords.lat],
			zoom: 4,
		});

	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		if (this.state.sign___is_loading___initial === true) {
			this.map___prepare();
			this.setState({
				...state___SbSignPage___default,
			}, () => {
				setTimeout(() => {
					this.setState({
						sign___is_loading___initial: false
					})
				}, 500);
			});
		}
	}

//#endregion

//#region 																							R E N D E R

	render()
	{
		return <>
			<IonPage>

				<IonToast isOpen={ this.state.ionToast___signError___isVisible } message={ this.state.ionToast___signError___text } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ ionToast___signError___isVisible: false }); }}/>
				<IonToast isOpen={ this.state.ionToast___signSuccess___isVisible } message={ this._LOCALE.translate(locale___SbSignPage.toastSuccess___signSuccess___login) } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___signSuccess___isVisible: false }); }}/>

				<MdSignPasswordResetModal isOpen={ this.state.ionModal___MdSignPasswordResetModal___isOpen } event___onDidDismiss={() => { this.setState({ ionModal___MdSignPasswordResetModal___isOpen: false }); }}/>

				<IonHeader className="ion-no-border" collapse="fade" style={{position:'absolute',zIndex:'129213812'}}>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover/map"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<Link to="/discover" id="sb-sign----return-to-home" style={{display:'none'}}></Link>
					
					{(this.state.sign___is_loading___initial === true) ? <>
						<div className="sb-login---loader">
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="standard"/>
						</div>
					</> : <></>}

					<div className="sb-login---container">
						<div className="sb-login---container---opacizer">
							<div className="sb-login---container---opacizer---opacizer"></div>
							<div className="sb-login---container---opacizer---map">
								<div>
									<div ref={ this.mapbox___map___container } />
								</div>
							</div>
						</div>
						<div className="sb-login---container---menu">

							{(this.state.sign___is_loading === true) ? <>
								<div>
									<br />
									<br />
									<br />
									<br />
									<br />
									<ComSpinnerComponent size="small"/>
								</div>
							</> : <>
								<div>

									<img src="/assets/logo.squared.svg"></img>
									
									<br/>
								
									<IonSegment value={ this.state.tab_section___selected } onIonChange={(___e: any) => { this.setState({ tab_section___selected: ___e.detail.value }); }}>
										<IonSegmentButton value="login">
											<IonLabel>{ this._LOCALE.translate(locale___SbSignPage.section___login) }</IonLabel>
										</IonSegmentButton>
										<IonSegmentButton value="register">
											<IonLabel>{ this._LOCALE.translate(locale___SbSignPage.section___register) }</IonLabel>
										</IonSegmentButton>
									</IonSegment>

									<br/>
									<br/>
									<br/>

									{(() => { switch (this.state.tab_section___selected) {
										case 'login': return <>
											<ComInputUsername text={ this.state.field_login___username } event___onInput={(___e: string) => { this.setState({ field_login___username: ___e }) }} event___onInputIsValid={(___e: boolean) => { this.setState({ field_login___username___valid: ___e }) }}/>
											<ComInputPassword text={ this.state.field_login___password } event___onInput={(___e: string) => { this.setState({ field_login___password: ___e }) }} />
											<h6 onClick={() => { this.setState({ ionModal___MdSignPasswordResetModal___isOpen: true }); }}>{ this._LOCALE.translate(locale___SbSignPage.login___form___password_forgot) }</h6>
											<br/>
											<label htmlFor="sb-login---eula-accepted" className="sb-login---eula-acceptance">
												<input type="checkbox" id="sb-login---eula-accepted" checked={ this.state.field_login___eula_accepted } onChange={(___e: any) => { this.setState({ field_login___eula_accepted: ___e.target.checked })}}/>
												{(this.state.field_login___eula_accepted === true) ? <><i className="far fa-check-square"></i></> : <><i className="far fa-square"></i></>}
												<span>
													<span>{ this._LOCALE.translate(locale___SbSignPage.login___form___eula) } </span> 
													<Link to="/info/eula">{ this._LOCALE.translate(locale___SbSignPage.login___form___eula_url) }</Link> 
												</span>
											</label>
											<br/>
											<br/>
											<div>
												{(!(this.state.field_login___username.length > 0) || !(this.state.field_login___password.length > 0) || this.state.field_login___username___valid !== true) ? <>
													<button type="button" style={{cursor:'default',backgroundColor:'transparent'}}>
														<span>{ this._LOCALE.translate(locale___SbSignPage.login___form___button___disabled) }</span>
													</button>
												</> : <>
													{(this.state.field_login___eula_accepted === true) ? <>
														<button type="button" onClick={ this.perform_auth___login } style={{color:'var(--color---light)'}}>
															<span>{ this._LOCALE.translate(locale___SbSignPage.login___form___button___enabled) }</span>
															<i className="fas fa-chevron-right"></i>
														</button>
													</> : <>
														<button type="button" style={{cursor:'default',backgroundColor:'transparent'}}>
															<span>{ this._LOCALE.translate(locale___SbSignPage.login___form___button___missing_eula) }</span>
														</button>
													</>}
												</> }
											</div>
										</>; break;
										case 'register': return <>
											{(this.state.field_register___born_date___confirmed === null) ? <>
												<label htmlFor="field---event-datetime-begin" style={{textAlign:'center'}}>
													<p className="input---nb-label">la tua data di nascita</p>
													<input type="date" id="field---event-datetime-begin" className="input---nb-outlined" placeholder="dd/mm/yy" defaultValue={ this.state.field_register___born_date } value={ this.state.field_register___born_date } onChange={(___e: any) => { this.setState({ field_register___born_date: ___e.target.value }); }} style={{textAlign:'center'}}/>
												</label>
												<br />
												<p>
													<span>{ this._LOCALE.translate(locale___SbSignPage.register___form___age___paragraph) }</span>
													<Link to="/info/eula">{ this._LOCALE.translate(locale___SbSignPage.register___form___age___paragraph_url) }</Link>
												</p>
												<br/>
												<br/>
												<div>
													{(this.funcs___datetime.datetime___is14YearsOldOrMore(this.state.field_register___born_date) === true) ? <>
														<button type="button" onClick={ this.perform_auth___register___confirm_age } style={{color:'var(--color---light)'}}>
															<span>{ this._LOCALE.translate(locale___SbSignPage.register___form___button_age___enabled) }</span>
															<i className="fas fa-chevron-right"></i>
														</button>
													</> : <>
														<button type="button" style={{cursor:'default',backgroundColor:'transparent'}}>
															<span>{ this._LOCALE.translate(locale___SbSignPage.register___form___button_age___too_young) }</span>
														</button>
													</>}
												</div>
											</> : <>
												<input placeholder={ this._LOCALE.translate(locale___SbSignPage.register___form___placeholder___email) } type="email" className="input---nb-outlined" value={ this.state.field_register___email } onInput={(___e: any) => this.setState({ field_register___email: ___e.target.value })} style={{textTransform:'lowercase'}} />
												<ComInputUsername text={ this.state.field_register___username } event___onInput={(___e: string) => { this.setState({ field_register___username: ___e }) }} event___onInputIsValid={(___e: boolean) => { this.setState({ field_register___username___valid: ___e }) }}/>
												<ComInputPassword text={ this.state.field_register___password } event___onInput={(___e: string) => { this.setState({ field_register___password: ___e }) }} />
												<ComPasswordSafetyComponent password={ this.state.field_register___password } event___onProcess={() => { }}/>
												<br/>
												<label htmlFor="sb-register---eula-accepted" className="sb-login---eula-acceptance">
													<input type="checkbox" id="sb-register---eula-accepted" checked={ this.state.field_register___eula_accepted } onChange={(___e: any) => { this.setState({ field_register___eula_accepted: ___e.target.checked })}}/>
													{(this.state.field_register___eula_accepted === true) ? <><i className="far fa-check-square"></i></> : <><i className="far fa-square"></i></>}
													<span>
														<span>{ this._LOCALE.translate(locale___SbSignPage.register___form___eula) } </span> 
														<Link to="/info/eula">{ this._LOCALE.translate(locale___SbSignPage.register___form___eula_url) }</Link> 
													</span>
												</label>
												<br/>
												<br/>
												<div>
													{(!(this.state.field_register___email.length > 0) || !(this.state.field_register___username.length > 0) || !(this.state.field_register___password.length > 0) || this.state.field_register___username___valid !== true) ? <>
														<button type="button" style={{cursor:'default',backgroundColor:'transparent'}}>
															<span>{ this._LOCALE.translate(locale___SbSignPage.register___form___button___disabled) }</span>
														</button>
													</> : <>
														{(this.state.field_register___eula_accepted === true) ? <>
															<button type="button" onClick={ this.perform_auth___register } style={{color:'var(--color---light)'}}>
																<span>{ this._LOCALE.translate(locale___SbSignPage.register___form___button___enabled) }</span>
																<i className="fas fa-chevron-right"></i>
															</button>
														</> : <>
															<button type="button" style={{cursor:'default',backgroundColor:'transparent'}}>
																<span>{ this._LOCALE.translate(locale___SbSignPage.register___form___button___missing_eula) }</span>
															</button>
														</>}
													</> }
												</div>
											</>}
										</>; break;
									}})()}
								</div>

							</>}
						</div>
					</div>

				</IonContent>

			</IonPage>
		</>
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSignPage);