//	P R O P S

export interface props___ComInputPassword {

	placeholder?: string;
	text: string,
	
	event___onInput: (_: string) => void,

}

//	S T A T E

export interface state___ComInputPassword {

	field___is_visible: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComInputPassword___default: state___ComInputPassword = {

	field___is_visible: false,

}