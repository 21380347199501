import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdPoiRoleAddModal extends interface___modal, interface___is_logged {

	poi: any,
	user_role: string,

}

//	S T A T E

export interface state___MdPoiRoleAddModal {

	user_username_search: string,
	user_collection: any[],
	user_collection___is_loading: boolean,

	user_selected: any | null,
	user_selected_id: string,
	
	ionToast___role___isAdded: boolean | null,

}

//	S T A T E   D E F A U L T

export const state___MdPoiRoleAddModal___default: state___MdPoiRoleAddModal = {

	user_username_search: '',
	user_collection: [],
	user_collection___is_loading: false,

	user_selected: null,
	user_selected_id: '',
	
	ionToast___role___isAdded: null,

}