import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbDigitalMenuEditPage: type___locale___translations___collection = {
	'button___add_product': {
		de_de: 'Produkt hinzufügen',
		en_us: 'Add Product',
		es_es: 'Agregar producto',
		fr_fr: 'Ajouter un produit',
		it_it: 'Aggiungi prodotto',
	},
};