import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbChatsListPage: type___locale___translations___collection = {
	'title': {
		de_de: 'Konversationen',
		en_us: 'Conversations',
		es_es: 'Conversaciones',
		fr_fr: 'Conversations',
		it_it: 'Conversazioni',
	},
};