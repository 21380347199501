import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComChatMessageAttachmentComponent: type___locale___translations___collection = {
	'tale___he_respond': {
		de_de: 'Er hat auf Ihr Tale geantwortet',
		en_us: 'He responded to your Tale',
		es_es: 'Él respondió a tu Tale',
		fr_fr: 'Il a répondu à ton Tale',
		it_it: 'Ha risposto alla tua Tale',
	},
	'tale___you_respond': {
		de_de: 'Du hast mit Tale geantwortet',
		en_us: 'You answered Tale',
		es_es: 'Tu respondiste Tale',
		fr_fr: 'Vous avez répondu Tale',
		it_it: 'Hai risposto alla Tale',
	},
};