import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonToast, IonToggle, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbEventsEditPage } from './sb-events-edit.locale';

//	S T Y L E

import './sb-events-edit.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';
import { lib_names_shared } from '../../../libs/lib.names.shared';

//	S T A T E   -   P R O P S

import { props___SbEventEditPage, state___SbEventEditPage, state___SbEventEditPage___default } from './sb-events-edit.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___geo___lngLat } from '../../../types/types.types';

//	F U N C S

import { funcs_datetime } from '../../../funcs/funcs.datetime';
import { funcs_text } from '../../../funcs/funcs.text';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComEventCategoryComponent from '../../../components/com-event-category/com-event-category';
import ComMapPreviewComponent from '../../../components/com-map-preview/com-map-preview';
import ComMapPreviewAddressComponent from '../../../components/com-map-preview-address/com-map-preview-address';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComUserComponent from '../../../components/com-user/com-user';

//	M O D A L S

import MdEventSelectCategoryModal from '../../../modals/md-event-select-category/md-event-select-category';
import MdPoiSelectModal from '../../../modals/md-poi-select/md-poi-select';
import MdMediaEditorModal from '../../../modals/md-media-editor/md-media-editor';
import MdPositionSelectorModal from '../../../modals/md-position-selector/md-position-selector';

//	C L A S S

class SbEventEditPage extends React.Component<props___SbEventEditPage, state___SbEventEditPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventEditPage,
	) {
		super(props);
		this.state = state___SbEventEditPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
				
		this.setState({
			
			field___event_category: ___temp___event___props.data['event_category'],
			
			field___event_poster: ___temp___event___props.data['event_poster'],
			field___event_name: ___temp___event___props.data['event_name'],
			field___event_description: ___temp___event___props.data['event_description'],

			field___event_datetime___begin: this.funcs___datetime.datetime___cut_SZ((new Date(___temp___event___props.data['event_datetime_begin'])).toISOString() as string),
			field___event_datetime___end: this.funcs___datetime.datetime___cut_SZ((new Date(___temp___event___props.data['event_datetime_end'])).toISOString() as string),
			field___event_datetime___available: this.funcs___datetime.datetime___cut_SZ((new Date(___temp___event___props.data['event_settings___available_from'])).toISOString() as string),

			field___event_coords: { lng: ___temp___event___props.data['event_coords_lng'], lat: ___temp___event___props.data['event_coords_lat'] },

			field___event_contact_phone: (___temp___event___props.data['event_contact_phone'] !== null) ? ___temp___event___props.data['event_contact_phone'] : '',
			field___event_contact_email: ___temp___event___props.data['event_contact_email'],

			field___event_user_public: ___temp___event___props.data['event_user_public'],
			field___event_custom_icon: ___temp___event___props.data['event_settings___use_custom_icon'],

			event___props___is_loading: false










		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___SbEventEdit___askClose = () => {
		this.setState({
			ionAlert___askForClose___isVisible: true
		});
	}

	private handle___event_select___category = (___event_category: any | undefined | null) => {
		this.setState({
			ionModal___MdEventSelectCategoryModal___isOpen: false,
			field___event_category: ___event_category
		});
	}

	private handle___event_select___poi = (___event_poi: any | undefined | null) => {
		this.setState({
			ionModal___MdPoiSelectModal___isOpen: false,
			field___event_poi: (___event_poi) ? ___event_poi : null,
		}, () => {
			if (this.state.field___event_poi !== null) {
				this.setState({
					field___event_coords: {
						lng: this.state.field___event_poi['poi_coords_lng'],
						lat: this.state.field___event_poi['poi_coords_lat']
					}
				});
			}
		});
	}

	private handle___gallery___fileUpload = (___e: React.ChangeEvent<HTMLInputElement>) => {
		if (___e.target.files) {
			const ___files_array: File[] = Array.from(___e.target.files);
			const ___f: File = ___files_array[0];
			const ___f___reader: FileReader = new FileReader();
			___f___reader.onloadend = () => {
				const ___media___file: string = ___f___reader.result as string;
				this.setState({
					field___event_poster: ___media___file,
					ionModal___MdMediaEditorModal___isOpen: true
				});
			};
			___f___reader.readAsDataURL(___f);
		}
	}

	private handle___gallery___fileEditEnd = (___retrieved_gallery: string[] | null) => {
		this.setState({
			field___event_poster: (___retrieved_gallery && ___retrieved_gallery.length > 0) ? ___retrieved_gallery[0] : this.state.field___event_poster,
			ionModal___MdMediaEditorModal___isOpen: false,
		});
	}

	private handle___event_position___select = (___event_position: { coords: type___geo___lngLat, address: string }) => {
		this.setState({
			ionModal___MdPositionSelectorModal___isOpen: false,
			field___event_coords: ___event_position.coords,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private submit___event___check_if_can = () : boolean => {

		let ___can_submit: boolean = true;

		if (this.state.field___event_category === null) { document.getElementById('field---event-category').classList.add('input---nb-outlined---error'); ___can_submit = false; }
		if (this.state.field___event_name.length < 3) { document.getElementById('field---event-name').classList.add('input---nb-outlined---error'); ___can_submit = false; }
	//	if (!(this.state.field___event_datetime___begin && lib_names_shared.regex.input___datetime.test(this.state.field___event_datetime___begin))) { document.getElementById('field---event-datetime-begin').classList.add('input---nb-outlined---error'); ___can_submit = false; }
	//	if (!(this.state.field___event_datetime___end && lib_names_shared.regex.input___datetime.test(this.state.field___event_datetime___end))) { document.getElementById('field---event-datetime-end').classList.add('input---nb-outlined---error'); ___can_submit = false; }

		if (this.state.field___event_coords === null) { document.getElementById('field---event-coords').classList.add('input---nb-outlined---error'); ___can_submit = false; }
		
		if (___can_submit === false) {
			this.setState({
				ionToast___type: 'danger',
				ionToast___message: this._LOCALE.translate(locale___SbEventsEditPage.ionToast___cannot_save),
				ionToast___isOpen: true,
			});
		}

		return ___can_submit as boolean;

	}

	private submit___event = () => {

		const ___can_submit: boolean = this.submit___event___check_if_can();

		if (___can_submit === true) {
			this.setState({
				submitting___is_loading: true,
			}, async () => {
				const ___event___is_submitted: type___api___response = await this._API.events___submit(this.state.event___id, this.state.field___event_category['event_category_id'], this.state.field___event_poster, this.state.field___event_name, this.state.field___event_description, this.state.field___event_coords, (this.state.field___event_poi) ? this.state.field___event_poi['poi_id'] : null, this.state.field___event_datetime___begin, this.state.field___event_datetime___end, this.state.field___event_datetime___available, this.state.field___event_contact_phone, this.state.field___event_contact_email, this.state.field___event_user_public, this.state.field___event_custom_icon);
				this.setState({
					ionToast___type: (___event___is_submitted && ___event___is_submitted.response === 'success') ? 'success' : 'danger',
					ionToast___message: (___event___is_submitted && ___event___is_submitted.response === 'success') ? this._LOCALE.translate(locale___SbEventsEditPage.ionToast___event_create) : this._LOCALE.translate(locale___SbEventsEditPage.ionToast___event_creation_error),
					ionToast___isOpen: true,
					submitting___is_loading: false,
				}, () => {
					if ((___event___is_submitted && ___event___is_submitted.response === 'success')) {
						window.history.back();
					}
				})
			});
		}

	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event_id: string | null = this._ROUTER.get_id(this.props);
		if (___event_id && ___event_id !== 'new') {
			this.setState({
				event___id: ___event_id,
			}, async () => {
				await this.read_props___event();
			});
		} else {
			this.setState({
				event___id: null,
				event___props___is_loading: false,
				field___event_datetime___available: this.funcs___datetime.datetime___cut_SZ((new Date()).toISOString()),
				ionModal___MdEventSelectCategoryModal___isOpen: true,
			});
		}
	}

	ionViewDidLeave() : void {
		this.setState({
			...state___SbEventEditPage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonToast isOpen={ this.state.ionToast___isOpen } message={ this.state.ionToast___message } duration={ 3000 } color={ this.state.ionToast___type } position="top" onDidDismiss={() => { this.setState({ ionToast___isOpen: false }); }}/>

				<MdEventSelectCategoryModal isOpen={ this.state.ionModal___MdEventSelectCategoryModal___isOpen } event___onDidDismiss={ this.handle___event_select___category } />
				<MdPoiSelectModal isOpen={ this.state.ionModal___MdPoiSelectModal___isOpen } target="poi_permissions___events" event___onDidDismiss={ this.handle___event_select___poi } />

				{(this.state.field___event_poster !== null) ? <>
					<MdMediaEditorModal isOpen={ this.state.ionModal___MdMediaEditorModal___isOpen } input___aspect_ratio={ lib_names.aspectRatios.event_poster.ar } input___image_list={[ this.state.field___event_poster ]} event___onDidDismiss={ this.handle___gallery___fileEditEnd } />
				</> : <></>}
				
				<MdPositionSelectorModal isOpen={ this.state.ionModal___MdPositionSelectorModal___isOpen } position___input={ this.state.field___event_coords } event___onDidDismiss={ this.handle___event_position___select }/>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.event___props___is_loading === true || this.state.submitting___is_loading === true) ? <>
					
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					
					</> : <>

{/**
 * 
 * 
 * 
 * 						C A T E G O R I A
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-icons"></i>
								<span>{ this._LOCALE.translate(locale___SbEventsEditPage.category___title) }</span>
							</IonLabel>
						</IonListHeader>
						
						<div className="sb-event-edit---container">
							<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.category___category) }</p>
							<IonCard onClick={() => { this.setState({ ionModal___MdEventSelectCategoryModal___isOpen: true }); }} id="field---event-category">
								{(this.state.field___event_category === null) ? <>
									<IonList>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-dice"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbEventsEditPage.category___select) }</IonLabel>
											<IonNote slot="end">
												<i className="fa-solid fa-pen"></i>
											</IonNote>
										</IonItem>
									</IonList>
								</> : <>
									<ComEventCategoryComponent event_category___props={ this.state.field___event_category } isButton={ true }/>
									<IonNote slot="end">
										<i className="fa-solid fa-pen"></i>
									</IonNote>
								</>}
							</IonCard>
						</div>

{/**
 * 
 * 
 * 
 * 						P O S T E R   E   I N F O R M A Z I O N I
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-info-circle"></i>
								<span>{ this._LOCALE.translate(locale___SbEventsEditPage.poster_and_info___title) }</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-event-edit---container">
						
							<p className="input---nb-label">{ this._LOCALE.translate(locale___SbEventsEditPage.poster_and_info___poster) }</p>
							<input type="file" id="field---event-poster" onChange={ this.handle___gallery___fileUpload } accept={ lib_names_shared.files.input_accepted } multiple={ false } style={{display:'none'}}/>
							<label htmlFor="field---event-poster">
								<IonCard id="field---event-poster">
									{(this.state.field___event_poster !== null) ? <>
										<img src={ this.state.field___event_poster } />
										<br />
									</> : <></>}
									<IonList>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-images"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbEventsEditPage.poster_and_info___edit_poster) }</IonLabel>
											<IonNote slot="end">
												<i className="fa-solid fa-plus"></i>
											</IonNote>
										</IonItem>
									</IonList>
								</IonCard>
							</label>

							<br />

							<label htmlFor="field---event-name">
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.poster_and_info___field___name) }</p>
								<input type="text" id="field---event-name" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___SbEventsEditPage.poster_and_info___field___name___placeholder) }value={ this.state.field___event_name } onInput={(___e: any) => { this.setState({ field___event_name: ___e.target.value}); }}/>
							</label>

							<br />

							<label htmlFor="field---event-description">
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.poster_and_info___field___description) }</p>
								<textarea id="field---event-description" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___SbEventsEditPage.poster_and_info___field___description___placeholder) } style={{height:'250pt'}} value={ this.state.field___event_description } onInput={(___e: any) => { this.setState({ field___event_description: this.funcs___text.text_input___process(___e.target.value) }); }}></textarea>
							</label>

						</div>

{/**
 * 
 * 
 * 
 * 						D A T A   E   O R A
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-map-marked-alt"></i>
								<span>{ this._LOCALE.translate(locale___SbEventsEditPage.datetime___title) }</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-event-edit---container">

							<label htmlFor="field---event-datetime-begin">
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.datetime___begin) }</p>
								<input type="datetime-local" id="field---event-datetime-begin" className="input---nb-outlined" placeholder="dd/mm/yy hh:mm" defaultValue={ this.state.field___event_datetime___begin } value={ this.state.field___event_datetime___begin } onChange={(___e: any) => { this.setState({ field___event_datetime___begin: ___e.target.value}); }}/>
							</label>

							<label htmlFor="field---event-datetime-end">
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.datetime___end) }</p>
								<input type="datetime-local" id="field---event-datetime-end" className="input---nb-outlined" placeholder="dd/mm/yy hh:mm" defaultValue={ this.state.field___event_datetime___end } value={ this.state.field___event_datetime___end } onChange={(___e: any) => { this.setState({ field___event_datetime___end: ___e.target.value}); }}/>
							</label>

						</div>

{/**
 * 
 * 
 * 
 * 						I N D I R I Z Z O   E   P O S I Z I O N E
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-map-marked-alt"></i>
								<span>{ this._LOCALE.translate(locale___SbEventsEditPage.position___title) }</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-event-edit---container">

							{(this.state.field___event_coords !== null) ? <>
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.position___address_in_map) }</p>
								<section onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }}>
									<ComMapPreviewAddressComponent coords={ this.state.field___event_coords } canOpenRoute={ false }/>
								</section>
								<section onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }}>
									<ComMapPreviewComponent map_coords={ this.state.field___event_coords } canOpenRoute={ false }/>
								</section>
								<br />
								<IonCard onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }}>
									<IonList>
										<IonItem className="is-ion-item-button" button={ true }>
											<i className="fa-solid fa-map-marked-alt"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbEventsEditPage.position___edit_position) }</IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
							</> : <>
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.position___insert_position_in_map) }</p>
								<IonCard onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }} id="field---event-coords">
									<IonList>
										<IonItem className="is-ion-item-button" button={ true }>
											<i className="fa-solid fa-map-marked-alt"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbEventsEditPage.position___insert_position) }</IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
							</>}

							<section onClick={() => { this.setState({ ionModal___MdPoiSelectModal___isOpen: true }); }}>
								{(this.state.field___event_poi !== null && this.state.field___event_poi !== undefined) ? <>
									<p className="input---nb-label">{ this._LOCALE.translate(locale___SbEventsEditPage.position___matched) }</p>
									<ComPoiComponent poi_props={ this.state.field___event_poi } size="normal" />
									<br />
									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" button={ true }>
												<i className="fas fa-map-pin"></i>
												<IonLabel>{ this._LOCALE.translate(locale___SbEventsEditPage.position___edit_match) }</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
								</> : <>
									<p className="input---nb-label">{ this._LOCALE.translate(locale___SbEventsEditPage.position___match_to_poi) } </p>
									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" button={ true }>
												<i className="fas fa-map-pin"></i>
												<IonLabel>{ this._LOCALE.translate(locale___SbEventsEditPage.position___match_to_poi) }</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
								</>}
							</section>

						</div>

{/**
 * 
 * 
 * 
 * 						C O N T A T T I
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-mail-bulk"></i>
								<span>{ this._LOCALE.translate(locale___SbEventsEditPage.contacts___title) }</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-event-edit---container">

							<label htmlFor="field---event-contact-phone">
								<p className="input---nb-label">{ this._LOCALE.translate(locale___SbEventsEditPage.contacts___phone) }</p>
								<input type="text" id="field---event-contact-phone" className="input---nb-outlined" placeholder="+39 333 333 3334" value={ this.state.field___event_contact_phone } onInput={(___e: any) => { this.setState({ field___event_contact_phone: ___e.target.value}); }}/>
							</label>

							<label htmlFor="field---event-contact-email">
								<p className="input---nb-label">{ this._LOCALE.translate(locale___SbEventsEditPage.contacts___email) }</p>
								<input type="text" id="field---event-contact-email" className="input---nb-outlined" placeholder="email@email.com" value={ this.state.field___event_contact_email } onInput={(___e: any) => { this.setState({ field___event_contact_email: ___e.target.value}); }}/>
							</label>

						</div>

{/**
 * 
 * 
 * 
 * 						A L T R O
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-mail-bulk"></i>
								<span>{ this._LOCALE.translate(locale___SbEventsEditPage.other___title) }</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-event-edit---container">

							<IonCard>
								<IonList>
									{(this.props.is_logged___props['user_custom_icon___events'] !== null) ? <>
										<IonItem className="is-ion-item-button">
											<img src={ this.props.is_logged___props['user_custom_icon___events'] }/>
											<IonToggle checked={ this.state.field___event_custom_icon } onIonChange={(___e: any) => { this.setState({ field___event_custom_icon: ___e.detail.checked }); }}>
												<>{ this._LOCALE.translate(locale___SbEventsEditPage.other__custom_icon) }</>
											</IonToggle>
										</IonItem>
									</> : <></>}
									<IonItem className="is-ion-item-button">
										<img src="/assets/markers/poi.svg"/>
										<IonToggle checked={ this.state.field___event_user_public } onIonChange={(___e: any) => { this.setState({ field___event_user_public: ___e.detail.checked }); }}>
											<>{ this._LOCALE.translate(locale___SbEventsEditPage.other___user_public) }</>
										</IonToggle>
									</IonItem>
								</IonList>
							</IonCard>
							
							<br />

							{(() => { switch (this.state.field___event_user_public as boolean) {
								case true: return <>
									<ComUserComponent user___props={ this.props.is_logged___props } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
								</>; break;
								case false: return <>
									<section style={{filter:'grayscale(1)',opacity:0.5}}>
										<ComUserComponent user___props={ this.props.is_logged___props } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
									</section>
								</>; break;
							}})()}

							<br />

							<label htmlFor="field---event-datetime-available">
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___SbEventsEditPage.other___available_when) }</p>
								<input type="datetime-local" id="field---event-datetime-available" className="input---nb-outlined" placeholder="dd/mm/yy hh:mm" defaultValue={ this.state.field___event_datetime___available } value={ this.state.field___event_datetime___available } onChange={(___e: any) => { this.setState({ field___event_datetime___available: ___e.target.value}); }}/>
							</label>
	
						</div>

						<br />
						<br />
						<br />

					</>}
				</IonContent>

				<IonFooter>
					{(!(this.state.event___props___is_loading == true || this.state.submitting___is_loading === true)) ? <>
						<section className="container---footer-section" onClick={ this.submit___event }>
							<button className="is-element is-button is-color---folly is-full-width">
								<i className="fa-solid fa-check"></i>
								{(this.state.event___id === null) ? <>
									<h4>{ this._LOCALE.translate(locale___SbEventsEditPage.button___upload) }</h4>
								</> : <>
									<h4>{ this._LOCALE.translate(locale___SbEventsEditPage.button___upload___update) }</h4>
								</>}
							</button>
						</section>
					</> : <></>}
				</IonFooter>

			</IonPage>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbEventEditPage);