import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbArchiveNotificationsPage: type___locale___translations___collection = {
	'ionActionSheet___select_as_read': {
		de_de: 'Alles als gelesen markieren',
		en_us: 'Mark all as read',
		es_es: 'Marcar todo como leído',
		fr_fr: 'Marquer tout comme lu',
		it_it: 'Segna tutto come letto',
	},
	'title': {
		de_de: 'Deine Benachrichtigungen',
		en_us: 'Your Notifications',
		es_es: 'Tus Notificaciones',
		fr_fr: 'Vos Notifications',
		it_it: 'Le tue Notifiche',
	}
};