import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbArchiveEnrollPage: type___locale___translations___collection = {
'title': {
		de_de: 'Möchten Sie ein Ereignis oder einen Punkt von Interesse erstellen?',
		en_us: 'Do you want to create an Event or a Point of Interest?',
		es_es: '¿Quieres crear un Evento o un Punto de Interés?',
		fr_fr: 'Voulez-vous créer un Événement ou un Point d\'Intérêt?',
		it_it: 'Vuoi creare un Evento o un Punto di Interesse?',
	},
	'description___1': {
		de_de: 'Um unseren Nutzern eine sichere Plattform ohne Spam, Betrug oder andere Gefahren zu gewährleisten, überprüfen wir alle, die öffentliche Inhalte wie Veranstaltungen und Sehenswürdigkeiten hinzufügen möchten.',
		en_us: 'To ensure a safe platform for our users, free from spam, scams, and other hazards, we verify anyone who wants to add public content such as Events and Points of Interest.',
		es_es: 'Para garantizar a nuestros usuarios una plataforma segura, libre de spam, estafas y otros peligros, verificamos a cualquiera que quiera agregar contenido público como Eventos y Puntos de Interés.',
		fr_fr: 'Pour garantir à nos utilisateurs une plateforme sûre, exempte de spam, d\'arnaques et d\'autres dangers, nous vérifions toute personne souhaitant ajouter du contenu public, tel que des Événements et des Points d\'Intérêt.',
		it_it: 'Per garantire ai nostri utenti la sicurezza di una piattaforma pulita, senza spam, truffe o altri pericoli, sottoponiamo ad una verifica chiunque voglia inserire contenuti pubblici come Eventi e Punti di interesse',
	},
	'description___2': {
		de_de: 'Kontaktieren Sie uns jetzt per E-Mail',
		en_us: 'Contact us now via email',
		es_es: 'Contáctanos ahora por correo electrónico',
		fr_fr: 'Contactez-nous maintenant par e-mail',
		it_it: 'Contattaci subito via mail',
	}
};