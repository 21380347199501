import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComCopyrightComponent: type___locale___translations___collection = {
	'row1': {
		de_de: '© Copyright 2022 - {{1}} by PRYSM',
		en_us: '© Copyright 2022 - {{1}} by PRYSM',
		es_es: '© Copyright 2022 - {{1}} by PRYSM',
		fr_fr: '© Copyright 2022 - {{1}} by PRYSM',
		it_it: '© Copyright 2022 - {{1}} by PRYSM',
	},
	'row2': {
		de_de: '"Nearby" und "Nearby Community" sind eingetragene Marken, die ausschließlich PRYSM LAB gehören',
		en_us: '"Nearby" and "Nearby Community" are registered trademarks exclusively owned by PRYSM LAB',
		es_es: '"Nearby" y "Nearby Community" son marcas registradas propiedad exclusiva de PRYSM LAB',
		fr_fr: '"Nearby" et "Nearby Community" sont des marques déposées appartenant exclusivement à PRYSM LAB',
		it_it: '"Nearby" e "Nearby Community" sono marchi registrati di proprietà esclusiva di PRYSM LAB',
	},
	'row3': {
		de_de: 'jede unerlaubte Vervielfältigung, auch teilweise, ist untersagt',
		en_us: 'any unauthorized reproduction, even partial, is prohibited',
		es_es: 'cualquier reproducción no autorizada, incluso parcial, está prohibida',
		fr_fr: 'toute reproduction non autorisée, même partielle, est interdite',
		it_it: 'ogni loro riproduzione anche parziale non autorizzata è vietata',
	},
};