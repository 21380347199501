import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdUserFollowsModal extends interface___modal, interface___is_logged {

	target: 'following' | 'followers' | '',
	target_user: string,

}

//	S T A T E

export interface state___MdUserFollowsModal {

	user_follows___props: any[],
	user_follows___props___is_loading: boolean,
	user_follows___props___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdUserFollowsModal___default: state___MdUserFollowsModal = {
	
	user_follows___props: [],
	user_follows___props___is_loading: true,
	user_follows___props___is_complete: false,

}