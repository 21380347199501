import React from 'react'
import { IonCard } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComUserComponent } from './com-user.locale';

//	S T Y L E

import './com-user.scss'

//	S T A T E S   -   P R O P S

import { props___ComUserComponent } from './com-user.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComUserVerifiedComponent from '../com-user-verified/com-user-verified';

//	C L A S S

export default class ComUserComponent extends React.Component<props___ComUserComponent, {}>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.props) ? <>
				<IonCard className="com-user-component---container">
					<img src={ this.props.user___props['user_image_profile'] }/>
					<section>
						<h5>
							<span>{ this.props.user___props['user_username'] }</span>
							{(this.props.user___props['user_profile_verified'] && this.props.user___props['user_profile_verified'] === true) ? <>
								<ComUserVerifiedComponent popup={ false } />
							</> : <></>}
						</h5>
						{(this.props.is_logged && this.props.is_logged___props && this.props.is_logged === true && this.props.is_logged___props !== null) ? <>
								{(this.props.user___props['user_is_close_friend'] && this.props.user___props['user_is_close_friend'] === true) ? <>
									<section className="com-user-component---container---is-close-friend">
										<section>
											<span>{ this._LOCALE.translate(locale___ComUserComponent.is_close_friend) }</span>
										</section>
									</section>
								</> : <>
									<p>
										{(
											(this.props.user___props['user_you_follow_him'] && this.props.user___props['user_you_follow_him'] === true) ||
											(this.props.user___props['user_he_follows_you'] && this.props.user___props['user_he_follows_you'] === true)
										) ? <>
											{(() => { switch (true) {
												case (this.props.user___props['user_you_follow_him'] && this.props.user___props['user_you_follow_him'] === true && this.props.user___props['user_he_follows_you'] && this.props.user___props['user_he_follows_you'] === true): return <>
													<i className="fas fa-link"></i>
													<span>{ this._LOCALE.translate(locale___ComUserComponent.followed_by_both) }</span>
												</>; break;
												case (this.props.user___props['user_you_follow_him'] && this.props.user___props['user_you_follow_him'] === true): return <>
													<i className="fas fa-link"></i>
													<span>{ this._LOCALE.translate(locale___ComUserComponent.followed_by_you) }</span>
												</>; break;
												case (this.props.user___props['user_he_follows_you'] && this.props.user___props['user_he_follows_you'] === true): return <>
													<i className="fas fa-link"></i>
													<span>{ this._LOCALE.translate(locale___ComUserComponent.followed_by_him) }</span>
												</>; break;
											}})()}
										</> : <>
											{(
												(this.props.user___props['user_count___followers'] && this.props.user___props['user_count___followers'] > 0) ||
												(this.props.user___props['user_count___following'] && this.props.user___props['user_count___following'] > 0)
											) ? <>
												<i className="fas fa-users"></i>
												<span>{ this._LOCALE.translate(locale___ComUserComponent.follows_total, [(this.props.user___props['user_count___followers'] + this.props.user___props['user_count___following'])]) }</span>
											</> : <>
												<i className="fas fa-users"></i>
												<span>{ this._LOCALE.translate(locale___ComUserComponent.follows_nothing) }</span>
											</>}
										</>}
									</p>
								</>}
						</> : <></>}
					</section>
				</IonCard>
			</> : <></>}
		</>;
	}

//#endregion

}