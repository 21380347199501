import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbArchiveEventsPage: type___locale___translations___collection = {
	'title': {
		de_de: 'Deine Veranstaltungen',
		en_us: 'Your Events',
		es_es: 'Tus Eventos',
		fr_fr: 'Vos Événements',
		it_it: 'I tuoi Eventi',
	},
	'button___create_event': {
		de_de: 'Ereignis erstellen',
		en_us: 'Create Event',
		es_es: 'Crear Evento',
		fr_fr: 'Créer un Événement',
		it_it: 'Crea Evento',
	}
};