import React from 'react';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonModal, IonToast, IonToolbar } from '@ionic/react';
import { locale___App } from '../../App.locale';

//	L O C A L E

import { locale___MdPoiRoleAddModal } from './md-poi-role-add.locale';

//	S T Y L E

import './md-poi-role-add.scss';

//	S T A T E   -   P R O P S

import { props___MdPoiRoleAddModal, state___MdPoiRoleAddModal, state___MdPoiRoleAddModal___default } from './md-poi-role-add.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../components/com-list-separator/com-list-separator';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';
import ComUserComponent from '../../components/com-user/com-user';

//	C L A S S

export default class MdPoiRoleAddModal extends React.Component<props___MdPoiRoleAddModal, state___MdPoiRoleAddModal>
{

//#region 																							C O N F I G

	private readonly MdPoiRoleAddModal___modal_config: type___modal___config = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); },
	};

//#endregion

//#region 																							D E C L A R A T I O N

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPoiRoleAddModal,
	) {
		super(props);
		this.state = state___MdPoiRoleAddModal___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___user_select = (___user: any) => {
		this.setState({
			user_selected: ___user,
			user_selected_id: ___user['user_id'],
		});
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___user_collection = () => {
		if (this.state.user_username_search && this.state.user_username_search.length > 0) {
			this.setState({
				user_collection___is_loading: true,
			}, async () => {
				const ___temp___user_collection: type___api___response = await this._API.users___read___multi('search', this.state.user_username_search, 0);
				this.setState({
					user_collection: ___temp___user_collection.data,
					user_collection___is_loading: false,
				});
			});
		}
	}

//#endregion

//#region 																							S U B M I T

	private submit___new_role = async () => {
		const ___is_added___in_role___poi_id: string = this.props.poi['poi_id'];
		const ___is_added___in_role___user_id: string = this.state.user_selected['user_id'];
		const ___is_added___in_role___wich_role: string = this.props.user_role;
		const ___is_added___in_role: type___api___response = await this._API.poi___role_edit('add', ___is_added___in_role___poi_id, ___is_added___in_role___user_id, ___is_added___in_role___wich_role);
		this.setState({
			ionToast___role___isAdded: (___is_added___in_role && ___is_added___in_role.response === 'success') ? true : false,
		}, () => {
			if (___is_added___in_role.response === 'success') {
				this.props.event___onDidDismiss();
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	event___onDidPresent = () : void => {
		this.setState({
			...state___MdPoiRoleAddModal___default,
		});
	}

	event___onDidDismiss = () : void => {
		this.setState({
			...state___MdPoiRoleAddModal___default,
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>

			<IonToast isOpen={( this.state.ionToast___role___isAdded === true )} message={ this._LOCALE.translate(locale___MdPoiRoleAddModal.ionToast___role_added) } duration={ 3000 } color="success" position="top" onDidDismiss={() => { this.setState({ ionToast___role___isAdded: null }); }}/>
			<IonToast isOpen={( this.state.ionToast___role___isAdded === false )} message={ this._LOCALE.translate(locale___MdPoiRoleAddModal.ionToast___role_added___error) } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ ionToast___role___isAdded: null }); }}/>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdPoiRoleAddModal___modal_config }>

				<IonHeader>
						<IonToolbar>
							<IonButtons slot="end">
								<IonButton onClick={ this.props.event___onDidDismiss }>
									<b>{ this._LOCALE.translate(locale___App.modal___topBar___button___close) }</b>
								</IonButton>
							</IonButtons>
					</IonToolbar>
				</IonHeader>
				
				<IonContent forceOverscroll={ false }>

					<IonListHeader>
						<IonLabel>{ this._LOCALE.translate(locale___MdPoiRoleAddModal.title) }</IonLabel>
					</IonListHeader>

					<br />

					<div className="md-poi-role-add---container">

						{(() => { switch (this.props.user_role) {
							case 'poi_permissions___events': return <>
								<h4>{ this._LOCALE.translate(locale___MdPoiRoleAddModal.type_selected___title) }</h4>
								<p>{ this._LOCALE.translate(locale___MdPoiRoleAddModal.type_selected___description) }</p>
							</>; break;
							
							






						}})()}

						<br />

						{(this.state.user_selected !== null) ? <>

							<h4>{ this._LOCALE.translate(locale___MdPoiRoleAddModal.you_selected) }</h4>
							<ComUserComponent user___props={ this.state.user_selected } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
							<p onClick={() => { this.setState({ user_selected: null, user_collection: [], user_collection___is_loading: false, user_username_search: '' }); }}>
								<span>{ this._LOCALE.translate(locale___MdPoiRoleAddModal.you_selected___cancel) }</span>
							</p>
							
						</> : <>
						
							<br />

							<label htmlFor="field---user">
								<p className="input---nb-label">{ this._LOCALE.translate(locale___MdPoiRoleAddModal.field___search) }</p>
								<section style={{display:'flex',gap:'10pt'}}>
									<input type="text" id="field---user" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___MdPoiRoleAddModal.field___search___placeholder) } value={ this.state.user_username_search } onInput={(___e: any) => { this.setState({ user_username_search: ___e.target.value }, () => { this.read_props___user_collection(); }); }} />
									<i className="fas fa-search" style={{lineHeight:'40pt'}}></i>
								</section>
							</label>

							<br />

							{(this.state.user_collection___is_loading === true) ? <>
								<ComSpinnerInlineComponent/>
							</> : <>
								{(this.state.user_collection.length > 0) ? <>
									<section>
										{this.state.user_collection.map((___user: any, ___user___idx: number) => {
											return <>
												<section key={ ___user___idx } onClick={() => { this.handle___user_select(___user) }}>
													<ComUserComponent user___props={ ___user } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
												</section>
												<ComListSeparatorComponent/>
											</>;
										})}
									</section>
								</> : <>
									<ComListEmptyComponent text={ this._LOCALE.translate(locale___MdPoiRoleAddModal.field___search___not_found) } />
								</>}
							</>}
						
						</>}

					</div>
				</IonContent>

				<IonFooter>
					{(this.state.user_selected !== null) ? <>
						<section className="container---footer-section" onClick={() => { this.submit___new_role(); }}>
							<button className="is-element is-button is-color---folly is-full-width">
								<i className="fa-solid fa-check"></i>
								<h4>{ this._LOCALE.translate(locale___MdPoiRoleAddModal.button___add_role) }</h4>
							</button>
						</section>
					</> : <>
						<section className="container---footer-section">
							<button className="is-element is-button is-color---shadow is-full-width">
								<i></i>
								<h4>{ this._LOCALE.translate(locale___MdPoiRoleAddModal.button___select_user) }</h4>
							</button>
						</section>
					</>}
				</IonFooter>

			</IonModal>

		</>;
	}

//#endregion

}