import React from 'react';
import { IonAlert, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonModal, IonSelect, IonSelectOption, IonToast, IonToolbar } from '@ionic/react';

//	S T Y L E

import './md-posts-create.scss';

//	S T A T E   -   P R O P S

import { props___MdPostsCreateModal, state___MdPostsCreateModal, state___MdPostsCreateModal___default } from './md-posts-create.state';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../interfaces/interface.alert';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	F U N C S

import { funcs_text } from '../../funcs/funcs.text';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';
import ComPoiComponent from '../../components/com-poi/com-poi';
import ComEventComponent from '../../components/com-event/com-event';
import ComInputTextareaComponent from '../../components/com-input-textarea/com-input-textarea';
import { locale___MdPostsCreateModal } from './md-posts-create.locale';
import { locale___App } from '../../App.locale';

//	C L A S S

export default class MdPostsCreateModal extends React.Component<props___MdPostsCreateModal, state___MdPostsCreateModal>
{

//#region 																							C O N F I G

	private readonly MdPostsCreateModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly ionAlert___ask_close___props: interface___IonAlert = {
		header: this._LOCALE.translate(locale___MdPostsCreateModal.ionAlert___cancel___title),
		message: this._LOCALE.translate(locale___MdPostsCreateModal.ionAlert___cancel___description),
		buttons: [
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___yes),
				handler: () => {
					this.props.event___onDidDismiss();
				}
			},
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___cancel),
				role: 'cancel',
				handler: () => {
					this.setState({
						ionAlert___askForClose___visibility: false
					});
				}
			}
		]
	};

//#endregion


//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPostsCreateModal,
	) {
		super(props);
		this.state = state___MdPostsCreateModal___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___post___submit = () => {
		this.setState({
			post___is_submitting: true,
		}, async () => {
			const ___post___submit: type___api___response = await this._API.posts___submit(this.props.post_target___type, this.props.post_target___id, this.state.post___text, this.state.post___visibility, this.state.post___share_element_type, this.state.post___share_element_id);
			if (___post___submit.response === 'error') {
				this.setState({
					ionToast___error___isOpen: true, 
					post___is_submitting: false,
				});
			} else {
				this.props.event___onDidDismiss();
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void
	{
		this.setState({
			...state___MdPostsCreateModal___default
		}, () => {
			if (this.props.post_attachment_type && this.props.post_attachment_type) {
				this.setState({
					post___share_element_type: this.props.post_attachment_type,
					post___share_element_id: (() => {
						switch (this.props.post_attachment_type) {
							case 'event': return this.props.post_attachment_element['event_id']; break;
							case 'poi': return this.props.post_attachment_element['poi']; break;
						}
					})(),
				});
			}
		});
	}

	private event___onDidDismiss() : void
	{
		this.setState({
			...state___MdPostsCreateModal___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdPostsCreateModal___modal_config}>

				<IonAlert isOpen={ this.state.ionAlert___askForClose___visibility } {...this.ionAlert___ask_close___props}></IonAlert>

				<IonToast isOpen={ this.state.ionToast___error___isOpen} message={ this._LOCALE.translate(locale___MdPostsCreateModal.ionToast___error) } onDidDismiss={() => { this.setState({ ionToast___error___isOpen: false }); }} position="top" />

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => { this.setState({ ionAlert___askForClose___visibility: true }); }}>
								<b>{ this._LOCALE.translate(locale___App.modal___topBar___button___cancel) }</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<br />

					<IonListHeader>
						<IonLabel>{ this._LOCALE.translate(locale___MdPostsCreateModal.title) }</IonLabel>
					</IonListHeader>

					{(this.state.post___is_submitting === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<ComSpinnerInlineComponent/>
					
					</> : <>
					
						<section className="md-modal---container md-posts-create---container">
							{(
								(
									(this.props.post_target___type === 'poi' && this.props.post_target___name && this.props.post_target___id) ||
									(this.props.post_target___type === 'user' && this.props.post_target___name && this.props.post_target___id) ||
									(this.props.post_target___type === 'self')
								) &&
								(this.props.is_logged && this.props.is_logged === true && this.props.is_logged___props && this.props.is_logged___props !== null)
							) ? <>
								
								{(() => { switch (this.props.post_target___type) {
									case 'poi': return <>
										<h6>{ this._LOCALE.translate(locale___MdPostsCreateModal.writing_as___name, [this.props.post_target___name]) }</h6>
									</>; break;
									case 'user': return <>
										{(this.props.post_target___id === null || this.props.is_logged___props['user_id'] === this.props.post_target___id) ? <>
											<h6>{ this._LOCALE.translate(locale___MdPostsCreateModal.writing_on___your_profile) }o</h6>
										</> : <>
											<h6>{ this._LOCALE.translate(locale___MdPostsCreateModal.writing_on___name_profile, [this.props.post_target___name]) }</h6>
										</>}
									</>; break;
									case 'self': return <>
										{(this.props.post_attachment_type && this.props.post_attachment_type) ? <>
											{(() => { switch (this.props.post_attachment_type) {
												case 'poi': return <>
													<h6>{ this._LOCALE.translate(locale___MdPostsCreateModal.sharing___poi) }</h6>
													<ComPoiComponent poi_props={ this.props.post_attachment_element } size="normal"/>
												</>; break;
												case 'event': return <>
													<h6>{ this._LOCALE.translate(locale___MdPostsCreateModal.sharing___event) }</h6>
													<ComEventComponent event_props={ this.props.post_attachment_element } size="normal"/>
												</>; break;
											}})()}
										</> : <>
											<h6>{ this._LOCALE.translate(locale___MdPostsCreateModal.writing_on___your_profile) }</h6>
										</>}
									</>; break;
								}})()}

								<br/>

								<ComInputTextareaComponent text={ this.state.post___text } event___onInput={(___e: string) => { this.setState({ post___text: ___e }); }} is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />

							</> : <>
								
								{ this._LOCALE.translate(locale___MdPostsCreateModal.something_gone_wrong) }
							
							</>}

						</section>

						<br />

					</>}

				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.handle___post___submit }>
							<i className="fa-solid fa-check"></i>
							<h4>{ this._LOCALE.translate(locale___MdPostsCreateModal.button___save) }</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}