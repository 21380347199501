import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPostCommentComponent: type___locale___translations___collection = {
	'ionActionSheet___postCommentAction____button___delete': {
		de_de: 'Löschen',
		en_us: 'Delete',
		es_es: 'Borrar',
		fr_fr: 'Supprimer',
		it_it: 'Elimina',
	},
	'ionActionSheet___postCommentAction____title': {
		de_de: 'Aufmerksamkeit',
		en_us: 'Attention',
		es_es: 'Atención',
		fr_fr: 'Attention',
		it_it: 'Attenzione',
	},
	'ionActionSheet___postCommentAction____message': {
		de_de: 'Sie sind dabei, diesen Kommentar zu löschen. Der Vorgang kann nicht rückgängig gemacht werden. Möchten Sie fortfahren?',
		en_us: 'You are about to delete this comment, the action is irreversible, Do you want to continue?',
		es_es: 'Estás a punto de eliminar este comentario, la acción es irreversible, ¿Quieres continuar?',
		fr_fr: 'Vous êtes sur le point de supprimer ce commentaire, l\'action est irréversible, Voulez-vous continuer ?',
		it_it: 'Stai per eliminare questo commento, l\'azione è irreversibile, Vuoi continuare?',
	},
	'ionToast___errorDeleting': {
		de_de: 'Fehler beim Löschen des Kommentars',
		en_us: 'Error deleting comment',
		es_es: 'Error al eliminar comentario',
		fr_fr: 'Erreur lors de la suppression du commentaire',
		it_it: 'Errore nell\'eliminazione del commento',
	},
	'ionToast___errorIsDeleted': {
		de_de: 'Kommentar gelöscht',
		en_us: 'Comment deleted',
		es_es: 'Comentario eliminado',
		fr_fr: 'Commentaire supprimé',
		it_it: 'Commento eliminato',
	},
	'has_commented': {
		de_de: ' kommentierte',
		en_us: ' commented',
		es_es: ' comento',
		fr_fr: ' a commenté',
		it_it: ' ha commentato',
	},
	'created___today_at': {
		de_de: 'Heute um {{1}}',
		en_us: 'Today at {{1}}',
		es_es: 'Hoy a las {{1}}',
		fr_fr: 'Aujourd\'hui à {{1}}',
		it_it: 'Oggi alle {{1}}',
	},
	'created___datetime': {
		de_de: '{{1}} um {{2}}',
		en_us: '{{1}} at {{2}}',
		es_es: '{{1}} en {{2}}',
		fr_fr: '{{1}} à {{2}}',
		it_it: '{{1}} alle {{2}}',
	},
	'comment___is_deleted': {
		de_de: 'Kommentar gelöscht',
		en_us: 'Comment deleted',
		es_es: 'Comentario eliminado',
		fr_fr: 'Commentaire supprimé',
		it_it: 'Commento eliminato',
	},
};