import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";
import { type___geo___lngLat, type___post___attachment_type, type___post___target, type___post___visibility } from "../../types/types.types";

//	P R O P S

export interface props___MdPostsCreateModal extends interface___modal, interface___is_logged {

	post_target___type: type___post___target,
	post_target___id: string | null,
	post_target___name: string | null,

	post_attachment_type?: type___post___attachment_type,
	post_attachment_element?: any,
	
}

//	S T A T E

export interface state___MdPostsCreateModal {

	me_props: any | null,
	me_props___is_loading: boolean,

	post___share_element_type: type___post___attachment_type | null,
	post___share_element_id: string | null,

	post___text: string,
	post___visibility: type___post___visibility,
	post___coords: type___geo___lngLat | null,

	post___is_submitting: boolean,










	ionAlert___askForClose___visibility: boolean,

	ionToast___error___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdPostsCreateModal___default: state___MdPostsCreateModal = {
	
	me_props: null,
	me_props___is_loading: true,
	
	post___share_element_type: null,
	post___share_element_id: null,

	post___text: '',
	post___visibility: 'public',
	post___coords: null,

	post___is_submitting: false,















	ionAlert___askForClose___visibility: false,

	ionToast___error___isOpen: false,

}