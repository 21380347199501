import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, RefresherEventDetail, IonListHeader, IonPage, IonRefresher, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbArchiveFavoritesPage } from './sb-archive-favorites.locale';

//	S T Y L E

import './sb-archive-favorites.scss';

//	S T A T E   -   P R O P S

import { props___SbArchiveFavoritesPage, state___SbArchiveFavoritesPage, state___SbArchiveFavoritesPage___default } from './sb-archive-favorites.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComGalleryPlaceholderComponent from '../../../components/com-gallery-placeholder/com-gallery-placeholder';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';

//	C L A S S

class SbArchiveFavoritesPage extends React.Component<props___SbArchiveFavoritesPage, state___SbArchiveFavoritesPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbArchiveFavoritesPage,
	) {
		super(props);
		this.state = state___SbArchiveFavoritesPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___favorites_collection___poi = async () => {
		const ___temp___poi_collection: type___api___response = await this._API.poi___read___multi('favorites', '', this.state.favorites___collection.length);
		this.setState((prevState) => ({
			favorites___collection: [...prevState.favorites___collection, ...___temp___poi_collection.data],
			favorites___collection___is_loading: false,
			favorites___collection___is_complete: (___temp___poi_collection.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			favorites___collection: [],
			favorites___collection___is_loading: true,
		}, () => {
			___e.detail.complete();
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			favorites___collection___is_loading: true
		}, async () => {
			await this.read_props___favorites_collection___poi();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___favorites_collection___poi();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<section className="sb-archive-favorites---container">

						<ComGalleryPlaceholderComponent placeholder_path={ '/assets/ui/icons/icon-star.svg' } />

						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbArchiveFavoritesPage.title) }</IonLabel>
						</IonListHeader>

						<br />

						{this.state.favorites___collection.map((___p: any, ___p___idx: number) => {
							return <>
								<Link key={ ___p___idx } to={ '/view/poi/' + ___p['poi_id'] }>
									<ComPoiComponent poi_props={ ___p } size="normal" />
								</Link>
								<ComListSeparatorComponent/>
							</>;
						})}

						{(this.state.favorites___collection___is_complete === true) ? <>
							<ComListEmptyComponent list_items_quantity={ this.state.favorites___collection.length }/>
						</> : <>
							<ComInfiniteScrollerComponent is_loading={ this.state.favorites___collection___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
						</>}

						<br />
						<br />

					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbArchiveFavoritesPage);