import React from 'react';
import { IonActionSheet, IonAlert, IonContent, IonToast } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___App } from '../../App.locale';
import { locale___ComTaleViewInner } from './com-tale-view-inner.locale';

//	S T Y L E

import './com-tale-view-inner.scss';

//	T Y P E S

import { type___action___element___like } from '../../types/types.actions';
import { type___api___response } from '../../types/types.api-response';
import { type___message___attachment } from '../../types/types.messages';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../interfaces/interface.actionsheet';
import { interface___IonAlert } from '../../interfaces/interface.alert';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';
import { funcs_text } from '../../funcs/funcs.text';

//	S T A T E   -   P R O P S

import { props___ComTaleViewInner, state___ComTaleViewInner, state___ComTaleViewInner___default } from './com-tale-view-inner.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import Com404Component from '../com-404/com-404';
import ComSpinnerComponent from '../com-spinner/com-spinner';
import ComSpinnerTagComponent from '../com-spinner-tag/com-spinner-tag';
import ComTextRichComponent from '../com-text-rich/com-text-rich';

//	C L A S S

export default class ComTaleViewInner extends React.Component<props___ComTaleViewInner, state___ComTaleViewInner>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N F I G

	private readonly ionActionSheet___taleActions___config: interface___IonActionSheet = {
		buttons: [
			{
				text: this._LOCALE.translate(locale___ComTaleViewInner.ionActionSheet___taleActions___delete),
				role: 'destructive',
				handler: () => {
					this.setState({
						ionAlert___taleRemove___isOpen: true,
						ionActionSheet___taleActions___isOpen: false,
					});
				}
			},
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___cancel),
				role: 'cancel',
				handler: () => {
					this.setState({
						ionActionSheet___taleActions___isOpen: false,
					});
				}
			}
		]
	};

	private readonly ionAlert___taleAction___taleRemove___config: interface___IonAlert = {
		header: this._LOCALE.translate(locale___ComTaleViewInner.ionAlert___taleActionRemove___title),
		message: this._LOCALE.translate(locale___ComTaleViewInner.ionAlert___taleActionRemove___message),
		buttons: [
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___yes),
				handler: async () => {
					const ___tale___is_deleted: type___api___response = await this._API.tales___delete(this.state.tale___props['tale_id']);
					this.setState({
						ionToast___taleAction___isDeleted: (___tale___is_deleted['response'] === 'success') ? true : false,
					}, () => {
						this.props.event___onDidDismiss();
					});
				}
			},
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___cancel),
				role: 'cancel',
				handler: () => {
					this.setState({
						ionAlert___taleRemove___isOpen: false
					});
				}
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTaleViewInner,
	) {
		super(props);
		this.state = state___ComTaleViewInner___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___tale = async () => {
		const ___temp___tale_props: type___api___response = await this._API.tales___read___single(this.props.tale_id);
		this.setState({
			tale___props: ___temp___tale_props.data,
			tale___props___is_loading: false,
		}, async () => {
			if (this.state.tale___props && this.state.tale___props['tale_poi']) { await this.read_props___poi(); }
			else { this.setState({ tale_poi___props___is_loading: false }); }
		});
	}

	private read_props___poi = async () => {
		const ___temp___poi_props: type___api___response = await this._API.poi___read___single(this.state.tale___props['tale_poi']);
		this.setState({
			tale_poi___props: ___temp___poi_props.data,
			tale_poi___props___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___tale___like = (___action: type___action___element___like) => {
		if (!(this.state.tale___props['tale_user_is_me'] && this.state.tale___props['tale_user_is_me'] === true))
		{
			this.setState({
				tale_like___is_loading: true,
			}, async () => {
				const ___tale_like___temp: type___api___response = await this._API.tales_likes___toggle(this.state.tale___props['tale_id'], ___action);
				if (___tale_like___temp && ___tale_like___temp.response === 'success') {
					const ___tale_props___temp: any = this.state.tale___props;
					___tale_props___temp['tale_likes_liked'] = !(___tale_props___temp['tale_likes_liked'] as boolean);
					___tale_props___temp['tale_likes_count'] = (___tale_props___temp['tale_likes_liked'] === true) ? ___tale_props___temp['tale_likes_count'] + 1 : ___tale_props___temp['tale_likes_count'] - 1;
					this.setState({
						tale___props: ___tale_props___temp,
						tale_like___is_loading: false,
					});
				} else {
					this.setState({
						tale_like___is_loading: false,
					});
				}
			});
		}
	}

	handle___tale___message_send = () => {
		this.setState({
			tale_message___is_sending: true,
			tale_message___is_writing: false,
		}, () => {
			const ___temp___message_sent___text: string = this.state.tale_message___text;
			const ___temp___message_sent___attachment: type___message___attachment = { type: 'tale', target: this.state.tale___props['tale_id'] };
			this.setState({
				tale_message___text: '',
			}, () => {
				(async () => {
					const ___temp___message_sent: type___api___response = await this._API.chats_messages___send(this.state.tale___props['tale_user']['user_id'], ___temp___message_sent___text, ___temp___message_sent___attachment);
					this.setState({
						tale_message___is_sending: false,
						tale_message___is_writing: false,
					}, () => {
					
					
						//	check message as sent
		

					});
				})()
			});
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {
		this.setState({
			...state___ComTaleViewInner___default
		}, () => {
			this.read_props___tale();
		});
	}

	componentWillUnmount() : void {
		this.setState({
			...state___ComTaleViewInner___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<IonActionSheet isOpen={ this.state.ionActionSheet___taleActions___isOpen } {...this.ionActionSheet___taleActions___config} />
			<IonAlert isOpen={ this.state.ionAlert___taleRemove___isOpen } {...this.ionAlert___taleAction___taleRemove___config} />
		
			<IonToast isOpen={(this.state.ionToast___taleAction___isDeleted === false) ? true : false } message={ this._LOCALE.translate(locale___ComTaleViewInner.ionToast___errorDeleting) } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ ionToast___taleAction___isDeleted: null }); }}/>
			<IonToast isOpen={(this.state.ionToast___taleAction___isDeleted === true) ? true : false } message={ this._LOCALE.translate(locale___ComTaleViewInner.ionToast___isDeleted) } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___taleAction___isDeleted: null }); }}/>

			<IonContent forceOverscroll={ false }>
				<section className="com-tale-view-inner---container">
					{(this.state.tale___props___is_loading === true) ? <>
						
						<br />
						<br />
						<br />
						<br />
						<br />

						<ComSpinnerComponent size="small"/>

						<br />
						<br />
						<br />
						<br />
						<br />
					
					</> : <>

						{(this.state.tale___props !== null) ? <>
						
							<section className="com-tale-view-inner---media" onClick={() => { this.setState({ tale_message___is_writing: false }); }}>
								<img src={ this.state.tale___props['tale_media'] } />
							</section>

							<section className="com-tale-view-inner---footer">
								{(this.state.tale_message___is_writing === true) ? <>

									{(this.state.tale_message___is_sending === true ) ? <>
										
										<section className="com-tale-view-inner---footer---message-send">
											<section style={{display:'flex',justifyContent:'center',width:'100%'}}>
												<ComSpinnerTagComponent />
											</section>
										</section>
									
									</> : <>
									
										<section className="com-tale-view-inner---footer---message-send container---footer-section---text-input">
											<textarea id="field---message-body-text" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___ComTaleViewInner.reply___to_this_tale) } value={ this.state.tale_message___text } onInput={(___e: any) => { this.setState({ tale_message___text: this.funcs___text.text_input___process(___e.target.value) }); }}></textarea>
											{(this.state.tale_message___text.length > 0) ? <>
												<i className="fa-solid fa-arrow-up" onClick={ this.handle___tale___message_send }></i>
											</> : <>
												<i className="fa-solid fa-arrow-up" style={{opacity:'0.5',filter:'grayscale(1)'}}></i>
											</>}
										</section>
									
									</>}

								</> : <>

									<section className="com-tale-view-inner---footer---user">
										<Link to={ '/view/user/' + this.state.tale___props['tale_user']['user_username'] } onClick={() => { this.props.event___onDidDismiss(); }}>
											<h4>{ this.state.tale___props['tale_user']['user_username'] }</h4>
										</Link>
										{(this.state.tale___props['tale_coords_public'] && this.state.tale___props['tale_coords_public'] === true) ? <>
											{(this.state.tale_poi___props !== null) ? <>
												<h6>
													<span>{ this._LOCALE.translate(locale___ComTaleViewInner.tale___at) } </span>
													<Link to={ '/view/poi/' + this.state.tale_poi___props['poi_id'] } onClick={() => { this.props.event___onDidDismiss(); }}>
														{ this.state.tale_poi___props['poi_name'] }
													</Link>
												</h6>
											</> : <></>}
										</> : <></>}
										{(this.state.tale___props['tale_text'] && this.state.tale___props['tale_text'].length > 0) ? <>
											<ComTextRichComponent text={ this.state.tale___props['tale_text'] }/>
										</> : <></>}
										{(this.funcs___datetime.datetime___get___date(this.state.tale___props['tale_datetime']) === this.funcs___datetime.datetime___get___date(new Date())) ? <>
											<span>{ this._LOCALE.translate(locale___ComTaleViewInner.datetime_today_at, [this.funcs___datetime.datetime___get___time(this.state.tale___props['tale_datetime'])]) }</span>
										</> : <>
											<span>{ this._LOCALE.translate(locale___ComTaleViewInner.datetime_date_at, [this.funcs___datetime.datetime___get___date(this.state.tale___props['tale_datetime']), this.funcs___datetime.datetime___get___time(this.state.tale___props['tale_datetime'])]) }</span>
										</>}
									</section>
									
									<section className="com-tale-view-inner---footer---actions">
										{(this.state.tale___props['tale_likes_liked'] && this.state.tale___props['tale_likes_liked'] === true) ? <>
											<div onClick={() => { this.handle___tale___like('unlike'); }}>
												{(this.state.tale_like___is_loading === true) ? <>
													<ComSpinnerTagComponent/>
												</> : <>
													<i className="fas fa-heart" style={{color:'var(--color---folly)'}}></i>
												</>}
												<p>{ this.state.tale___props['tale_likes_count'] ?? 0 }</p>
											</div>
										</> : <>
											<div onClick={() => { this.handle___tale___like('like'); }}>
												{(this.state.tale_like___is_loading === true) ? <>
													<ComSpinnerTagComponent/>
												</> : <>
													<i className="far fa-heart"></i>
												</>}
												<p>{ this.state.tale___props['tale_likes_count'] ?? 0 }</p>
											</div>
										</>}
										{(this.state.tale___props['tale_user_is_me'] && this.state.tale___props['tale_user_is_me'] === true) ? <>
											<div onClick={() => { this.setState({ ionActionSheet___taleActions___isOpen: true }); }}>
												<i className="far fa-dot-circle"></i>
											</div>
										</> : <>
											{(this.state.tale_message___is_sending === true ) ? <>
												<div>
													<ComSpinnerTagComponent />
												</div>
											</> : <>
												<div onClick={() => { this.setState({ tale_message___is_writing: true }); }}>
													<i className="far fa-comments"></i>
												</div>
											</>}
										</>}
									</section>

								</>}
							</section>

						</> : <>

							<section className="com-tale-view-inner---404" onClick={ this.props.event___onDidDismiss }>
								<Com404Component theme="dark" />
								<i className="fas fa-times"></i>
							</section>

						</>}

					</>}
				</section>
			</IonContent>

		</>;
	}

//#endregion

}