import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdDiscoverFiltersModal: type___locale___translations___collection = {
	'topBarButton___cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},
	'showing': {
		de_de: 'Anzuzeigende Elemente',
		en_us: 'Items to Display',
		es_es: 'Artículos para mostrar',
		fr_fr: 'Éléments à afficher',
		it_it: 'Elementi da visualizzare',
	},
	'showing___poi': {
		de_de: 'Sehenswürdigkeiten (Poi)',
		en_us: 'Point of Interests (Poi)',
		es_es: 'Puntos de interés (Poi)',
		fr_fr: 'Points d\'intérêt (Poi)',
		it_it: 'Punti di interesse (Poi)',
	},
	'showing___events': {
		de_de: 'Veranstaltungen',
		en_us: 'Events',
		es_es: 'Eventos',
		fr_fr: 'Événements',
		it_it: 'Eventi',
	},
	'showing___tales': {
		de_de: 'Tales',
		en_us: 'Tales',
		es_es: 'Tales',
		fr_fr: 'Tales',
		it_it: 'Tales',
	},
	'showing___smart_ai': {
		de_de: 'Smart AI',
		en_us: 'Smart AI',
		es_es: 'Smart AI',
		fr_fr: 'Smart AI',
		it_it: 'Smart AI',
	},
	'showing___smart_ai___description': {
		de_de: 'Lassen Sie sich vom Smart View inspirieren, der Ihnen durch unsere KI nur und ausschließlich das vorschlägt, was Ihren Interessen entspricht',
		en_us: 'Let yourself be inspired by the Smart View which, through our AI, suggests only and exclusively what coincides with your interests',
		es_es: 'Déjate inspirar por Smart View que, a través de nuestra IA, te sugiere única y exclusivamente lo que coincide con tus intereses.',
		fr_fr: 'Laissez-vous inspirer par le Smart View qui, grâce à notre IA, vous suggère uniquement et exclusivement ce qui coïncide avec vos intérêts',
		it_it: 'Lasciati ispirare dalla Visualizzazione Smart che tramite la nostra AI ti suggerisce solo ed esclusivamente ciò che coincide con i tuoi interessi',
	},
	'showing___smart_ai___selectFilters': {
		de_de: 'Bearbeite deine Interessen',
		en_us: 'Edit your Interests',
		es_es: 'Edita tus Intereses',
		fr_fr: 'Modifiez vos Intérêts',
		it_it: 'Modifica I tuoi Interessi',
	},
	'map_style': {
		de_de: 'Kartenstil',
		en_us: 'Map Style',
		es_es: 'Estilo de mapa',
		fr_fr: 'Style de carte',
		it_it: 'Stile della mappa',
	},
	'map_style___normal': {
		de_de: 'standard',
		en_us: 'standard',
		es_es: 'estándar',
		fr_fr: 'standard',
		it_it: 'standard',
	},
	'map_style___satellite': {
		de_de: 'satellit',
		en_us: 'satellite',
		es_es: 'satélite',
		fr_fr: 'satellite',
		it_it: 'satellite',
	},
	'map_style___dark': {
		de_de: 'dark',
		en_us: 'dark',
		es_es: 'dark',
		fr_fr: 'dark',
		it_it: 'dark',
	},
	'button___save': {
		de_de: 'Wenden Sie Filter an',
		en_us: 'Apply Filters',
		es_es: 'Aplicar filtros',
		fr_fr: 'Appliquer des filtres',
		it_it: 'Applica filtri',
	}
};