import React from 'react'
import { debounce } from 'lodash';

//	L O C A L I Z A T I O N

import { locale___ComInputTagsComponent } from './com-input-tags.locale';

//	S T Y L E

import './com-input-tags.scss'

//	S T A T E S   -   P R O P S

import { props___ComInputTagsComponent, state___ComInputTagsComponent, state___ComInputTagsComponent___default } from './com-input-tags.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	M O D A L S

import ComSpinnerTagComponent from '../com-spinner-tag/com-spinner-tag';

//	C L A S S

export default class ComInputTagsComponent extends React.Component<props___ComInputTagsComponent, state___ComInputTagsComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComInputTagsComponent,
	) {
	
		super(props);
		this.state = state___ComInputTagsComponent___default;

		this.constructor___debounce();

	}

	constructor___debounce() : void {
		this.handle___search_users___input = this.handle___search_users___input.bind(this);
		this.handle___search_users = debounce(this.handle___search_users.bind(this), lib_names.lodash.debounce_delay);
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___tags = async () => {
		const ___temp___username_input: string = this.props.text.replace('@', '');
		const ___temp___follows_list: type___api___response = await this._API.search___full('users_follows', ___temp___username_input, 0);
		this.setState({
			usernames___collection: ___temp___follows_list.data,
			usernames___collection___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___search_users___input = () => {
		this.setState({
			search_param: this.props.text,
			usernames___collection___is_loading: true,
			usernames___collection: [],
		}, () => {
			this.handle___search_users(this.state.search_param);
		});
	}

	private handle___search_users = (___search_param: string) => {
		this.setState({
			search_param: ___search_param,
			usernames___collection: [],
			usernames___collection___is_loading: true,
		}, async () => {
			await this.read_props___tags();
		});
	}

	private handle___select_user_tag = (___tag_user: any) => {
		const ___tag_user___username: string = '@' + ___tag_user['user_username'];
		this.props.event___onTagSelect(___tag_user___username);
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void> {
		await this.read_props___tags();
	}

	async componentDidUpdate(prevProps: props___ComInputTagsComponent, prevState: state___ComInputTagsComponent) : Promise<void> {
		if (prevProps.text !== this.props.text) {
			await this.read_props___tags();
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.props.text && this.props.text.length > 0) ? <>
				<div className="com-input-tags---container">
					{(this.state.usernames___collection___is_loading === true) ? <>
						<ComSpinnerTagComponent />
						<span>{ this._LOCALE.translate(locale___ComInputTagsComponent.tags___suggestions) }</span>
					</> : <>
						{(this.state.usernames___collection.length > 0) ? <>
							{this.state.usernames___collection.map((___tu: any, ___tu___idx: number)=> {
								return <>
									<div key={ ___tu___idx } onClick={() => { this.handle___select_user_tag(___tu); }}>
										<span>{ '@' + ___tu['user_username'] }</span>
									</div>
								</>;
							})}
						</> : <>
							<span>{ this._LOCALE.translate(locale___ComInputTagsComponent.tags___nobody) }</span>
						</>}
					</>}
				</div>
			</> : <></>}
		</>;
	}

//#endregion

}