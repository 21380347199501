import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbArchivePoiPage: type___locale___translations___collection = {
	'title': {
		de_de: 'Deine Punkte von Interesse',
		en_us: 'Your Points of Interest',
		es_es: 'Tus Puntos de Interés',
		fr_fr: 'Vos Points d\'Intérêt',
		it_it: 'I tuoi Punti di Interesse',
	},
};