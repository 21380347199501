import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N S

import { locale___App } from '../../../App.locale';
import { locale___SbElementInvite } from './sb-element-invite.locale';

//	S T Y L E

import './sb-element-invite.scss';

//	S T A T E   -   P R O P S

import { props___SbElementInvitePage, state___SbElementInvitePage, state___SbElementInvitePage___default } from './sb-element-invite.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	F U N C S

import { funcs_text } from '../../../funcs/funcs.text';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComEventComponent from '../../../components/com-event/com-event';
import ComEventTimeScheduleComponent from '../../../components/com-event-timeschedule/com-event-timeschedule';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';

//	C L A S S

class SbElementInvitePage extends React.Component<props___SbElementInvitePage, state___SbElementInvitePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbElementInvitePage,
	) {
		super(props);
		this.state = state___SbElementInvitePage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___invite = async () => {
		const ___temp___invite__props: type___api___response = await this._API.events_invites___read___single(this.state.invite___id);
		this.setState({
			invite___props: ___temp___invite__props.data,
			invite___props___is_loading: false,
		}, async () => {
			if (this.state.invite___props !== null) {
				await this.read_props___invite_event();
			} else {
				this.setState({
					invite___event___props___is_loading: false,
				});
			}
		});
	}

	private read_props___invite_event = async () => {
		const ___temp___invite_event___props: type___api___response = await this._API.events___read___single(this.state.invite___props['invite_event']['event_id']);
		this.setState({
			invite___event___props: ___temp___invite_event___props.data,
			invite___event___props___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			
			







		}, async () => {
			await this.read_props___invite();
			___e.detail.complete();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___invite_id: string | null = this._ROUTER.get_id(this.props);
		if (___invite_id) {
			this.setState({
				invite___id: ___invite_id,
			}, async () => {
				await this.read_props___invite();
			});
		}
	}

	ionViewWillLeave() : void {
		this.setState({
			...state___SbElementInvitePage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefresherComponent/>
					</IonRefresher>

					{(this.state.invite___props___is_loading === true || this.state.invite___event___props___is_loading === true) ? <>

						<ComSpinnerInlineComponent/>

					</> : <>
						{(this.state.invite___props !== null && this.state.invite___event___props !== null) ? <>
							<div className="sb-page---container sb-element-invite---container">

								<i className="far fa-envelope-open"></i>

								<h6>{ this._LOCALE.translate(locale___SbElementInvite.invite_received) }</h6>
								{(this.state.invite___props['invite_sender'] !== null) ? <>
									<h5>
										<span>{ this._LOCALE.translate(locale___SbElementInvite.who_invited) } </span>
										<Link to={ '/view/user/' + this.state.invite___props['invite_sender']['user_username'] }>{ this.state.invite___props['invite_sender']['user_username'] }</Link>
									</h5>
								</> : <></>}

								<br />
								<br />
								<br />

								<ComEventTimeScheduleComponent event_props={ this.state.invite___event___props }/>

								<br />

								<Link to={ '/view/events/' + this.state.invite___event___props['event_id'] }>
									<ComEventComponent event_props={ this.state.invite___event___props } size="normal" />
								</Link>

								<br />

								<IonCard>
									<IonList>
										<IonItem className="is-ion-item-button" button={ true } routerLink={ '/view/events/' + this.state.invite___event___props['event_id'] } routerDirection="forward">
											<i className="far fa-calendar-check"></i>
											<IonLabel><b>{ this._LOCALE.translate(locale___SbElementInvite.go_to_event) }</b></IonLabel>
										</IonItem>
									</IonList>
								</IonCard>

							</div>
						</> : <>
							<Com404Component/>
						</>}
					</>}

				</IonContent>

				{/**

				{(this.state.post___props !== null) ? <>
					<IonFooter>
						<section className="container---footer-section---text-input">
							{(this.state.field___new_comment___is_submitting === true) ? <>
								<ComSpinnerInlineComponent />
							</> : <>
								<textarea id="field---poi-description" className="input---nb-outlined" placeholder="Scrivi un commento..." value={ this.state.field___new_comment___text } onInput={(___e: any) => { this.setState({ field___new_comment___text: this.funcs___text.text_input___process(___e.target.value) }); }}></textarea>
								{(this.state.field___new_comment___text.length > 0) ? <>
									<i className="fa-solid fa-check" onClick={() => { this.handle___post_comment___submit(); }}></i>
								</> : <>
									<i className="fa-solid fa-check" style={{opacity:'0.5',filter:'grayscale(1)'}}></i>
								</>}
							</>}
						</section>
					</IonFooter>
				</> : <></>}

				*/}

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbElementInvitePage);