import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbSettingsAuthenticationPage: type___locale___translations___collection = {
	'ionToast___success': {
		de_de: 'Passwort erfolgreich geändert',
		en_us: 'Password successfully changed',
		es_es: 'Contraseña cambiada con éxito',
		fr_fr: 'Mot de passe changé avec succès',
		it_it: 'Password cambiata con successo',
	},
	'ionToast___error___error_generic': {
		de_de: 'Passwort konnte nicht geändert werden, allgemeiner Fehler',
		en_us: 'Unable to change password, generic error',
		es_es: 'No se pudo cambiar la contraseña, error genérico',
		fr_fr: 'Impossible de changer le mot de passe, erreur générale',
		it_it: 'Impossibile cambiare la password, errore generico',
	},
	'ionToast___error___error_new_not_match': {
		de_de: 'Die neuen Passwörter stimmen nicht überein',
		en_us: 'The new passwords do not match',
		es_es: 'Las nuevas contraseñas no coinciden',
		fr_fr: 'Les nouveaux mots de passe ne correspondent pas',
		it_it: 'Le nuove password non combaciano',
	},
	'ionToast___error___error_old_wrong': {
		de_de: 'Das alte Passwort wurde falsch eingegeben',
		en_us: 'You entered the old password incorrectly',
		es_es: 'Has introducido mal la contraseña antigua',
		fr_fr: 'Vous avez mal saisi l\'ancien mot de passe',
		it_it: 'Hai inserito la vecchia password in modo errato',
	},
	'title': {
		de_de: 'Passwort ändern',
		en_us: 'Change Password',
		es_es: 'Cambiar Contraseña',
		fr_fr: 'Changer le mot de passe',
		it_it: 'Cambia Password',
	},
	'old___placeholder': {
		de_de: 'Altes Passwort',
		en_us: 'Old Password',
		es_es: 'Contraseña antigua',
		fr_fr: 'Ancien mot de passe',
		it_it: 'Vecchia Password',
	},
	'new___placeholder': {
		de_de: 'Neues Passwort',
		en_us: 'New Password',
		es_es: 'Nueva Contraseña',
		fr_fr: 'Nouveau mot de passe',
		it_it: 'Nuova Password',
	},
	'new_x2___placeholder': {
		de_de: 'Neues Passwort wiederholen',
		en_us: 'Repeat New Password',
		es_es: 'Repetir Nueva Contraseña',
		fr_fr: 'Répéter le nouveau mot de passe',
		it_it: 'Ripeti Nuova Password',
	},
	'validation___number': {
		de_de: 'Mindestens eine Zahl 1-9',
		en_us: 'At least one number 1-9',
		es_es: 'Al menos un número 1-9',
		fr_fr: 'Au moins un chiffre 1-9',
		it_it: 'Almeno un numero 1-9',
	},
	'validation___lowercase': {
		de_de: 'Mindestens ein Kleinbuchstabe a-z',
		en_us: 'At least one lowercase letter a-z',
		es_es: 'Al menos una letra minúscula a-z',
		fr_fr: 'Au moins une lettre minuscule a-z',
		it_it: 'Almeno una lettera minuscola a-z',
	},
	'validation___uppercase': {
		de_de: 'Mindestens ein Großbuchstabe A-Z',
		en_us: 'At least one uppercase letter A-Z',
		es_es: 'Al menos una letra mayúscula A-Z',
		fr_fr: 'Au moins une lettre majuscule A-Z',
		it_it: 'Almeno una lettera maiuscola A-Z',
	},
	'validation___symbols': {
		de_de: 'Mindestens ein Symbol !@#$%^&*(),.?":{}|<>',
		en_us: 'At least one symbol !@#$%^&*(),.?":{}|<>',
		es_es: 'Al menos un símbolo !@#$%^&*(),.?":{}|<>',
		fr_fr: 'Au moins un symbole !@#$%^&*(),.?":{}|<>',
		it_it: 'Almeno un simbolo !@#$%^&*(),.?":{}|<>',
	},
	'validation___length': {
		de_de: 'Zwischen 3 und 16 Zeichen lang',
		en_us: 'Length between 3 and 16 characters',
		es_es: 'Longitud entre 3 y 16 caracteres',
		fr_fr: 'Longueur comprise entre 3 et 16 caractères',
		it_it: 'Una lunghezza compresa tra i 3 ed i 16 caratteri',
	},
	'validation___match_new_old': {
		de_de: 'Die neuen Passwörter müssen übereinstimmen',
		en_us: 'The new passwords must match',
		es_es: 'Las nuevas contraseñas deben coincidir',
		fr_fr: 'Les nouveaux mots de passe doivent correspondre',
		it_it: 'Le password nuove devono combaciare',
	},
	'button___save': {
		de_de: 'Speichern',
		en_us: 'Save',
		es_es: 'Guardar',
		fr_fr: 'Sauvegarder',
		it_it: 'Salva',
	},
	'button___disabled': {
		de_de: 'Erfüllen Sie die Passwortanforderungen',
		en_us: 'Meet the password requirements',
		es_es: 'Cumple con los requisitos de la contraseña',
		fr_fr: 'Respectez les exigences du mot de passe',
		it_it: 'Rispetta i requisiti della password',
	}
};