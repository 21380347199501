import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToast, IonToggle, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../../App.locale';
import { locale___SbSettingsPrivacyPage } from './sb-settings-privacy.locale';

//	S T Y L E

import './sb-settings-privacy.scss';

//	S T A T E   -   P R O P S

import { state___SbSettingsPrivacyPage, state___SbSettingsPrivacyPage___default } from './sb-settings-privacy.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___user_setting___property } from '../../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

class SbSettingsPrivacyPage extends React.Component<{}, state___SbSettingsPrivacyPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___SbSettingsPrivacyPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user = async () => {
		const ___temp___user___props: type___api___response = await this._API.users___read___single('me', null);
		if (!___temp___user___props || ___temp___user___props.response !== 'success' || ___temp___user___props.data === null) { console.log('404'); }
		this.setState({
			user___props: ___temp___user___props.data,
		}, () => {
			this.setState({
				user_privacy___profile_public: this.state.user___props['user_profile_public'],
				user_privacy___contactable: this.state.user___props['user_profile_contactable'],
				user_privacy___exclude_from_search: this.state.user___props['user_privacy___exclude_from_search'],
				user_privacy___only_close_friends: this.state.user___props['user_privacy___only_close_friends'],
				user___props___is_loading: false,
			});
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___user_privacy___update = async () => {
		this.setState({
			user___props___is_loading: true,
		}, async () => {
			const ___user_values__to_update: type___user_setting___property[] = [
				{ key: 'user_profile_public', value: this.state.user_privacy___profile_public },
				{ key: 'user_profile_contactable', value: this.state.user_privacy___contactable },
				{ key: 'user_privacy___exclude_from_search', value: this.state.user_privacy___exclude_from_search },
				{ key: 'user_privacy___only_close_friends', value: this.state.user_privacy___only_close_friends },
			];
			const ___user___is_submitted: type___api___response = await this._API.users_settings___update(___user_values__to_update);
			this.setState({
				ionToast___privacy_is_submitted___result: (___user___is_submitted && ___user___is_submitted.response === 'success') ? 'success' : 'error',
			}, async () => {
				await this.read_props___user();
			});
		})
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewWillEnter() : Promise<void> {
		await this.read_props___user();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonToast isOpen={ this.state.ionToast___privacy_is_submitted___result === 'success' } message={ this._LOCALE.translate(locale___SbSettingsPrivacyPage.ionToast___privacy_is_submitted___saved) } duration={ 3000 } color='success' position='top' onDidDismiss={() => { this.setState({ ionToast___privacy_is_submitted___result: null }); }}/>
				<IonToast isOpen={ this.state.ionToast___privacy_is_submitted___result === 'error' } message={ this._LOCALE.translate(locale___SbSettingsPrivacyPage.ionToast___privacy_is_submitted___error) } duration={ 3000 } color='danger' position='top' onDidDismiss={() => { this.setState({ ionToast___privacy_is_submitted___result: null }); }}/>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.user___props___is_loading === true) ? <>
						
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />

						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonListHeader>
							<IonLabel>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.title) }</IonLabel>
						</IonListHeader>

						<div className="sb-settings-privacy---container">
							
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<i className="fa-solid fa-ghost"></i>
										<IonToggle checked={ this.state.user_privacy___profile_public } onIonChange={(___e: any) => { this.setState({ user_privacy___profile_public: ___e.detail.checked }, () => { this.handle___user_privacy___update(); }); }}>
											<>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___profile_public) }</>
										</IonToggle>
									</IonItem>
									<p>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___profile_public___description) }</p>
								</IonList>
							</IonCard>

							<br />

							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<i className="fas fa-search-minus"></i>
										<IonToggle checked={ this.state.user_privacy___exclude_from_search } onIonChange={(___e: any) => { this.setState({ user_privacy___exclude_from_search: ___e.detail.checked }, () => { this.handle___user_privacy___update(); }); }}>
											<>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___exclude_from_search) }</>
										</IonToggle>
									</IonItem>
									<p>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___exclude_from_search___description) }</p>
								</IonList>
							</IonCard>

							<br />

							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<i className="fas fa-user-friends"></i>
										<IonToggle checked={ this.state.user_privacy___only_close_friends } onIonChange={(___e: any) => { this.setState({ user_privacy___only_close_friends: ___e.detail.checked }, () => { this.handle___user_privacy___update(); }); }}>
											<>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___only_close_friends) }</>
										</IonToggle>
									</IonItem>
									<p>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___only_close_friends___description) }</p>
								</IonList>
							</IonCard>

						</div>

						<br />

						<IonListHeader>
							<IonLabel>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.messages) }</IonLabel>
						</IonListHeader>

						<div className="sb-settings-privacy---container">
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<i className="fa-solid fa-comments"></i>
										<IonToggle checked={ this.state.user_privacy___contactable } onIonChange={(___e: any) => { this.setState({ user_privacy___contactable: ___e.detail.checked }, () => { this.handle___user_privacy___update(); }); }}>
											<>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___contactable) }</>
										</IonToggle>
									</IonItem>
									<p>{ this._LOCALE.translate(locale___SbSettingsPrivacyPage.user_privacy___contactable___description) }</p>
								</IonList>
							</IonCard>
						</div>
					
					</>}

				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsPrivacyPage);