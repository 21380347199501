import { interface___Cropper } from "../interfaces/interface.cropper";

/* 
 * 
 * 
 * 
 * 								L I B   N A M E S
 * 
 * 
 * 
 */

export const lib_cropper: interface___Cropper = {
		autoCropArea: 1,
		background: false,
		crossOrigin: 'anonymous',
		center: false,
		cropBoxMovable: false,
		cropBoxResizable: false,
		dragMode: 'move',
		guides: false,
		highlight: false,
		modal: true,
		movable: true,
		restore: false,
		rotatable: true,
		scalable: true,
		toggleDragModeOnDblclick: false,
		viewMode: 0,
		style: { height: 'auto', width: '100%' },
}