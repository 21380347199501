import React from 'react'
import { IonContent, IonPopover } from '@ionic/react';

//	L O C A L E

import { locale___ComInputUsername } from './com-input-username.locale';

//	S T Y L E

import './com-input-username.scss'

//	S T A T E S   -   P R O P S

import { props___ComInputUsername, state___ComInputUsername, state___ComInputUsername___default } from './com-input-username.state';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComInputUsername extends React.Component<props___ComInputUsername, state___ComInputUsername>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComInputUsername,
	) {
		super(props);
		this.state = state___ComInputUsername___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___write = (___input: string) => {
		const ___input___trimmed: string = ___input.toLowerCase().trim();
		const ___output___is_valid: boolean = (___input___trimmed.length >= 3 && ___input___trimmed.length <= 16 && ___input___trimmed.match(lib_names_shared.regex.credential___username)) ? true : false;
		this.setState({ field___is_valid: ___output___is_valid });
		this.props.event___onInput(___input___trimmed);
		this.props.event___onInputIsValid(___output___is_valid);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-input-username---container">
				<input type="text" placeholder={ this._LOCALE.translate(locale___ComInputUsername.placeholder) } className="input---nb-outlined" value={ this.props.text } onInput={(___e: any) =>{ this.handle___write(___e.target.value); }} />
				<div id="com-input-username---popup-trigger">
					{(this.state.field___is_valid === false) ? <>
						<i className="far fa-times-circle" style={{color:'var(--color---reddy)'}}></i>
					</> : <>
						<i className="far fa-question-circle"></i>
					</>}
				</div>
				<IonPopover className="com-input-username---popup" trigger="com-input-username---popup-trigger" triggerAction="click">
					<IonContent forceOverscroll={ false }>
						<section>
							<p>{ this._LOCALE.translate(locale___ComInputUsername.info) }</p>
						</section>
					</IonContent>
				</IonPopover>
			</div>
		</>;
	}

//#endregion

}