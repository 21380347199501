/* 
 * 
 * 
 * 
 * 								L I B   N A M E S
 * 
 * 
 * 
 */

export const lib_names = {
	aspectRatios: {
		catalog_item: { 'ar': 1 / 1, 'w': 1, 'h': 1 },
		event_poster: { 'ar': 1 / 1.414, 'w': 1, 'h': 1.414 },
		poi: { 'ar': 11 / 16, 'w': 11, 'h': 16 },
		tales: { 'ar': 9 / 16, 'w': 9, 'h': 16 },
		user___cover: { 'ar': 16 / 9, 'w': 16, 'h': 9 },
		user___profile: { 'ar': 1 / 1, 'w': 1, 'h': 1 },
	},
	cacheStorage: {
		cache___api: 'c551beba89b456bc6564a0d4b1d6a1e5242cf83b',
	},
	localStorage: {
		user___app_download: '5eb8909cee65cf9df9f3cd4d6c6ac9806feb68f9',
		user___app_reviewed: '5182fba91d879260a4826d97b9b3c124b126b79b',
		user___changelog_view: '5ce31efd6d4767550addcf2fbd26dc265c8bb085',
		user___cookies_accepted: '7e9381a3d73bae265235aa3068c3f2604ecacd3e',
		user___interests___events: 'd04e9fc0e29e4c0f9890db2c5ef441aa19d2ff9b',
		user___interests___poi: 'cc410f322896c93fe4ddd13661a6d812dbfc6c7a',
		user___language: '9d9276431fa569a2d17f51a9a797191ac60cede4',
		user___location: 'fa25fa1a1acecb305decb0262f56ca9a3b852e20',
		user___logintoken: 'e435ea36bde2346fb69a6bc57c5f7699e4d54bcf',
		user___map___filters: '9a6d3f1257587a735596d18b993b91c1fb657228',
		user___notification_token: '552a9fb313ab4bc7aee9ed03d67f18a383e3ec86'
	},
	lodash: {
		debounce_delay: 500,
	},
	mapboxgl: {
		accessToken: 'pk.eyJ1Ijoicmlrb3p6IiwiYSI6ImNsazAzeTVzZjBnZ2ozZG1sOXNkbmdpMHMifQ.YEm8hzqY7Q0WBewrRtl6ig',
		accessToken_geocoding: 'pk.eyJ1Ijoicmlrb3p6IiwiYSI6ImNsazAzeTVzZjBnZ2ozZG1sOXNkbmdpMHMifQ.YEm8hzqY7Q0WBewrRtl6ig',
		attributionControl: false,
		doubleClickZoom: false,
		dragRotate: false,
		markerColor: '#FD4755',
		mapbox_fly_options: {
			speed: 2,
			curve: 1,
			essential: true
		},
		maxPitch: 0,
		maxZoom: 20,
		minZoom: 11.5,
		minZoomToDiscover: 7.5,
		style_dark: 'mapbox://styles/mapbox/dark-v11',
		style_normal: 'mapbox://styles/rikozz/clk0414x700a201pfdf0z70gh',
		style_satellite: 'mapbox://styles/rikozz/clngobzt9001p01nuacx5dv1v',
		touchPitch: false,
		zoom: 11.5,
		zoomPreview: 15.5,
	},
	mongo: {
		query_limit: 15,
		query_limit_extended: 500,
		query_limit_few: 3,
		query_limit_single: 1,
	},
	share_urls: {
		events: '/view/event',
		poi: '/view/poi'
	}
}