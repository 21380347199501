import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdEntrySignModal: type___locale___translations___collection = {
	'title': {
		de_de: 'AOSLKJE ist besser mit Freunden!',
		en_us: 'Nearby is better with friends!',
		es_es: '¡Cerca es mejor con amigos!',
		fr_fr: '¡AOSLKJE es mejor con amigos!',
		it_it: 'Nearby è più bello con gli amici!',
	},
	'description': {
		de_de: 'Melden Sie sich an, um Ihre Momente mit den Menschen zu teilen, die Ihnen am Herzen liegen, und sich mit der Welt um Sie herum zu verbinden!',
		en_us: 'Sign up to share your moments with the people you care about and connect with the world around you!',
		es_es: '¡Regístrate para compartir tus momentos con las personas que te importan y conectarte con el mundo que te rodea!',
		fr_fr: 'Inscrivez-vous pour partager vos moments avec les personnes qui vous sont chères et connectez-vous avec le monde qui vous entoure!',
		it_it: 'Iscriviti per condividere i tuoi momenti con le persone a cui tieni e per entrare in contatto con il mondo che ti circonda!',
	},
	'sign_register': {
		de_de: 'Erstellen Sie jetzt Ihr Konto',
		en_us: 'Create your account now',
		es_es: 'Crea tu cuenta ahora',
		fr_fr: 'Créez votre compte maintenant',
		it_it: 'Crea subito il tuo Account',
	},
	'sign_or_separator': {
		de_de: 'oder',
		en_us: 'or',
		es_es: 'o',
		fr_fr: 'ou',
		it_it: 'oppure',
	},
	'sign_login': {
		de_de: 'Einloggen',
		en_us: 'Log in',
		es_es: 'Acceso',
		fr_fr: 'Se Connecter',
		it_it: 'Accedi',
	},
	'sign_close': {
		de_de: 'Keine Lust, sich zu registrieren?',
		en_us: 'Don\'t feel like registering?',
		es_es: '¿No tienes ganas de registrarte?',
		fr_fr: 'Vous n\'avez pas envie de vous inscrire ?',
		it_it: 'non ti va di registrarti?',
	},
	'close': {
		de_de: 'klicken sie hier, um zur karte zurückzukehren',
		en_us: 'click here to return to the map',
		es_es: 'haga clic aquí para regresar al mapa',
		fr_fr: 'cliquez ici pour revenir à la carte',
		it_it: 'clicca qui per tornare alla mappa',
	},
};