import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdPoiSelectCategoryModal: type___locale___translations___collection = {
	'title': {
		de_de: 'POI-Kategorie auswählen',
		en_us: 'Select POI Category',
		es_es: 'Seleccionar categoría de POI',
		fr_fr: 'Sélectionner la catégorie POI',
		it_it: 'Seleziona Categoria POI',
	},
	'back_to': {
		de_de: '______________',
		en_us: 'back to {{1}}',
		es_es: '______________',
		fr_fr: '______________',
		it_it: 'torna a {{1}}',

	}
};