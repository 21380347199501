import React from 'react'

//	S T Y L E

import './com-password-safety.scss'

//	S T A T E S   -   P R O P S

import { props___ComPasswordSafetyComponent, state___ComPasswordSafetyComponent, state___ComPasswordSafetyComponent___default } from './com-password-safety.state';

//	C L A S S

export default class ComPasswordSafetyComponent extends React.Component<props___ComPasswordSafetyComponent, state___ComPasswordSafetyComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComPasswordSafetyComponent,
	) {
		super(props);
		this.state = state___ComPasswordSafetyComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___calculate_password_strenght() : void {
		
		console.log('___password___strength', this.props.password)

		let ___password___strength = 0;

		if (this.props.password.length > 5) ___password___strength += 1;
		if (this.props.password.length > 8) ___password___strength += 1;
		if (/[A-Z]/.test(this.props.password)) ___password___strength += 1;
		if (/[0-9]/.test(this.props.password)) ___password___strength += 1;
		if (/[\W]/.test(this.props.password)) ___password___strength += 1;

		this.setState({
			password_strength: ___password___strength,
		});

	}

//#endregion

//#region 																							P R O G R E S S   B A R

	private progressBar___get___color() : string {
		const ___strenght: number = this.state.password_strength;
		switch (___strenght)
		{
			case 0: return '#607D8B'; break;
			case 1: return '#B71C1C'; break;
			case 2: return '#E65100'; break;
			case 3: return '#FBC02D'; break;
			case 4: return '#AFB42B'; break;
			case 5: return '#388E3C'; break;
		}
	}

	private progressBar___get___width() : string {
		const ___strenght: number = this.state.password_strength;
		return ((___strenght / 5) * 100).toString() + '%';
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidUpdate(prevProps: props___ComPasswordSafetyComponent, prevState: state___ComPasswordSafetyComponent) : void
	{
		if (this.props !== prevProps) {
			console.log('12e12')
			this.handle___calculate_password_strenght();
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-password-safety-bar---container">
				<div style={{ width: this.progressBar___get___width(), backgroundColor: this.progressBar___get___color() }}></div>
			</div>
		</>;
	}

//#endregion

}