import { interface___is_logged } from "../../interfaces/interface.logged";
import { type___post___attachment_type } from "../../types/types.types";

//	P R O P S

export interface props___ComInputUsername {

	text: string,
	
	event___onInput: (_: string) => void,
	event___onInputIsValid: (_: boolean) => void,

}

//	S T A T E

export interface state___ComInputUsername {

	field___is_valid: boolean | null,

}

//	S T A T E   D E F A U L T

export const state___ComInputUsername___default: state___ComInputUsername = {

	field___is_valid: null,

}