import React from 'react'

//	L O C A L E

import { locale___ComChatMessageViewedComponent } from './com-chat-message-viewed.locale';

//	S T Y L E

import './com-chat-message-viewed.scss'

//	S T A T E   P R O P S

import { props___ComChatMessageViewedComponent } from './com-chat-message-viewed.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComChatMessageViewedComponent extends React.Component<props___ComChatMessageViewedComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-chat-message-viewed---container">
				<p>{ this._LOCALE.translate(locale___ComChatMessageViewedComponent.viewed___datetime, [this.funcs___datetime.datetime___get___date(this.props.message_props['message_read_datetime']), this.funcs___datetime.datetime___get___time(this.props.message_props['message_read_datetime'])]) }</p>
			</div>
		</>;
	}

//#endregion

}