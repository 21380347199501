import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-catalog-carshub-vehicle-view.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	F U N C S

import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___SbCatalogCarsHubVehicleViewPage, state___SbCatalogCarsHubVehicleViewPage, state___SbCatalogCarsHubVehicleViewPage___default } from './sb-catalog-carshub-vehicle-view.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___catalog___item } from '../../../types/types.catalog';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComGallerySliderComponent from '../../../components/com-gallery-slider/com-gallery-slider';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComTextComponent from '../../../components/com-text/com-text';

//	M O D A L S

import MdMediaViewModal from '../../../modals/md-media-view/md-media-view';

//	C L A S S

class SbCatalogCarsHubVehicleViewPage extends React.Component<props___SbCatalogCarsHubVehicleViewPage, state___SbCatalogCarsHubVehicleViewPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbCatalogCarsHubVehicleViewPage,
	) {
		super(props);
		this.state = state___SbCatalogCarsHubVehicleViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___vehicle = async () => {
		const ___temp___vehicle___props: type___api___response = await this._API.catalog_carshub___vehicles___read___single(this.state.vehicle___id);
		
			console.log('___temp___vehicle___props',___temp___vehicle___props)
		
		
		this.setState({
			vehicle___props: ___temp___vehicle___props.data as type___catalog___item,
			vehicle___props___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___vehicle();
		___e.detail.complete();
	}

	private handle___media_preview___show = (___media_src: string) => {
		this.setState({
			ionModal___MdMediaViewModal___argument: ___media_src,
			ionModal___MdMediaViewModal___visible: true,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___vehicle_id: string | null = this._ROUTER.get_id(this.props);
		if (___vehicle_id) {

			console.log('___vehicle_id',___vehicle_id)

			this.setState({
				vehicle___id: ___vehicle_id,
			}, async () => {
				await this.read_props___vehicle();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdMediaViewModal isOpen={ this.state.ionModal___MdMediaViewModal___visible } media_src={ this.state.ionModal___MdMediaViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdMediaViewModal___visible: false, ionModal___MdMediaViewModal___argument: '' }); }} />

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					{(this.state.vehicle___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
				
					</> : <>

					{/**



						item_id: '$_id',
						item_name: '$vehicle_name',
						item_gallery: { $map: { input: '$vehicle_gallery_pictures', as: 'string', in: { $concat: [ 'https://pub-0d0b8b25ea914ae8a2928abe81b69ed9.r2.dev/', '$$string']}}},
						item_gallery_fallback: 'https://pub-0d0b8b25ea914ae8a2928abe81b69ed9.r2.dev/placeholder-vehicle-generic.png',
						item_attributes: {
							category: 'car',
							km: '$vehicle_conditions___km',
							registration: { $concat: ['$vehicle_conditions___registration_month', '/', '$vehicle_conditions___registration_year']},
							power: '$vehicle_specs___engine_power_kw',
						},
						item_description: '$vehicle_info___description',
						item_price: '$vehicle_accounting___price_sell',
						item_currency: 'eur',
						item_created_datetime: '$createdAt',



					 */}

						<br />

						<div className="sb-catalog-carshub-vehicle-view---image">
							{(this.state.vehicle___props['item_gallery'] && this.state.vehicle___props['item_gallery'].length > 0) ? <>
								<ComGallerySliderComponent gallery___images={ this.state.vehicle___props['item_gallery'] } image___aspect_ratio={ lib_names.aspectRatios.catalog_item.ar }/>
							</> : <>
								<section>
									<img src={ this.state.vehicle___props['item_gallery_fallback'] } />
								</section>
							</>}
						</div>

						<div className="sb-page---container sb-catalog-carshub-vehicle-view---component">

							<h3>{ this.state.vehicle___props['item_name'] }</h3>
							<h4>{ this.funcs___numbers.format___price(this.state.vehicle___props['item_price'], '€') }</h4>

							<br />

							{(this.state.vehicle___props['item_description'] && this.state.vehicle___props['item_description'].length > 0) ? <>
								<ComTextComponent text={ this.state.vehicle___props['item_description'] }/>
								<br />
							</> : <></>}

						</div>
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T 

export default withIonLifeCycle(SbCatalogCarsHubVehicleViewPage);