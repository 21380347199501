import React from 'react';
import { IonContent, IonModal } from '@ionic/react';

//	S T Y L E

import './md-tales-view.scss';

//	T Y P E S

import { type___modal___config } from '../../types/types.modals';

//	S T A T E   -   P R O P S

import { props___MdTalesViewModal } from './md-tales-view.state';

//	C O M P O N E N T S

import ComTaleViewInner from '../../components/com-tale-view-inner/com-tale-view-inner';

//	C L A S S

export default class MdTalesViewModal extends React.Component<props___MdTalesViewModal, {}>
{

//#region 																							R E T U R N

	private readonly MdTalesViewModal___modal_config: type___modal___config = {
		backdropDismiss: false,
		handle: false,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
	};

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdTalesViewModal___modal_config }>
				<IonContent forceOverscroll={ false } scrollX={ false } scrollY={ false }>
					<section className="md-tale-view---container">
						<section className="md-tale-view---container---topbar">
							<i className="fas fa-times" onClick={ this.props.event___onDidDismiss }></i>
						</section>
						<ComTaleViewInner tale_id={ this.props.tale_id } event___onDidDismiss={ this.props.event___onDidDismiss }/>
					</section>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}