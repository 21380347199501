import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-settings-datas.scss';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C L A S S

class SbSettingsPrivacyPage extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S
			
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<br />

					<IonListHeader>
						<IonLabel>Gestisci i tuoi dati</IonLabel>
					</IonListHeader>
					
					<br />

					<div className="sb-settings-datas---container">
						
						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" routerLink="/settings/datas/cache" routerDirection="forward">
									<i className="fa-solid fa-database"></i>
									<IonLabel>Elimina la cache ed i Cookies</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } routerLink="/datas/delete-me">
									<i className="fas fa-user-slash"></i>
									<IonLabel color="danger">Elimina il tuo account ed i tuoi dati</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>
					
					</div>

				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsPrivacyPage);