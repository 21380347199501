import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComChatConversationComponent: type___locale___translations___collection = {
	'you': {
		de_de: 'Du',
		en_us: 'You',
		es_es: 'Tú',
		fr_fr: 'Tu',
		it_it: 'Tu',
	},
};