import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPostCreateComponent: type___locale___translations___collection = {
	'post___update': {
		de_de: 'Posten Sie ein Update',
		en_us: 'Post an update',
		es_es: 'Publicar una actualización',
		fr_fr: 'Publier une mise à jour',
		it_it: 'Pubblica un aggiornamento',
	},
	'post___what_are_you_thinking': {
		de_de: 'Was denkst du?',
		en_us: 'What are you thinking?',
		es_es: '¿Qué estás pensando?',
		fr_fr: 'A quoi penses-tu?',
		it_it: 'A cosa stai pensando?',
	},
	'post___write_something': {
		de_de: 'Schreibe etwas...',
		en_us: 'Write something...',
		es_es: 'Escribe algo...',
		fr_fr: 'Écrivez quelque chose...',
		it_it: 'Scrivi qualcosa...',
	},
};