import React from 'react'

//	L O C A L E

import { locale___ComChatMessageAttachmentUnavailableComponent } from './com-chat-message-attachment-unavailable.locale';

//	S T Y L E

import './com-chat-message-attachment-unavailable.scss'

//	S T A T E   -   P R O P S

import { props___ComChatMessageAttachmentUnavailableComponent } from './com-chat-message-attachment-unavailable.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComChatMessageAttachmentUnavailableComponent extends React.Component<props___ComChatMessageAttachmentUnavailableComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-chat-message-attachment-unavailable---container">
				{(() => { switch (this.props.attachment_type) {
					case 'tale': return <>
						<div className="com-chat-message-attachment-unavailable---tale">
							<p>{ this._LOCALE.translate(locale___ComChatMessageAttachmentUnavailableComponent.tale) }</p>
						</div>
					</>; break;









				}})()}
			</div>
		</>;
	}

//#endregion

}