import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComInputTextareaComponent: type___locale___translations___collection = {
	'textarea___placeholder': {
		de_de: 'Schreibe etwas...',
		en_us: 'Write something...',
		es_es: 'Escribe algo...',
		fr_fr: 'Écrivez quelque chose...',
		it_it: 'Scrivi qualcosa...',
	},
};