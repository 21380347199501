import React from 'react'

//	L O C A L E

import { locale___ComPostCreateDisabledComponent } from './com-post-create-disabled.locale';

//	S T Y L E

import './com-post-create-disabled.scss'

//	S T A T E S   -   P R O P S

import { props___ComPostCreateDisabledComponent } from './com-post-create-disabled.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	M O D A L S

import MdPostsCreateModal from '../../modals/md-posts-create/md-posts-create';

//	C L A S S

export default class ComPostCreateDisabledComponent extends React.Component<props___ComPostCreateDisabledComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-post-create-disabled---container">
				{(() => { switch (this.props.why) {
					case 'not_following': return <>
						<span>{ this._LOCALE.translate(locale___ComPostCreateDisabledComponent.not_following) }</span>
					</>; break;
					


					
				}})()}
			</div>
		</>;
	}

//#endregion

}