import React from 'react'
import { IonCard, IonItem, IonLabel, IonList } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComButtonInviteComponent } from './com-button-invite.locale';

//	S T Y L E

import './com-button-invite.scss'

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComButtonInviteComponent extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard>
				<section className="com-button-invite---component">
					<IonList>
						<IonItem className="is-ion-item-button" button={ true } routerLink="/invite">
							<i className="fas fa-share-alt"></i>
							<IonLabel style={{fontWeight:'bold'}}>{ this._LOCALE.translate(locale___ComButtonInviteComponent.invite_a_friend) }</IonLabel>
						</IonItem>
					</IonList>
				</section>
			</IonCard>
		</>;
	}

//#endregion

}