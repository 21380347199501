import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N S

import { locale___App } from '../../../App.locale';
import { locale___SbArchiveEnrollPage } from './sb-archive-enroll.locale';

//	S T Y L E

import './sb-archive-enroll.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S T A T E   P R O P S

import { props___SbArchiveEnrollPage } from './sb-archive-enroll.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';

//	C L A S S

class SbArchiveEnrollPage extends React.Component<props___SbArchiveEnrollPage, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbArchiveEnrollPage,
	) {
		super(props);
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___stats___platform = async () => {
		const ___temp___stats___platform: type___api___response = await this._API.stats___platform();
		if (___temp___stats___platform.response === 'success') {
			this.setState({
				stats_platform___count_events: (___temp___stats___platform.data['count_events']) ?? null, 
				stats_platform___count_poi: (___temp___stats___platform.data['count_poi']) ?? null, 
				stats_platform___count_poi_categories: (___temp___stats___platform.data['count_poi_categories']) ?? null, 
				stats_platform___count_posts: (___temp___stats___platform.data['count_posts']) ?? null, 
				stats_platform___count_tales: (___temp___stats___platform.data['count_tales']) ?? null, 
				stats_platform___count_users: (___temp___stats___platform.data['count_users']) ?? null, 
				stats_platform___props___is_loading: false,
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___stats___platform();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-support---container">

						<br />
						<br />

						<img src="../../assets/markers/poi.svg"/>

						<br />
						<br />

						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbArchiveEnrollPage.title) }</IonLabel>
						</IonListHeader>

						<br />

						<p>{ this._LOCALE.translate(locale___SbArchiveEnrollPage.description___1) }</p>

						<br />
						<br />

						<p>{ this._LOCALE.translate(locale___SbArchiveEnrollPage.description___2) }</p>

						<br />

						<IonCard>
							<a href={ 'mailto:info@nearbycommunity.it?subject=Nuova%20richiesta%20di%20inserimento%20nella%20mappa%20da%20' + this.props.is_logged___props['user_username'] } target="_blank">
								<IonItem className="is-ion-item-button" button={ true }>
									<i className="fas fa-envelope"></i>
									<IonLabel><b>info@nearbycommunity.it</b></IonLabel>
								</IonItem>
							</a>
						</IonCard>
					
						<br />

					</section>
				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbArchiveEnrollPage);