import React from 'react';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonModal, IonToast, IonToolbar } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../App.locale';
import { locale___MdSignPasswordResetModal } from './md-sign-password-reset.locale';

//	S T Y L E

import './md-sign-password-reset.scss';

//	S T A T E S   -   P R O P S

import { props___MdSignPasswordResetModal, state___MdSignPasswordResetModal, state___MdSignPasswordResetModal___default } from './md-sign-password-reset.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S E R V I C E S

import { service_RestAuthService } from '../../services/service-auth';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class MdSignPasswordResetModal extends React.Component<props___MdSignPasswordResetModal, state___MdSignPasswordResetModal>
{

//#region 																							D E C L A R A T I O N S

	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N F I G

	private readonly MdSignPasswordResetModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdSignPasswordResetModal,
	) {
		super(props);
		this.state = state___MdSignPasswordResetModal___default;
	}

//#endregion

//#region 																							S U B M I T

	private submit___password_reset = () => {
		this.setState({
			password_reset___is_loading: true,
		}, async () => {
			const ___password___is_resetted: type___api___response = await this._AUTH.auth___perform___reset_password(this.state.password_reset___field___user_email);
			if (___password___is_resetted && ___password___is_resetted.response === 'success')
			{
				this.setState({
					toast___password_reset_success___visible: true,
					password_reset___is_loading: false,
				}, () => {
					this.props.event___onDidDismiss();
				});
			}
			else
			{
				this.setState({
					toast___password_reset_error___visible: true,
					password_reset___is_loading: false,
				});
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void
	{
		this.setState({
			password_reset___is_loading: false,
		});
	}

	private event___onDidDismiss() : void
	{
		this.setState({
			...state___MdSignPasswordResetModal___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
	
			<IonToast isOpen={ this.state.toast___password_reset_success___visible } message={ this._LOCALE.translate(locale___MdSignPasswordResetModal.toast___success) } duration={ 3000 } color="success" position="top" onDidDismiss={() => { this.setState({ toast___password_reset_error___visible: false }); }}/>
			<IonToast isOpen={ this.state.toast___password_reset_error___visible } message={ this._LOCALE.translate(locale___MdSignPasswordResetModal.toast___error) } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ toast___password_reset_error___visible: false }); }}/>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdSignPasswordResetModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>{ this._LOCALE.translate(locale___App.modal___topBar___button___cancel) }</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonListHeader style={{textAlign:'center'}}>
						<IonLabel>{ this._LOCALE.translate(locale___MdSignPasswordResetModal.title) }</IonLabel>
					</IonListHeader>

					{(this.state.password_reset___is_loading === true) ? <>
						<br />
						<br />
						<ComSpinnerComponent size="small" />
					</> : <>
						<br />
						<section className="md-sign-password-reset---container">
							<p>{ this._LOCALE.translate(locale___MdSignPasswordResetModal.description_1) }</p>
							<br />
							<input placeholder={ this._LOCALE.translate(locale___MdSignPasswordResetModal.email_placeholder) } type="text" className="input---nb-outlined" style={{textAlign:'center'}} value={ this.state.password_reset___field___user_email } onInput={(___e: any) => this.setState({ password_reset___field___user_email: ___e.target.value })}/>
							<br />
							<p>{ this._LOCALE.translate(locale___MdSignPasswordResetModal.description_2) }</p>
						</section>
					</>}

				</IonContent>

				{(this.state.password_reset___is_loading === false) ? <>
					<IonFooter>
						<section className="container---footer-section">
							<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___password_reset }>
								<i className="fas fa-unlock-alt"></i>
								<h4>{ this._LOCALE.translate(locale___MdSignPasswordResetModal.button_recover) }</h4>
							</button>
						</section>
					</IonFooter>
				</> : <></>}

			</IonModal>

		</>;
	}

//#endregion

}