import { type___locale___translations___collection } from '../../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___SbArchiveFavoritesPage: type___locale___translations___collection = {
	'title': {
		de_de: 'Deine Favoriten',
		en_us: 'Your Favorites',
		es_es: 'Tus Favoritos',
		fr_fr: 'Vos Favoris',
		it_it: 'I tuoi Preferiti'
	},
};