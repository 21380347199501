import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdEventSelectCategoryModal: type___locale___translations___collection = {
	'title': {
		de_de: 'Kategorie auswählen',
		en_us: 'Select Event Category',
		es_es: 'Seleccionar categoría de evento',
		fr_fr: 'Sélectionner la catégorie d\'événement',
		it_it: 'Seleziona Categoria Evento',
	},
};