import { type___locale___translations___collection } from "../types/types.locale";

//	E X P O R T   L O C A L E   -->   funcs_numbers

export const locale___funcs_numbers: type___locale___translations___collection = {
	'unit___meters': {
		de_de: 'meter',
		en_us: 'meters',
		es_es: 'metros',
		fr_fr: 'mètres',
		it_it: 'metri',
	},
	'unit___kms': {
		de_de: 'km',
		en_us: 'km',
		es_es: 'km',
		fr_fr: 'km',
		it_it: 'km',
	},
};