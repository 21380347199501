//	L I B S

import { lib_names_shared } from "../libs/lib.names.shared";

//	E X P O R T

export class service_Router
{

//#region 																							G E T   I D

	public get_id(___props: any) : string | null
	{
		if (___props['match'] && ___props['match']['params'] && ___props['match']['params']['id']) {
			return ___props['match']['params']['id'] as string;
		} else {
			if (___props['match'] && ___props['match']['url'])
			{
				const ___props_url: string = ___props['match']['url'] as string;
				const ___props_url___id: RegExpMatchArray | null = ___props_url.match(lib_names_shared.regex.types___objectId);
				if (___props_url___id) { return ___props_url___id[0]; }
				else {
					const ___props_url___last_slash: number = ___props_url.lastIndexOf('/');
					const ___props_url___id___last: string = (___props_url___last_slash !== -1 && ___props_url___last_slash < ___props_url.length - 1) ? ___props_url.substring(___props_url___last_slash + 1) : ___props_url;
					return ___props_url___id___last as string;
				}
			}
		}
		return null;
	}

//#endregion

}