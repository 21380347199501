import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPostCreateDisabledComponent: type___locale___translations___collection = {
	'not_following': {
		de_de: 'Folge, um etwas zu posten',
		en_us: 'Follow to post something',
		es_es: 'Sigue para publicar algo',
		fr_fr: 'Suivez pour publier quelque chose',
		it_it: 'Segui per pubblicare qualcosa'
	},
	












};