import { type___locale___translations___collection } from "../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbInvitePage: type___locale___translations___collection = {
	'share_title': {
		de_de: 'Entdecken Sie die Welt um Sie herum!',
		en_us: 'Discover the world around you!',
		es_es: '¡Descubre el mundo que te rodea!',
		fr_fr: 'Découvrez le monde qui vous entoure!',
		it_it: 'Scopri il mondo intorno a te!',
	},
	'share_text': {
		de_de: 'Treten Sie Nearby bei und lassen Sie uns gemeinsam die reale Welt erkunden\nNearby ist Ihr Tor zur Welt um Sie herum!\n\nFolgen Sie dem Link zum Herunterladen der App {{1}}',
		en_us: 'Join Nearby and let\'s explore the real world together\nNearby is your gateway to the world around you!\n\nFollow the link to download the app {{1}}',
		es_es: 'Únase a Nearby y exploremos juntos el mundo real. ¡Nearby es su puerta de entrada al mundo que lo rodea!\n\nSiga el enlace para descargar la aplicación {{1}}',
		fr_fr: 'Rejoignez Nearby et explorons le monde réel ensemble\nNearby est votre passerelle vers le monde qui vous entoure !\n\nSuivez le lien pour télécharger l\'application {{1}}',
		it_it: 'Entra su Nearby ed esploriamo insieme il mondo reale\nNearby è il punto di accesso al mondo che ti circonda!\n\nSegui il link per scaricare l\'app {{1}}',
	},
	'text___question': {
		de_de: 'Gefällt dir Nearby?!',
		en_us: 'Do you like Nearby?!',
		es_es: '¡¿Te gusta Nearby?!',
		fr_fr: 'Aimez-vous Nearby?!',
		it_it: 'Ti piace Nearby?!',
	},
	'text___answer': {
		de_de: 'Teile die App und lade deine Freunde ein',
		en_us: 'Share the app and invite your friends',
		es_es: 'Comparte la aplicación e invita a tus amigos',
		fr_fr: 'Partagez l\'application et invitez vos amis',
		it_it: 'Condiviti l\'app ed invita i tuoi amici',
	},
	'text___button': {
		de_de: 'Aktie',
		en_us: 'Share',
		es_es: 'Compartir',
		fr_fr: 'Partager',
		it_it: 'Condividi',
	},
};