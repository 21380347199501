//	S T A T E

export interface state___SbInfoAppPage {

	app_version: string,
	app_version_build: string,

	app_version___is_loading: boolean,

	ionModal___MdEntryChangeLogModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbInfoAppPage___default: state___SbInfoAppPage = {

	app_version: '×.×.×',
	app_version_build: '×××××',

	app_version___is_loading: true,

	ionModal___MdEntryChangeLogModal___isOpen: false,

}