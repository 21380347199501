import { interface___is_logged } from "../../../interfaces/interface.logged";
import { interface___has_notifications } from "../../../interfaces/interface.notifications";

//	P R O P S

export interface props___SbChatsListPage extends interface___is_logged, interface___has_notifications {

}

//	S T A T E

export interface state___SbChatsListPage {

	chat_conversations___collection: any[],
	chat_conversations___collection___is_loading: boolean,
	chat_conversations___collection___is_complete: boolean,

	ionModal___MdUserSearchModal___isOpen: boolean,
	ionModal___MdUserSearchModal___result: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbChatsListPage___default: state___SbChatsListPage = {

	chat_conversations___collection: [],
	chat_conversations___collection___is_loading: true,
	chat_conversations___collection___is_complete: false,

	ionModal___MdUserSearchModal___isOpen: false,
	ionModal___MdUserSearchModal___result: null,

}