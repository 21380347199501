import { RouteComponentProps } from "react-router";

//	S T A T E

export interface state___SbSettingsDatasCachePage {

	localStorage___is_clearing: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbSettingsDatasCachePage___default: state___SbSettingsDatasCachePage = {

	localStorage___is_clearing: false,

}