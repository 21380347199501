import React from 'react'

//	L O C A L I Z A T I O N

import { locale___Com404Component } from './locale.com-camera-unavailable';

//	S T Y L E

import './com-camera-unavailable.scss'

//	S T A T E   -   P R O P S

import { props___ComCameraUnavailableComponent } from './com-camera-unavailable.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComCameraUnavailableComponent extends React.Component<props___ComCameraUnavailableComponent, {}>
{

//#region 																							R E N D E R

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-camera-unavailable---container">
			
				<img src="/assets/ui/images/image-404.svg" alt="404" />
			
				<h3>{ this._LOCALE.translate(locale___Com404Component.title) }</h3>
				<p>{ this._LOCALE.translate(locale___Com404Component.message) }</p>
				<span>{ this._LOCALE.translate(locale___Com404Component.footer) }</span>

				<br />
				<br />

				<section>
					<span onClick={ this.props.event___onDidRetry }>
						{ this._LOCALE.translate(locale___Com404Component.button___retry) }
					</span>
					<span onClick={ this.props.event___onDidCancel }>
						{ this._LOCALE.translate(locale___Com404Component.button___close) }
					</span>
				</section>

			</div>
		</>;
	}

//#endregion

}