import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComChatMessageViewedComponent: type___locale___translations___collection = {
	'viewed___datetime': {
		de_de: 'Gesehen am {{1}} um {{2}}',
		en_us: 'Viewed on {{1}} at {{2}}',
		es_es: 'Visto el {{1}} en {{2}}',
		fr_fr: 'Consulté le {{1}} à {{2}}',
		it_it: 'Visualizzato il {{1}} alle {{2}}',
	},
};