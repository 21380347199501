import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComButtonInviteComponent: type___locale___translations___collection = {
	'invite_a_friend': {
		de_de: 'Lade einen Freund ein',
		en_us: 'Invite a friend',
		es_es: 'Invitar a un amigo',
		fr_fr: 'Inviter un ami',
		it_it: 'Invita un amico',
	},
};