import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdEntryLanguageModal: type___locale___translations___collection = {
	'hint___select_a_language_to_begin': {
		de_de: 'wählen Sie eine Sprache um zu beginnen',
		en_us: 'select language to start',
		es_es: 'seleccione un idioma para empezar',
		fr_fr: 'sélectionnez une langue pour commencer',
		it_it: 'seleziona una lingua per iniziare',
	}
};