//	P R O P S

export interface props___ComTaleComponent {

	tale___props: any
	event___onClick: () => void

}

//	S T A T E

export interface state___ComTaleComponent {

}

//	S T A T E   D E F A U L T

export const state___ComTaleComponent___default: state___ComTaleComponent = {

}