import React from 'react'

//	L O C A L E

import { locale___ComChatConversationComponent } from './com-chat-conversation.locale';

//	S T Y L E

import './com-chat-conversation.scss'

//	S T A T E   P R O P S

import { props___ComChatConversationComponent } from './com-chat-conversation.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComChatConversationOnlineComponent from '../com-chat-conversation-online/com-chat-conversation-online';
import ComUserVerifiedComponent from '../com-user-verified/com-user-verified';

//	C L A S S

export default class ComChatConversationComponent extends React.Component<props___ComChatConversationComponent, {}>
{

//#region 																							R E N D E R

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-chat-conversation---container">
				<section>
					<section>
						<section>
							<img src={ this.props.chat_props['chat_with']['user_image_profile'] }/>
							<ComChatConversationOnlineComponent />
						</section>
						<section>
							<h5>
								<span>{ this.props.chat_props['chat_with']['user_username'] }</span>
								{(this.props.chat_props['chat_with']['user_profile_verified'] && this.props.chat_props['chat_with']['user_profile_verified'] === true) ? <>
									<ComUserVerifiedComponent popup={ false } />
								</> : <></>}
							</h5>
							<p>
								{(this.props.chat_props['chat_last_message']['message_from'] === 'me') ? <><b>{ this._LOCALE.translate(locale___ComChatConversationComponent.you) }: </b></> : <></>}
								<span>{ this.props.chat_props['chat_last_message']['message_text'] }</span>
							</p>
						</section>
					</section>
					<section>
						{(this.props.chat_props['chat_messages_unread'] && this.props.chat_props['chat_messages_unread'] > 0) ? <>
							<section>
								<span>{ this.props.chat_props['chat_messages_unread'] }</span>
							</section>
						</> : <></>}
						<section>
							<i className="fas fa-chevron-right"></i>
						</section>
					</section>
				</section>
			</div>
		</>;
	}

//#endregion

}