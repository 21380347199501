import React from 'react'
import { Link } from 'react-router-dom';

//	S T Y L E

import './com-post-attachment.scss'

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___post___attachment_type } from '../../types/types.types';

//	S T A T E S   -   P R O P S

import { props___ComPostAttachmentComponent, state___ComPostAttachmentComponent, state___ComPostAttachmentComponent___default } from './com-post-attachment.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../com-event/com-event';
import ComPoiComponent from '../com-poi/com-poi';
import ComSpinnerInlineComponent from '../com-spinner-inline/com-spinner-inline';
import ComTalePreviewComponent from '../com-tale-preview/com-tale-preview';

//	C L A S S

export default class ComPostAttachmentComponent extends React.Component<props___ComPostAttachmentComponent, state___ComPostAttachmentComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComPostAttachmentComponent,
	) {
		super(props);
		this.state = state___ComPostAttachmentComponent___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___attachment = async () => {
		if (this.props.post_props && this.props.post_props['post_attachment_type'] !== null && this.props.post_props['post_attachment_id'] !== null) {
			let ___temp___attachment_props: type___api___response = { response: 'no-data', data: null };
			switch (this.props.post_props['post_attachment_type'] as type___post___attachment_type) {
				case 'event': ___temp___attachment_props = await this._API.events___read___single(this.props.post_props['post_attachment_id']); break;
				case 'poi': ___temp___attachment_props = await this._API.poi___read___single(this.props.post_props['post_attachment_id']); break;
				case 'tale': ___temp___attachment_props = await this._API.tales___read___single(this.props.post_props['post_attachment_id']); break;
			}
			this.setState({
				post_attachment___props: ___temp___attachment_props.data,
				post_attachment___props___is_loading: false,
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void> {
		await this.read_props___attachment();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.props.post_props && this.props.post_props['post_attachment_type'] && this.props.post_props['post_attachment_type'] !== null && this.props.post_props['post_attachment_id'] !== null) ? <>
				{(this.state.post_attachment___props___is_loading === true || this.state.post_attachment___props === null) ? <>
					<ComSpinnerInlineComponent/>
				</> : <>
					{(() => { switch (this.props.post_props['post_attachment_type'] as type___post___attachment_type) {
						case 'event': return <>
							<Link to={ '/view/events/' + this.props.post_props['post_attachment_id'] }>
								<ComEventComponent event_props={ this.state.post_attachment___props } size="normal" />
							</Link>
						</>; break;
						case 'poi': return <>
							<Link to={ '/view/poi/' + this.props.post_props['post_attachment_id'] }>
								<ComPoiComponent poi_props={ this.state.post_attachment___props } size="normal" />
							</Link>
						</>; break;
						case 'tale': return <>
							<Link to={ '/show/tale/' + this.props.post_props['post_attachment_id'] }>
								<ComTalePreviewComponent tale_props={ this.state.post_attachment___props } />
							</Link>
						</>; break;















					}})()}
				</>}
			</> : <></>}
		</>;
	}

//#endregion

}