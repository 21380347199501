import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdDigitalMenuDishEditModal: type___locale___translations___collection = {
	'ionToast___added': {
		de_de: 'Produkt hinzugefügt',
		en_us: 'Product added',
		es_es: 'Producto añadido',
		fr_fr: 'Produit ajouté',
		it_it: 'Prodotto aggiunto'
	},
	'ionToast___error': {
		de_de: 'Fehler beim Hinzufügen des Produkts',
		en_us: 'Error adding product',
		es_es: 'Error al añadir el producto',
		fr_fr: 'Erreur lors de l\'ajout du produit',
		it_it: 'Errore nell\'aggiunta del prodotto'
	},
	'ionToast___title': {
		de_de: 'Produktinformationen',
		en_us: 'Product Information',
		es_es: 'Información del producto',
		fr_fr: 'Informations sur le produit',
		it_it: 'Informazioni prodotto'
	},
	'field___select_category': {
		de_de: 'Kategorie auswählen',
		en_us: 'Select Category',
		es_es: 'Seleccionar categoría',
		fr_fr: 'Sélectionner une catégorie',
		it_it: 'Seleziona Categoria'
	},
	'field___product_name': {
		de_de: 'Produktname',
		en_us: 'Product Name',
		es_es: 'Nombre del producto',
		fr_fr: 'Nom du produit',
		it_it: 'nome prodotto'
	},
	'field___product_name___placeholder': {
		de_de: 'Name des Produkts',
		en_us: 'Product Name',
		es_es: 'Nombre del producto',
		fr_fr: 'Nom du produit',
		it_it: 'Nome del prodotto'
	},
	'field___product_description': {
		de_de: 'Beschreibung und/oder Zutaten',
		en_us: 'Description and/or ingredients',
		es_es: 'Descripción y/o ingredientes',
		fr_fr: 'Description et/ou ingrédients',
		it_it: 'descrizione e/o ingredienti'
	},
	'field___product_description___placeholder': {
		de_de: 'Beschreibung und/oder Zutaten',
		en_us: 'Description and/or ingredients',
		es_es: 'Descripción y/o ingredientes',
		fr_fr: 'Description et/ou ingrédients',
		it_it: 'descrizione e/o ingredienti'
	},
	'field___product_price': {
		de_de: 'Preis',
		en_us: 'Price',
		es_es: 'Precio',
		fr_fr: 'Prix',
		it_it: 'prezzo'
	},
	'field___product_unit___select': {
		de_de: 'Einheit auswählen',
		en_us: 'Select unit of measure',
		es_es: 'Seleccionar unidad de medida',
		fr_fr: 'Sélectionner l\'unité de mesure',
		it_it: 'Seleziona unità di misura'
	},
	'field___product_unit___none': {
		de_de: 'Keine',
		en_us: 'None',
		es_es: 'Ninguna',
		fr_fr: 'Aucune',
		it_it: 'Nessuna'
	},
	'field___product_unit___kilo': {
		de_de: 'pro Kilo',
		en_us: 'Per Kilo',
		es_es: 'Por Kilo',
		fr_fr: 'Par Kilo',
		it_it: 'al Kilo'
	},
	'field___product_unit___gram': {
		de_de: 'pro Gramm',
		en_us: 'Per Gram',
		es_es: 'Por Gramo',
		fr_fr: 'Par Gramme',
		it_it: 'al Grammo'
	},
	'field___product_unit___liter': {
		de_de: 'pro Liter',
		en_us: 'Per Liter',
		es_es: 'Por Litro',
		fr_fr: 'Par Litre',
		it_it: 'al Litro'
	},
	'field___product_allergens': {
		de_de: 'Allergene',
		en_us: 'Allergens',
		es_es: 'Alérgenos',
		fr_fr: 'Allergènes',
		it_it: 'allergeni'
	},
	'field___button___save': {
		de_de: 'Speichern',
		en_us: 'Save',
		es_es: 'Guardar',
		fr_fr: 'Enregistrer',
		it_it: 'Salva'
	},
	'field___button___disabled': {
		de_de: 'Bitte alle Felder ausfüllen',
		en_us: 'Fill all fields',
		es_es: 'Rellene todos los campos',
		fr_fr: 'Remplissez tous les champs',
		it_it: 'Compila tutti i campi'
	}
};