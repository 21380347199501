import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbSignPage extends RouteComponentProps<{ id: string; }> {

	event___registerAuthUserDatas: (_: any | null) => void,

}

//	S T A T E

export interface state___SbSignPage {

	sign___is_loading___initial: boolean,
	sign___is_loading: boolean,
	sign___is_logged_in: boolean,

	tab_section___selected: 'login' | 'register',

	field_login___username: string, 
	field_login___username___valid: boolean,
	field_login___password: string,
	field_login___eula_accepted: boolean,

	field_register___born_date: string | undefined,
	field_register___born_date___confirmed: Date | null,
	field_register___email: string,
	field_register___username: string,
	field_register___username___valid: boolean,
	field_register___password: string,
	field_register___eula_accepted: boolean,

	ionModal___MdSignPasswordResetModal___isOpen: boolean,

	ionToast___signError___isVisible: boolean,
	ionToast___signError___text: string,
	ionToast___signSuccess___isVisible: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbSignPage___default: state___SbSignPage = {

	sign___is_loading___initial: true,
	sign___is_loading: false,
	sign___is_logged_in: false,

	tab_section___selected: 'login',

	field_login___username: '', 
	field_login___username___valid: false,
	field_login___password: '',
	field_login___eula_accepted: false,

	field_register___born_date: undefined,
	field_register___born_date___confirmed: null,
	field_register___email: '',
	field_register___username: '',
	field_register___username___valid: false,
	field_register___password: '',
	field_register___eula_accepted: false,

	ionModal___MdSignPasswordResetModal___isOpen: false,

	ionToast___signError___isVisible: false,
	ionToast___signError___text: '',
	ionToast___signSuccess___isVisible: false,

}