import React from 'react';
import { IonPage, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-element-tale.scss';

//	S T A T E   -   P R O P S

import { props___SbElementTalePage, state___SbElementTalePage, state___SbElementTalePage___default } from './sb-element-tale.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComTaleViewInner from '../../../components/com-tale-view-inner/com-tale-view-inner';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

class SbElementTalePage extends React.Component<props___SbElementTalePage, state___SbElementTalePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbElementTalePage,
	) {
		super(props);
		this.state = state___SbElementTalePage___default;
	}

//#endregion

//#region 																							C O N I F G

	config___noModalEnterAnimation = () => {
	//	const ___animation_return: Animation = createAnimation().duration(0);
	//	return ___animation_return as Animation;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___go_back = async () => {
		window.history.back();
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___tale_id: string | null = this._ROUTER.get_id(this.props);
		if (___tale_id) {
			this.setState({
				tale___id: ___tale_id,
			});
		}
	}

	ionViewWillLeave() : void {
		this.setState({
			...state___SbElementTalePage___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>
				<section className="sb-element-tale---container">
					<section className="sb-element-tale---container---topbar">
						<div onClick={ this.handle___go_back }>
							<i className="fas fa-chevron-left"></i>
							<span>{ this._LOCALE.translate(locale___App.ionBackButton___text) }</span>
						</div>
					</section>
					{(this.state.tale___id && this.state.tale___id !== null) ? <>
						<ComTaleViewInner tale_id={ this.state.tale___id } event___onDidDismiss={ this.handle___go_back }/>
					</> : <>
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</>}
				</section>
			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbElementTalePage);