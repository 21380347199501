import { interface___is_logged } from "../../interfaces/interface.logged";
import { type___post___attachment_type } from "../../types/types.types";

//	P R O P S

export interface props___ComInputTextareaComponent extends interface___is_logged {

	text: string,
	event___onInput: (_: string) => void,

}

//	S T A T E

export interface state___ComInputTextareaComponent {

	input_text: string,
	input_text___rich: string,

	tag_selector___input: string,
	tag_selector___is_visible: boolean,











}

//	S T A T E   D E F A U L T

export const state___ComInputTextareaComponent___default: state___ComInputTextareaComponent = {

	input_text: '',
	input_text___rich: '',

	tag_selector___input: '',
	tag_selector___is_visible: false,











}