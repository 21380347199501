import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComEventTimeScheduleComponent: type___locale___translations___collection = {
	'event_now': {
		de_de: 'Veranstaltung läuft!',
		en_us: 'Event underway!',
		es_es: '¡Evento en marcha!',
		fr_fr: 'Événement en cours!',
		it_it: 'Evento in corso!',
	},
	'event_now_begun': {
		de_de: 'um {{1}} ging es los',
		en_us: 'it started at {{1}}',
		es_es: 'empezó a las {{1}}',
		fr_fr: 'ça a commencé à {{1}}',
		it_it: 'è iniziato alle {{1}}',
	},
	'event_ended': {
		de_de: 'Veranstaltung beendet',
		en_us: 'Event Ended',
		es_es: 'Evento finalizado',
		fr_fr: 'Événement terminé',
		it_it: 'Evento Terminato',
	},
	'event_ended_datetime': {
		de_de: 'Es war {{1}} um {{2}} Uhr',
		en_us: 'It was {{1}} at {{2}}',
		es_es: 'Era el {{1}} a las {{2}}',
		fr_fr: 'C\'était le {{1}} à {{2}}',
		it_it: 'Era il {{1}} alle {{2}}',
	},
	'event_begin_save_the_date': {
		de_de: 'Merken Sie den Termin vor!',
		en_us: 'Save the date!',
		es_es: '¡Reserva!',
		fr_fr: 'Réserve cette date!',
		it_it: 'Save the date!',
	},
	'event_begin_datetime': {
		de_de: 'Beginn ist am {{1}} um {{2}} Uhr',
		en_us: 'Starts {{1}} at {{2}}',
		es_es: 'Comienza el {{1}} a las {{2}}',
		fr_fr: 'Commence le {{1}} à {{2}}',
		it_it: 'Inizia il {{1}} alle {{2}}',
	},
};