import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbSettingsDatasCachePage: type___locale___translations___collection = {
	'title': {
		de_de: 'Gespeicherte Daten löschen',
		en_us: 'Delete saved data',
		es_es: 'Eliminar datos guardados',
		fr_fr: 'Supprimer les données enregistrées',
		it_it: 'Elimina dati salvati',
	},
	'description': {
		de_de: 'Wenn Sie Probleme mit der App haben, weil sie Fehler im Zusammenhang mit einer alten Version aufweist, Sie lokal gespeicherte Daten löschen möchten oder das Gefühl haben, dass Ihr Gerät bei der Verwendung der App langsamer wird, fahren Sie mit dem Leeren des Caches fort. Beachten Sie, dass durch diesen Vorgang alle Daten dauerhaft gelöscht werden Daten dieser Anwendung werden auf Ihrem Gerät gespeichert, einschließlich Cache, LocalStorage, Cookies, Cookies von Drittanbietern, Präferenzen, Kontodaten und Anmeldedaten. Diese Aktion beeinträchtigt nicht Ihre Inhalte, die bereits online auf der Plattform sind. Wenn Sie verstehen, was diese Aktion beinhaltet, fahren Sie mit der Schaltfläche unten fort.',
		en_us: 'If you have problems with the app because it has bugs related to an old version, you want to delete data saved locally or you feel your device slows down when using the app, proceed with clearing the cache, note that this procedure will permanently delete all data from this application saved on your device, including cache, localStorage, cookies, third-party cookies, preferences, account data and login data. This action does not compromise your content already online on the platform. If you understand what this action entails, continue with the button below.',
		es_es: 'Si tienes problemas con la aplicación porque tiene errores relacionados con una versión anterior, deseas eliminar datos guardados localmente o sientes que tu dispositivo se ralentiza al usar la aplicación, procede a borrar el caché, ten en cuenta que este procedimiento eliminará todo permanentemente. datos de esta aplicación guardados en su dispositivo, incluidos caché, almacenamiento local, cookies, cookies de terceros, preferencias, datos de cuenta y datos de inicio de sesión. Esta acción no compromete su contenido que ya está en línea en la plataforma. Si comprende lo que implica esta acción, continúe con el botón a continuación.',
		fr_fr: 'Si vous rencontrez des problèmes avec l\'application car elle présente des bugs liés à une ancienne version, que vous souhaitez supprimer des données enregistrées localement ou que vous sentez que votre appareil ralentit lors de l\'utilisation de l\'application, procédez à la suppression du cache, notez que cette procédure supprimera définitivement tout les données de cette application enregistrées sur votre appareil, y compris le cache, le stockage local, les cookies, les cookies tiers, les préférences, les données de compte et les données de connexion. Cette action ne compromet pas votre contenu déjà en ligne sur la plateforme. Si vous comprenez ce qu\'implique cette action, continuez avec le bouton ci-dessous.',
		it_it: 'Se hai problemi con l\'app perché presenta dei bug relativi ad una vecchia versione, vuoi eliminare i dati salvati in locale o senti il tuo dispositivo rallentato quando utilizzi l\'app procedi con la cancellazione della cache, nota che questa procedura eliminerà permanentemente tutti i dati di questa applicazione salvati nel tuo dispositivo, compreso cache, localStorage, cookies, cookies di terze parti, preferenze, dati dell\'account e dati del login. Questa azione non compromette i tuoi contenuti già online nella piattaforma. Se hai compreso cosa comporta questa azione prosegui col pulsante qui sotto.',
	},
	'button___delete': {
		de_de: 'ALLE gespeicherten Daten löschen',
		en_us: 'Delete ALL saved data',
		es_es: 'Eliminar TODOS los datos guardados',
		fr_fr: 'Supprimer TOUTES les données enregistrées',
		it_it: 'Elimina TUTTI dati salvati',
	},
	'is_deleting': {
		de_de: 'gespeicherte daten werden gelöscht...',
		en_us: 'deleting saved data...',
		es_es: 'eliminando datos guardados...',
		fr_fr: 'suppression des données enregistrées...',
		it_it: 'eliminando i dati salvati...',
	},
};