import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonListHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { debounce } from 'lodash';

//	S T Y L E

import './sb-user-close-friends.scss';

//	S T A T E   -   P R O P S

import { props___SbUsersCloseFriendsPage, state___SbUsersCloseFriendsPage, state___SbUsersCloseFriendsPage___default } from './sb-user-close-friends.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___user___friends___action } from '../../../types/types.types';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';
import ComUserComponent from '../../../components/com-user/com-user';

//	M O D A L S


//	C L A S S

class SbUsersCloseFriendsPage extends React.Component<props___SbUsersCloseFriendsPage, state___SbUsersCloseFriendsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbUsersCloseFriendsPage,
	) {

		super(props);
		this.state = state___SbUsersCloseFriendsPage___default;

		this.constructor___debounce();

	}

	constructor___debounce() : void {
		this.handle___discover___search___input = this.handle___discover___search___input.bind(this);
		this.handle___discover___search = debounce(this.handle___discover___search.bind(this), lib_names.lodash.debounce_delay);
	}

//#endregion

//#region 																							R E A D   P R O P S


	private read_props___close_friends_collection = async () => {
		const ___temp___close_friends___collection: type___api___response = await this._API.users_friends___read_list();
		this.setState({
			close_friends___collection: ___temp___close_friends___collection.data,
			close_friends___collection___is_loading: false,
		});
	}

	private read_props___user_follows_collection = async () => {
		const ___temp___user_follows___collection: type___api___response = await this._API.search___full('users_follows', this.state.follows___search_param, this.state.follows___collection.length);
		this.setState((prevState) => ({
			follows___collection: [...prevState.follows___collection, ...___temp___user_follows___collection.data],
			follows___collection___is_loading: false,
			follows___collection___is_complete: (___temp___user_follows___collection.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail> | null = null) => {
		this.setState({
			follows___collection: [],
			follows___collection___is_loading: true,
			follows___collection___is_complete: false,
		}, async () => {
			await this.read_props___user_follows_collection();
			if (___e !== null) { ___e.detail.complete(); }
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			follows___collection___is_loading: true
		}, async () => {
			await this.read_props___user_follows_collection();
		});
	}

	private handle___discover___search___input = (___search_param: string) => {
		this.setState({
			follows___search_param: ___search_param,
			follows___collection___is_loading: true,
			follows___collection: [],
		}, () => {
			this.handle___discover___search(this.state.follows___search_param);
		});
	}

	private handle___discover___search = (___search_param: string) => {
		this.setState({
			follows___search_param: ___search_param,
			follows___collection: [],
			follows___collection___is_loading: true,
		}, async () => {
			if (this.state.follows___search_param && this.state.follows___search_param.length > 0) {
				await this.read_props___user_follows_collection();
			}
		});
	}

	private handle___friend___toggle = async (___friend_id: string) => {
	
		const ___friends___action: type___user___friends___action = (() => {
			switch (this.state.close_friends___collection.includes(___friend_id)) {
			case true: return 'remove'; break;
			case false: return 'add'; break;
		}})()
	
		const ___temp___is_friended___list: string[] = this.state.close_friends___collection;
		const ___temp___is_friended___list_index: number = ___temp___is_friended___list.indexOf(___friend_id);
		const ___temp___is_friended: type___api___response = await this._API.users_friends___toggle(___friend_id, ___friends___action);

		if (___temp___is_friended___list_index > -1 && ___temp___is_friended.response === 'success') {
			___temp___is_friended___list.splice(___temp___is_friended___list_index, 1);
		} else {
			___temp___is_friended___list.push(___friend_id);
		}

		this.setState({
			close_friends___collection: ___temp___is_friended___list
		});

	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___close_friends_collection();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/user/profile"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefresherComponent/>
					</IonRefresher>
			
					<div className="sb-page---container">

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerRefresherComponent/>
						</IonRefresher>

						<section className="sb-user-close-friends---container">

							<img src="../../assets/ui/icons/icon-close-friends.svg"/>

							<br />
							<br />

							<IonListHeader>
								<IonLabel style={{textAlign:'center'}}>Amici Fidati</IonLabel>
							</IonListHeader>

							<p>
								<span>Gli amici fidati sono persone che conosci nella vita reale, che possono visualizzare i tuoi contenuti all'interno della mappa</span>
								<br />
								<br />
								<span style={{fontWeight:'bold'}}>Aggiungi solo le persone di cui ti fidi e che conosci realmente a questa cerchia</span>
							</p>

						</section>

						<br />

						{(this.state.close_friends___collection___is_loading === true) ? <>
							<ComSpinnerInlineComponent />
						</> : <>
						
							<label className="sb-user-close-friends---search-bar" htmlFor="input---discover-search-full">
								<i className="fas fa-search"></i>
								<input type="text" id="input---discover-search-full" placeholder="Cerca tra i tuoi contatti..." value={ this.state.follows___search_param } onInput={(___e: any) => { this.handle___discover___search___input(___e.target.value); }}/>
							</label>

							<br />
							<br />

							<section className="sb-user-close-friends---selector-scroller">
								{this.state.follows___collection.map((___user: any, ___user___idx: number) => {
									return <>
										<section key={ ___user___idx } className={(this.state.close_friends___collection.includes(___user['user_id'])) ? 'sb-user-close-friends---selector-scroller---selected' : ''} onClick={() => { this.handle___friend___toggle(___user['user_id']); }}>
											<ComUserComponent user___props={ ___user } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props }/>
											{(this.state.close_friends___collection.includes(___user['user_id'])) ? <><i className="fas fa-check-circle"></i></> : <><i className="far fa-circle"></i></>}
										</section>
										<ComListSeparatorComponent />
									</>;
								})}
							</section>

							{(this.state.follows___collection___is_complete === true) ? <>
								<ComListEmptyComponent list_items_quantity={ this.state.follows___collection.length }/>
							</> : <>
								<ComInfiniteScrollerComponent is_loading={ this.state.follows___collection___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
							</>}

						</>}

					</div>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbUsersCloseFriendsPage);