import React from 'react';

//	S T Y L E

import './com-gallery-editor.scss'

//	T Y P E S

import { props___ComGalleryEditComponent, state___ComGalleryEditComponent, state___ComGalleryEditComponent___defaults } from './com-gallery-editor.state';

//	C L A S S

export default class ComGalleryEditComponent extends React.Component<props___ComGalleryEditComponent, state___ComGalleryEditComponent>
{

//#region 																							D E C L A R A T I O N S

	private item___dragged: number | null = null;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComGalleryEditComponent,
	) {
		super(props);
		this.state = state___ComGalleryEditComponent___defaults;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___gallery_file___move = (___item_index: number, ___go_to: 'prev' | 'next') => {
		const ___gallery_array___temp: string[] = this.state.field___gallery_pictures;
		switch (___go_to) {
			case 'prev':
				if (___item_index > 0) {
					const ___element___main: string = ___gallery_array___temp[___item_index];
					const ___element___switch: string = ___gallery_array___temp[(___item_index - 1)];
					___gallery_array___temp[(___item_index - 1)] = ___element___main;
					___gallery_array___temp[___item_index] = ___element___switch;
				}
			break;
			case 'next':
				if (___item_index < (___gallery_array___temp.length - 1)) {
					const ___element___main: string = ___gallery_array___temp[___item_index];
					const ___element___switch: string = ___gallery_array___temp[(___item_index + 1)];
					___gallery_array___temp[(___item_index + 1)] = ___element___main;
					___gallery_array___temp[___item_index] = ___element___switch;
				}
			break;
		}
		this.setState({
			field___gallery_pictures: ___gallery_array___temp,
		});
	}

	private handle___gallery_file___remove = (___index: number) => {
		this.setState((___prevState) => ({
			field___gallery_pictures: ___prevState.field___gallery_pictures.filter((_, i) => i !== ___index),
		}));
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		this.setState({
			field___gallery_pictures: this.props.gallery_pictures
		});
	}

	componentDidUpdate(prevProps: props___ComGalleryEditComponent, prevState: state___ComGalleryEditComponent) : void {
		if (this.props !== prevProps) {
			this.setState({
				field___gallery_pictures: this.props.gallery_pictures
			});
		}
	}

//#endregion

//#region 																							R E N D E R			

	render(): React.ReactNode
	{
		return <>
			<div className="com-gallery-editor-component---container">
				{this.state.field___gallery_pictures.map((___img: string, ___img___idx: number) => <>
					<div key={ ___img___idx } draggable >
						<img key={ ___img___idx } src={ ___img }/>
						<div>
							<section>
								<i className="fas fa-trash" style={{color:'var(--color---reddy)'}} onClick={() => { this.handle___gallery_file___remove(___img___idx) }}></i>
								<span></span>
							</section>
							<section>
								{(___img___idx === 0) ? <>
									<span></span>
								</> : <>
									<i className="fas fa-chevron-left" onClick={() => { this.handle___gallery_file___move(___img___idx, 'prev') }}></i>
								</>}
								{(___img___idx === (this.state.field___gallery_pictures.length - 1)) ? <>
									<span></span>
								</> : <>
									<i className="fas fa-chevron-right" onClick={() => { this.handle___gallery_file___move(___img___idx, 'next') }}></i>
								</>}
							</section>
						</div>
					</div>
				</>)}
			</div>
		</>;
	}

//#endregion

}