import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdPositionSelectorModal: type___locale___translations___collection = {
	'title': {
		de_de: 'Standort',
		en_us: 'Location',
		es_es: 'Ubicación',
		fr_fr: 'Emplacement',
		it_it: 'Posizione',
	},
	'no_address_selected': {
		de_de: 'Keine Adresse ausgewählt',
		en_us: 'No address selected',
		es_es: 'No se ha seleccionado ninguna dirección',
		fr_fr: 'Aucune adresse sélectionnée',
		it_it: 'Nessun indirizzo selezionato',
	},
	'button___save': {
		de_de: 'Standort speichern',
		en_us: 'Save location',
		es_es: 'Guardar ubicación',
		fr_fr: 'Enregistrer l\'emplacement',
		it_it: 'Salva posizione',
	}
};