import React from 'react'
import { Link } from 'react-router-dom';
import { IonActionSheet, IonAlert, IonToast } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../App.locale';
import { locale___ComPostCommentComponent } from './com-post-comment.locale';

//	S T Y L E

import './com-post-comment.scss'

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../interfaces/interface.actionsheet';
import { interface___IonAlert } from '../../interfaces/interface.alert';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S T A T E S   -   P R O P S

import { props___ComPostCommentComponent, state___ComPostCommentComponent, state___ComPostCommentComponent___default } from './com-post-comment.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComTextRichComponent from '../com-text-rich/com-text-rich';

//	C L A S S

export default class ComPostCommentComponent extends React.Component<props___ComPostCommentComponent, state___ComPostCommentComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N F I G

	private readonly ionActionSheet___postCommentAction___config: interface___IonActionSheet = {
		buttons: [
			{
				text: this._LOCALE.translate(locale___ComPostCommentComponent.ionActionSheet___postCommentAction____button___delete),
				role: 'destructive',
				handler: () => {
					this.setState({
						ionAlert___postCommentAction___askForClose___isOpen: true,
					});
				}
			},
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___cancel),
				role: 'cancel',
				handler: () => {
					this.setState({
						ionActionSheet___postCommentAction___isOpen: false,
					});
				}
			}
		]
	};

	private readonly ionAlert___postCommentAction___askForClose___config: interface___IonAlert = {
		header: this._LOCALE.translate(locale___ComPostCommentComponent.ionActionSheet___postCommentAction____button___delete),
		message: this._LOCALE.translate(locale___ComPostCommentComponent.ionActionSheet___postCommentAction____button___delete),
		buttons: [
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___yes),
				handler: async () => {
					const ___post_comment___is_deleted: type___api___response = await this._API.posts_comments___delete(this.state.post_comment___props['post_comment_id']);
					this.setState({
						ionToast___postCommentAction___isDeleted: (___post_comment___is_deleted['response'] === 'success') ? true : false,
						post_comment___is_destroyed: (___post_comment___is_deleted['response'] === 'success') ? true : false,
					}, () => {
						setTimeout(() => {
							this.setState({
								post_comment___is_destroyed___visible: false,
							}, () => {
								if (this.props.event___onDestroy) {
									this.props.event___onDestroy();
								}
							});
						}, 1000);
					});
				}
			},
			{
				text: this._LOCALE.translate(locale___App.ionAlert___button___cancel),
				role: 'cancel',
				handler: () => {
					this.setState({
						ionAlert___postCommentAction___askForClose___isOpen: false
					});
				}
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComPostCommentComponent,
	) {
		super(props);
		this.state = state___ComPostCommentComponent___default;
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void
	{
		this.setState({
			post_comment___props: this.props.post_comment___props,
			post_comment___props___is_loading: false,
		});
	}

	componentWillUnmount(): void {
		this.setState({
			...state___ComPostCommentComponent___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.state.post_comment___props___is_loading === true) ? <></> : <>
				{(this.state.post_comment___is_destroyed === false) ? <>

					<IonActionSheet isOpen={ this.state.ionActionSheet___postCommentAction___isOpen } {...this.ionActionSheet___postCommentAction___config} />
					<IonAlert isOpen={ this.state.ionAlert___postCommentAction___askForClose___isOpen } {...this.ionAlert___postCommentAction___askForClose___config} />
					<IonToast isOpen={(this.state.ionToast___postCommentAction___isDeleted === false) ? true : false } message={ this._LOCALE.translate(locale___ComPostCommentComponent.ionToast___errorDeleting) } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ ionToast___postCommentAction___isDeleted: null }); }}/>
					<IonToast isOpen={(this.state.ionToast___postCommentAction___isDeleted === true) ? true : false } message={ this._LOCALE.translate(locale___ComPostCommentComponent.ionToast___errorIsDeleted) } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___postCommentAction___isDeleted: null }); }}/>

					<section className="com-post-comment-component---container">
						
						{(this.state.post_comment___props['post_comment_writer_is_me'] && this.state.post_comment___props['post_comment_writer_is_me'] === true) ? <>
							<div className="com-post-comment-component---options">
								<i className="fas fa-ellipsis" onClick={() => { this.setState({ ionActionSheet___postCommentAction___isOpen: true }); }}></i>
							</div>
						</> : <></>}

						<Link to={ '/view/user/' + this.state.post_comment___props['post_comment_writer']['user_username'] }>
							<img src={ this.state.post_comment___props['post_comment_writer']['user_image_profile'] }/>
							<span>
								<span>{ this.state.post_comment___props['post_comment_writer']['user_username'] }</span>
								<span> { this._LOCALE.translate(locale___ComPostCommentComponent.has_commented) }: </span>
							</span>
						</Link>
						<ComTextRichComponent text={ this.state.post_comment___props['post_comment_text'] }/>
						<section>
							{(this.funcs___datetime.datetime___get___date(this.state.post_comment___props['post_comment_datetime']) === this.funcs___datetime.datetime___get___date(new Date())) ? <>
								<span>{ this._LOCALE.translate(locale___ComPostCommentComponent.created___today_at, [this.funcs___datetime.datetime___get___time(this.state.post_comment___props['post_comment_datetime'])]) }</span>
							</> : <>
								<span>{ this._LOCALE.translate(locale___ComPostCommentComponent.created___datetime, [this.funcs___datetime.datetime___get___date(this.state.post_comment___props['post_comment_datetime']), this.funcs___datetime.datetime___get___time(this.state.post_comment___props['post_comment_datetime'])]) }</span>
							</>}
						</section>
					</section>

				</> : <>

					{(this.state.post_comment___is_destroyed___visible === true) ? <>
						<section className="com-post-comment-component---destroyed">
							<p>{ this._LOCALE.translate(locale___ComPostCommentComponent.comment___is_deleted) }</p>
						</section>
					</> : <></>}

				</>}
			</>}
		</>;
	}

//#endregion

}