import React from 'react'
import { Link } from 'react-router-dom';

//	L O C A L E

import { locale___ComUserPrivateTopBarComponent } from './com-user-private-topbar.locale';

//	S T Y L E

import './com-user-private-topbar.scss'

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComListSeparatorComponent from '../com-list-separator/com-list-separator';

//	C L A S S

export default class ComUserPrivateTopBarComponent extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<Link to="/settings/privacy" className="com-user-private-topbar---container">
				<i className="fas fa-lock"></i>
				<span>{ this._LOCALE.translate(locale___ComUserPrivateTopBarComponent.profile_is_private) }</span>
			</Link>
			<ComListSeparatorComponent />
		</>;
	}

//#endregion

}