import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbPoiEditRolesPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbPoiEditRolesPage {

	poi___id: string,
	poi___props: any | null,
	poi___props___is_loading: boolean,

	user___owner: any | null,

	user___collection___administrators: any[],
	user___collection___creator_events: any[],
	user___collection___is_loading: boolean,









	actionSheet___user_options___target: string,
	actionSheet___user_options: boolean,








	ionModal___MdPoiRoleAddModal___isOpen: boolean,
	ionModal___MdPoiRoleAddModal___argument: string,

}

//	S T A T E   D E F A U L T

export const state___SbPoiEditRolesPage___default: state___SbPoiEditRolesPage = {

	poi___id: '',
	poi___props: null,
	poi___props___is_loading: true,

	user___owner: null,

	user___collection___administrators: [],
	user___collection___creator_events: [],
	user___collection___is_loading: true,















	actionSheet___user_options___target: '',
	actionSheet___user_options: false,














	ionModal___MdPoiRoleAddModal___isOpen: false,
	ionModal___MdPoiRoleAddModal___argument: '',

}